import { v4 as uuid } from 'uuid'
import { DATA_TYPES } from '@/utils/constants'
import * as types from '../../types'

const validationMessagesMap = {
  'urls contains an invalid url': 'This url is invalid',
}

const mapRawValidationErrors = (rawErrors) => rawErrors.map(({
  param,
  msg,
}) => {
  const matches = param.match(/\d+/)

  if (!matches || !matches.length) {
    return null
  }

  return {
    urlIndex: parseInt(matches[0], 10),
    message: validationMessagesMap[msg] || msg,
  }
})

export default {
  [types.URLS_CREATE_FETCHING](state) {
    Object.assign(state, { fetchingCreateUrls: true, fetchCreateUrlsError: '' })
  },
  [types.URLS_CREATE_ERROR](state, fetchCreateUrlsError) {
    Object.assign(state, { fetchingCreateUrls: false, fetchCreateUrlsError })
  },
  [types.URLS_CREATE_SUCCESS](state) {
    Object.assign(state, { fetchingCreateUrls: false })
  },

  [types.URLS_FETCHING_URLS](state) {
    Object.assign(state, { fetchingUrls: true, fetchUrlsError: '' })
  },
  [types.URLS_RECEIVE_URLS](state, { urls, totalRecords }) {
    Object.assign(state, { fetchingUrls: false, urls, totalUrls: totalRecords })
  },
  [types.URLS_MAX_URLS](state, maxTotalUrls) {
    Object.assign(state, { maxTotalUrls })
  },
  [types.URLS_RECEIVE_URLS_ERROR](state, fetchUrlsError) {
    Object.assign(state, { fetchingUrls: false, fetchUrlsError })
  },

  [types.URLS_EXPORT_URLS](state) {
    Object.assign(state, { exportingUrls: true, exportUrlsError: '' })
  },
  [types.URLS_RECEIVE_EXPORT_URLS](state) {
    Object.assign(state, { exportingUrls: false })
  },
  [types.URLS_RECEIVE_EXPORT_URLS_ERROR](state, exportUrlsError) {
    Object.assign(state, { exportingUrls: false, exportUrlsError })
  },

  [types.URLS_FETCHING_URLS_SUMMARY](state) {
    Object.assign(state, { fetchingUrlsSummary: true, fetchUrlsSummaryError: '' })
  },
  [types.URLS_RECEIVE_URLS_SUMMARY](state, {
    urls, totalRecords, isInfinityScroll, pageSize, page,
  }) {
    Object.assign(state, {
      fetchingUrlsSummary:
        false,
      urlsSummary: isInfinityScroll ? [...state.urlsSummary, ...urls] : urls,
      totalUrlsSummary: totalRecords,
      pageSummarySize: parseInt(pageSize, 10),
      pageSummary: parseInt(page, 10),
    })
  },
  [types.URLS_FETCHING_URLS_SUMMARY_ERROR](state, fetchUrlsError) {
    Object.assign(state, { fetchingUrlsSummary: false, fetchUrlsError })
  },

  [types.URLS_FETCHING_DELETE_URL](state) {
    Object.assign(state, { deleteUrlFetching: true, deleteUrlError: '' })
  },
  [types.URLS_RECEIVE_DELETE_URL](state) {
    Object.assign(state, { deleteUrlFetching: false })
  },
  [types.URLS_RECEIVE_DELETE_URL_ERROR](state, deleteUrlError) {
    Object.assign(state, { deleteUrlFetching: false, deleteUrlError })
  },

  [types.URLS_FETCHING_DELETE_URLS](state) {
    Object.assign(state, { deleteUrlsFetching: true, deleteUrlsError: '' })
  },
  [types.URLS_RECEIVE_DELETE_URLS](state) {
    Object.assign(state, { deleteUrlsFetching: false })
  },
  [types.URLS_RECEIVE_DELETE_URLS_ERROR](state, deleteUrlsError) {
    Object.assign(state, { deleteUrlsFetching: false, deleteUrlsError })
  },

  [types.URLS_FETCHING_VALIDATE_URLS](state) {
    Object.assign(state, { fetchingValidateUrls: true, validateUrlsErrors: [] })
  },
  [types.URLS_RECEIVE_VALID_URLS](state, { urls }) {
    Object.assign(state, { fetchingValidateUrls: false, validUrls: urls })
  },
  [types.URLS_RECEIVE_VALID_URLS_ERROR](state, rawErrors) {
    const validateUrlsErrors = mapRawValidationErrors(rawErrors)
    Object.assign(state, { fetchingValidateUrls: false, validateUrlsErrors })
  },

  [types.URLS_FETCHING_VALIDATE_LISTING_URLS](state) {
    Object.assign(state, { fetchingValidateListingUrls: true, validateListingUrlsErrors: [] })
  },
  [types.URLS_RECEIVE_VALID_LISTING_URLS](state, { urls }) {
    Object.assign(state, { fetchingValidateListingUrls: false, validListingUrls: urls })
  },
  [types.URLS_RECEIVE_VALID_LISTING_URLS_ERROR](state, rawErrors) {
    const validateListingUrlsErrors = mapRawValidationErrors(rawErrors)
    Object.assign(state, { fetchingValidateListingUrls: false, validateListingUrlsErrors })
  },

  [types.URLS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.URLS_SET_PAGE_SUMMARY](state, pageSummary) {
    Object.assign(state, { pageSummary })
  },
  [types.URLS_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.URLS_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.URLS_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },
  [types.URLS_GET_HOST_NAMES_REQUEST](state) {
    Object.assign(state, { hostnames: [], fetchingHostnamesError: '' })
  },
  [types.URLS_GET_HOST_NAMES_SUCCESS](state, { hostnames }) {
    const hosts = hostnames.map((name) => ({ value: uuid(), name }))
    Object.assign(state, { fetchingHostnamesError: '', hostnames: hosts })
  },
  [types.URLS_GET_HOST_NAMES_ERROR](state, fetchingHostnamesError) {
    Object.assign(state, { hostnames: [], fetchingHostnamesError })
  },

  [types.URLS_FETCHING_BCR_LINK_STATUS](state) {
    Object.assign(state, { fetchingBCRLinkStatus: true, fetchBCRLinkStatusError: '' })
  },
  [types.URLS_RECEIVE_BCR_LINK_STATUS](state, bcrLinkStatus) {
    Object.assign(state, {
      fetchingBCRLinkStatus: false,
      bcrLinkStatus: {
        ...bcrLinkStatus,
        dataType: DATA_TYPES.URLS,
      },
    })
  },
  [types.URLS_RECEIVE_BCR_LINK_STATUS_ERROR](state, fetchBCRLinkStatusError) {
    Object.assign(state, { fetchingBCRLinkStatus: false, fetchBCRLinkStatusError })
  },

  [types.URLS_FETCHING_LINK_URL_TO_BCR](state) {
    Object.assign(state, { fetchingLinkUrlToBCR: true, fetchingLinkUrlToBCRError: '' })
  },
  [types.URLS_RECEIVE_LINK_URL_TO_BCR](state) {
    Object.assign(state, { fetchingLinkUrlToBCR: false })
  },
  [types.URLS_RECEIVE_LINK_URL_TO_BCR_ERROR](state, fetchingLinkUrlToBCRError) {
    Object.assign(state, { fetchingLinkUrlToBCR: false, fetchingLinkUrlToBCRError })
  },

  [types.URLS_FETCHING_RETRY_LINK_URL_TO_BCR](state) {
    Object.assign(state, { fetchingRetryLinkUrlToBCR: true, fetchingRetryLinkUrlToBCRError: '' })
  },
  [types.URLS_RECEIVE_RETRY_LINK_URL_TO_BCR](state) {
    Object.assign(state, { fetchingRetryLinkUrlToBCR: false })
  },
  [types.URLS_RECEIVE_RETRY_LINK_URL_TO_BCR_ERROR](state, fetchingRetryLinkUrlToBCRError) {
    Object.assign(state, { fetchingRetryLinkUrlToBCR: false, fetchingRetryLinkUrlToBCRError })
  },

  [types.URLS_FETCHING_CREATE_BCR_QUERY](state) {
    Object.assign(state, { fetchingCreateBCRQuery: true, fetchingCreateBCRQueryError: '' })
  },
  [types.URLS_RECEIVE_CREATE_BCR_QUERY](state) {
    Object.assign(state, {
      fetchingCreateBCRQuery: false,
      fetchingCreateBCRQueryError: '',
    })
  },
  [types.URLS_RECEIVE_CREATE_BCR_QUERY_ERROR](state, fetchingCreateBCRQueryError) {
    Object.assign(state, { fetchingCreateBCRQuery: false, fetchingCreateBCRQueryError })
  },

  [types.URLS_FETCHING_SUGGESTED_BCR_QUERY](state) {
    Object.assign(state, {
      suggestedBCRQuery: '',
      fetchingSuggestedBCRQuery: true,
      fetchingSuggestedBCRQueryError: '',
    })
  },
  [types.URLS_RECEIVE_SUGGESTED_BCR_QUERY](state, { suggestedQuery }) {
    Object.assign(state, {
      suggestedBCRQuery: suggestedQuery,
      fetchingSuggestedBCRQuery: false,
      fetchingSuggestedBCRQueryError: '',
    })
  },
  [types.URLS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR](state, fetchingSuggestedBCRQueryError) {
    Object.assign(state, {
      fetchingSuggestedBCRQuery: false,
      fetchingSuggestedBCRQueryError,
    })
  },

  [types.URLS_DOWNLOAD_REVIEWS](state) {
    Object.assign(state, { fetchingDownloadReviews: true })
  },
  [types.URLS_RECEIVE_DOWNLOAD_REVIEWS](state) {
    Object.assign(state, { fetchingDownloadReviews: false, fetchDownloadReviewsError: '' })
  },
  [types.URLS_DOWNLOAD_REVIEWS_ERROR](state, fetchingDownloadReviewsError) {
    Object.assign(state, { fetchingDownloadReviews: false, fetchingDownloadReviewsError })
  },

  [types.URLS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
}
