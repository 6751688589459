const arraysEqual = (a, b) => {
  if (!a || !Array.isArray(a) || !b || !Array.isArray(b)) {
    return false
  }
  const arrA = [...a].sort()
  const arrB = [...b].sort()
  if (arrA.length !== arrB.length) {
    return false
  }

  for (let i = 0; i < arrA.length; i += 1) {
    if (arrB.indexOf(arrA[i]) === -1 || arrA.indexOf(arrB[i]) === -1) {
      return false
    }
  }
  return true
}

export default arraysEqual
