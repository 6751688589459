import * as types from '@/store/types'
import * as bcrApi from '@/api/bcr'

// eslint-disable-next-line import/prefer-default-export
export const fetchProjects = async ({ commit }) => {
  commit(types.BCR_FETCHING_PROJECTS)

  try {
    const data = await bcrApi.getProjects()
    return commit(types.BCR_RECEIVE_PROJECTS, data)
  } catch (e) {
    return commit(types.BCR_RECEIVE_PROJECTS_ERROR, e.message)
  }
}
