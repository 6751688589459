import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingSupportedDomains: false,
  supportedDomains: [],
  fetchingSupportedDomainsError: '',
  totalSupportedDomains: 0,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
