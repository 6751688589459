import mixpanel from 'mixpanel-browser'
import * as storageApi from '@/api/storage'
import * as userApi from '@/api/users'
import { getMixpanelConfiguration } from './mixpanelHelpers'

const { VUE_APP_ENABLE_TRACKING, VUE_APP_ENABLE_TRACKING_LOG } = process.env
const isTrackingEnabled = VUE_APP_ENABLE_TRACKING === 'true'
const isTrackingLogEnabled = VUE_APP_ENABLE_TRACKING_LOG === 'true'
const mixpanelConfig = getMixpanelConfiguration()
const { mixpanelWriteKey } = mixpanelConfig
let mixpanelWasInitialized = false

const getPersonalInformation = async () => {
  const me = await userApi.me()
  const { firstName, lastName, username } = me.user

  return {
    $first_name: firstName,
    $last_name: lastName,
    $username: username,
    $email: username,
  }
}

const getUserInformation = async () => {
  const me = await userApi.me()
  const { bcrClient, client } = me
  const {
    id, creationDate, uiRole, uiLanguage, startDate, parentId, salesForceId, internal,
  } = bcrClient
  const {
    id: clientId, name,
  } = client

  return {
    distinct_id: id,
    userId: id,
    role: uiRole,
    client: name,
    clientId,
    clientStartDate: startDate,
    parentClientId: parentId,
    salesForceId,
    internal,
    creationDate,
    uiLanguage,
  }
}

const register = async () => {
  const userInformation = await getUserInformation()
  const personalInformation = await getPersonalInformation()
  const uniqueSessionId = storageApi.get('token')
  mixpanel.identify(userInformation.distinct_id)
  mixpanel.register({
    sessionId: uniqueSessionId,
    ...userInformation,
  })
  mixpanel.people.set({ ...userInformation, ...personalInformation })
}

export const initTracking = () => {
  /* We need to set the 'mixpanel:log' property value to true in localStorage
    in order to be able to see the logs of the events and arguments passed
    that will be sent to the mixpanel while in development mode,
    as it takes some time to get the results in the mixpanel app.
  */
  storageApi.set('mixpanel:log', isTrackingEnabled && isTrackingLogEnabled)

  if (!mixpanelWriteKey) {
    return Promise.resolve()
  }

  return new Promise((resolve) => {
    mixpanel.init(mixpanelWriteKey, {
      ...mixpanelConfig,
      loaded: () => {
        register()
        mixpanelWasInitialized = true

        resolve()
      },
    })
  })
}

export const track = (event, data) => {
  const args = [event.trim(), { ...data }]

  const dataInArgs = args[1]
  // Here removing token from the args to prevent from logging it out
  const { token: _, ...dataInArgsWithoutToken } = dataInArgs
  const argsWithoutToken = [event.trim(), { ...dataInArgsWithoutToken }]
  if (storageApi.get('mixpanel:log') === 'true') {
    console.log('%c[mixpanel.track]', 'background-color: #4f44e0; color: #fff', ...argsWithoutToken)
  }

  if (mixpanelWasInitialized && isTrackingEnabled) {
    mixpanel.track(...args)
  }
}
