import { SENTIMENT_RANGES, DATE_FORMAT_REGEX } from '@/utils/constants'
import * as types from '../../types'

const PERMITTED_SENTIMENT_TYPES = Object.keys(SENTIMENT_RANGES)

const matchesDateFormat = (v) => DATE_FORMAT_REGEX.test(v)

export const filterSentiment = async ({ commit }, { sentiment }) => {
  if (!sentiment) {
    return commit(types.DASHBOARDSFILTERS_SET_SENTIMENT, { minSentiment: null, maxSentiment: null, sentiment: '' })
  }
  if (!PERMITTED_SENTIMENT_TYPES.includes(sentiment)) {
    throw new Error(`sentiment must be one of ${PERMITTED_SENTIMENT_TYPES.join(', ')}`)
  }
  const [minSentiment, maxSentiment] = SENTIMENT_RANGES[sentiment]
  return commit(types.DASHBOARDSFILTERS_SET_SENTIMENT, { minSentiment, maxSentiment, sentiment })
}

export const filterEntitySentiment = async ({ commit }, { entitySentiment }) => {
  if (!entitySentiment) {
    return commit(
      types.DASHBOARDSFILTERS_SET_ENTITY_SENTIMENT,
      { minEntitySentiment: null, maxEntitySentiment: null, entitySentiment: '' },
    )
  }
  if (!PERMITTED_SENTIMENT_TYPES.includes(entitySentiment)) {
    throw new Error(`entity sentiment must be one of ${PERMITTED_SENTIMENT_TYPES.join(', ')}`)
  }
  const [minEntitySentiment, maxEntitySentiment] = SENTIMENT_RANGES[entitySentiment]
  return commit(
    types.DASHBOARDSFILTERS_SET_ENTITY_SENTIMENT,
    { minEntitySentiment, maxEntitySentiment, entitySentiment },
  )
}

export const filterEntity = async ({ commit }, { entity }) => {
  commit(types.DASHBOARDSFILTERS_SET_ENTITY_GROUP_ID, { entityGroupId: null })
  commit(types.DASHBOARDSFILTERS_SET_ENTITY, { entity })
}

export const filterEntityGroupId = ({ commit }, { entityGroupId }) => {
  commit(types.DASHBOARDSFILTERS_SET_ENTITY_GROUP_ID, { entityGroupId })
  commit(types.DASHBOARDSFILTERS_SET_ENTITY, { entity: null })
}

export const filterUrlIds = async ({ commit }, { urlIds }) => {
  commit(types.DASHBOARDSFILTERS_SET_URL_IDS, { urlIds })
}

export const filterRatings = async ({ commit }, { ratings }) => {
  const parseRatings = (ratings || []).map((rating) => Math.ceil(rating))
  commit(types.DASHBOARDSFILTERS_SET_RATINGS, { ratings: parseRatings })
}

export const filterEntityMapId = async ({ commit }, { entityMapId }) => {
  commit(types.DASHBOARDSFILTERS_SET_ENTITY_MAP_ID, { entityMapId })
}

export const filterCountries = async ({ commit }, { countries }) => {
  commit(types.DASHBOARDSFILTERS_SET_COUNTRIES, { countries })
}

export const filterHostnames = async ({ commit }, { hostnames }) => {
  commit(types.DASHBOARDSFILTERS_SET_HOSTNAMES, { hostnames })
}

export const filterSearch = async ({ commit }, { search }) => {
  commit(types.DASHBOARDSFILTERS_SET_SEARCH, { search })
}

export const filterDateRange = async ({ commit }, { dateRange }) => {
  if (!dateRange) {
    return commit(types.DASHBOARDSFILTERS_SET_DATE_RANGE, { minDate: null, maxDate: null })
  }
  if (
    !Array.isArray(dateRange) || dateRange.length !== 2
    || !matchesDateFormat(dateRange[0]) || !matchesDateFormat(dateRange[1])
  ) {
    throw new Error('dateRange must be an array of 2 YYYY-MM-DD strings')
  }
  return commit(types.DASHBOARDSFILTERS_SET_DATE_RANGE, { dateRange })
}

export const filterTrendingData = async ({ commit }, { period, dateRange }) => {
  if (!period) {
    throw new Error(`can not set trending data filters without a period, invalid period = ${period}`)
  }

  if (!dateRange) {
    const emptyDateRange = {
      [period]: {
        minDate: null,
        maxDate: null,
      },
    }

    return commit(
      types.DASHBOARDSFILTERS_SET_COMPARISON_TRENDING_DATE_RANGE,
      { dateRange: emptyDateRange },
    )
  }

  const { minDate, maxDate } = dateRange
  if (!minDate || !maxDate) {
    throw new Error(`dateRange for period ${period} must be an object of 2 YYYY-MM-DD strings`)
  }

  const newDateRange = {
    [period]: {
      minDate: dateRange.minDate,
      maxDate: dateRange.maxDate,
    },
  }

  return commit(
    types.DASHBOARDSFILTERS_SET_COMPARISON_TRENDING_DATE_RANGE,
    { dateRange: newDateRange },
  )
}

export const setSubGroup = ({ commit }, { subGroup }) => {
  commit(types.DASHBOARDSFILTERS_SET_SUB_GROUP, { subGroup })
}

export const setDashboardFilters = ({ commit }, {
  sentiment,
  entitySentiment,
  entity,
  urlIds,
  ratings,
  entityMapId,
  countries,
  hostnames,
  search,
  dateRange,
  trendingDataDateRangeFilter,
  entityGroupId,
  subGroup,
}) => {
  if (sentiment !== undefined) {
    filterSentiment({ commit }, { sentiment })
  }
  if (entitySentiment !== undefined) {
    filterEntitySentiment({ commit }, { entitySentiment })
  }
  if (entity !== undefined) {
    filterEntity({ commit }, { entity })
  }
  if (urlIds !== undefined) {
    filterUrlIds({ commit }, { urlIds })
  }
  if (ratings !== undefined) {
    filterRatings({ commit }, { ratings })
  }
  if (entityMapId !== undefined) {
    filterEntityMapId({ commit }, { entityMapId })
  }
  if (countries !== undefined) {
    filterCountries({ commit }, { countries })
  }
  if (hostnames !== undefined) {
    filterHostnames({ commit }, { hostnames })
  }
  if (search !== undefined) {
    filterSearch({ commit }, { search })
  }
  if (dateRange !== undefined) {
    filterDateRange({ commit }, { dateRange })
  }
  if (trendingDataDateRangeFilter !== undefined) {
    const { thisPeriod, lastPeriod } = trendingDataDateRangeFilter

    if (thisPeriod) {
      filterTrendingData({ commit }, { period: 'thisPeriod', dateRange: thisPeriod })
    }

    if (lastPeriod) {
      filterTrendingData({ commit }, { period: 'lastPeriod', dateRange: lastPeriod })
    }
  }
  if (entityGroupId !== undefined) {
    filterEntityGroupId({ commit }, { entityGroupId })
  }
  if (subGroup !== undefined) {
    setSubGroup({ commit }, { subGroup })
  }
}

export const resetFilters = ({ commit }) => {
  commit(types.DASHBOARDSFILTERS_RESET)
}
