import * as serverApi from './server'

export const create = (payload) => serverApi.post('/v3/products', payload)
export const getAll = (queryParams) => serverApi.get('/v3/products', queryParams)
export const deleteProducts = (payload) => serverApi.post('/v3/products/remove', payload)
export const editTags = (payload) => serverApi.patch('/v3/tags/products', payload)
export const getBCRLinkStatus = ({ productId }) => serverApi.get(`/v3/products/${productId}/bcrlinks/status`)
export const linkProductToBCR = (payload) => serverApi.post('/v3/products/bcrlinks', payload)
export const retryLinkProductToBCR = (payload) => serverApi.post('/v3/products/bcrlinks/retry', payload)
export const createBCRQuery = (payload) => serverApi.post('/v3/products/bcrlinks/queries', payload)
export const getBCRQuerySuggestion = (payload) => serverApi.post('/v3/products/bcrlinks/queries/suggested', payload)
export const editUrls = (payload) => serverApi.patch('/v3/products/urls', payload)
export const download = ({ productId }) => serverApi.getRaw(`/v3/products/${productId}/reviews/export`)
export const update = ({ productId, ...rest }) => serverApi.patch(`/v3/products/${productId}`, rest)
