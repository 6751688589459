import * as types from '../../types'
import * as dashboardsApi from '../../../api/dashboards'

export const createDashboard = async ({ commit }, {
  name,
  type,
  productIds,
  categoryIds,
  tagIds,
  brandIds,
  projectId,
  productFilters,
}) => {
  commit(types.DASHBOARDS_CREATE_DASHBOARD_FETCHING)
  try {
    const data = await dashboardsApi.create({
      name,
      type,
      productIds,
      categoryIds,
      tagIds,
      brandIds,
      projectId,
      productFilters,
    })
    return commit(types.DASHBOARDS_CREATE_DASHBOARD_SUCCESS, data)
  } catch (e) {
    return commit(types.DASHBOARDS_CREATE_DASHBOARD_ERROR, e.message)
  }
}

export const fetchDashboards = async ({ commit }, fetchParams = {}) => {
  commit(types.DASHBOARDS_FETCHING_DASHBOARDS)

  try {
    const data = await dashboardsApi.getAll(fetchParams)
    return commit(types.DASHBOARDS_RECEIVE_DASHBOARDS, data)
  } catch (e) {
    return commit(types.DASHBOARDS_RECEIVE_DASHBOARDS_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.DASHBOARDS_SET_PAGE, page)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.DASHBOARDS_SET_SORT, sort)
  commit(types.DASHBOARDS_SET_SORTDIR, sortDir)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.DASHBOARDS_SET_PAGESIZE, pageSize)
}

export const saveDashboard = async ({ commit, rootGetters }) => {
  commit(types.DASHBOARDS_SAVING_DASHBOARD)

  const dashboardId = rootGetters['dashboardsData/dashboardId']
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const { trendingDataDateRangeFilter } = rootGetters['dashboardsFilters/appliedTrendingDataFilters']
  const spdProducts = rootGetters['dashboardsData/productsForSPD']

  let allFilters = {
    ...dashboardFilters,
  }

  if (trendingDataDateRangeFilter?.thisPeriod?.minDate
    && trendingDataDateRangeFilter?.thisPeriod?.maxDate
    && trendingDataDateRangeFilter?.lastPeriod?.minDate
    && trendingDataDateRangeFilter?.lastPeriod?.maxDate) {
    allFilters = { ...allFilters, trendingDataDateRangeFilter }
  }

  try {
    await dashboardsApi.edit({
      ...allFilters,
      dashboardId,
      tabs: [{ type: 'single-product', sourceIds: spdProducts.map(({ _id }) => _id) }],
    })
    return commit(types.DASHBOARDS_RECEIVE_SAVE)
  } catch (e) {
    return commit(types.DASHBOARDS_RECEIVE_SAVE_ERROR, e.message)
  }
}

export const resetSaveDashboardState = ({ commit }) => commit(types.DASHBOARDS_RECEIVE_SAVE)

export const saveDashboardAs = async ({ commit, rootGetters }, { name, projectId }) => {
  commit(types.DASHBOARDS_SAVING_DASHBOARD_AS)

  const productIds = rootGetters['dashboardsData/dashboardProductIds']
  const categoryIds = rootGetters['dashboardsData/dashboardCategoryIds']
  const tagIds = rootGetters['dashboardsData/dashboardTagIds']
  const brandIds = rootGetters['dashboardsData/dashboardBrandIds']
  const type = rootGetters['dashboardsData/dashboardType']
  const filters = rootGetters['dashboardsFilters/appliedFilters']
  const { trendingDataDateRangeFilter } = rootGetters['dashboardsFilters/appliedTrendingDataFilters']
  const spdProducts = rootGetters['dashboardsData/productsForSPD']

  let allFilters = {
    ...filters,
  }

  if (trendingDataDateRangeFilter?.thisPeriod?.minDate
    && trendingDataDateRangeFilter?.thisPeriod?.maxDate
    && trendingDataDateRangeFilter?.lastPeriod?.minDate
    && trendingDataDateRangeFilter?.lastPeriod?.maxDate) {
    allFilters = { ...allFilters, trendingDataDateRangeFilter }
  }

  try {
    const data = await dashboardsApi.create({
      name,
      type,
      productIds,
      categoryIds,
      tagIds,
      brandIds,
      projectId,
      filters: allFilters,
      tabs: [{ type: 'single-product', sourceIds: spdProducts.map(({ _id }) => _id) }],
    })
    return commit(types.DASHBOARDS_RECEIVE_SAVE_AS, data)
  } catch (e) {
    return commit(types.DASHBOARDS_RECEIVE_SAVE_AS_ERROR, e.message)
  }
}

export const resetSaveDashboardAsState = ({ commit }) => commit(types.DASHBOARDS_RESET_SAVE_AS)

export const deleteDashboards = async ({ commit }, {
  dashboardIds,
  search,
}) => {
  commit(types.DASHBOARDS_DELETING_DASHBOARDS)

  try {
    await dashboardsApi.deleteDashboards({
      dashboardIds,
      search,
    })

    return commit(types.DASHBOARDS_DELETING_DASHBOARDS, false)
  } catch (e) {
    return commit(types.DASHBOARDS_DELETE_DASHBOARDS_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys = {}) => commit(types.DASHBOARDS_CLEAR_ERRORS, errorKeys)
