import * as types from '../../types'
import * as tagsApi from '../../../api/tags'

export const createTag = async ({ commit }, {
  name,
  color,
  projectId,
}) => {
  commit(types.TAGS_CREATE_TAG_FETCHING)
  try {
    const payload = {
      name,
      color,
      projectId,
    }
    const data = await tagsApi.createTag(payload)
    return commit(types.TAGS_CREATE_TAG_SUCCESS, data)
  } catch (e) {
    return commit(types.TAGS_CREATE_TAG_ERROR, e.message)
  }
}

export const fetchTags = async ({ commit }, fetchParams = {}) => {
  commit(types.TAGS_FETCHING_TAGS)

  try {
    const data = await tagsApi.getAll(fetchParams)
    return commit(types.TAGS_RECEIVE_TAGS, data)
  } catch (e) {
    return commit(types.TAGS_RECEIVE_TAGS_ERROR, e.message)
  }
}

export const fetchTagsSummary = async ({
  commit, state: {
    summaryPageSize,
  },
}, fetchParams = {}) => {
  commit(types.TAGS_FETCHING_TAGS_SUMMARY)

  try {
    const { isInfinityScroll, ...rest } = fetchParams
    const params = { ...rest, pageSize: summaryPageSize }
    const data = await tagsApi.getAll(params)
    return commit(types.TAGS_RECEIVE_TAGS_SUMMARY, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.TAGS_RECEIVE_TAGS_SUMMARY_ERROR, e.message)
  }
}

export const editTagsUrls = async ({ commit }, {
  op,
  tagIds,
  projectId,
  urlIds,
  filters = {},
}) => {
  commit(types.TAGS_FETCHING_EDIT_TAGS_URLS)

  try {
    const payload = {
      op,
      tagIds,
      urlIds,
      projectId,
      filters,
    }
    const data = await tagsApi.editUrls(payload)
    return commit(types.TAGS_EDIT_TAGS_URLS_SUCCESS, data)
  } catch (e) {
    return commit(types.TAGS_EDIT_TAGS_URLS_ERROR, e.message)
  }
}

export const editTagsProducts = async ({ commit }, {
  op,
  tagIds,
  projectId,
  productIds,
  filters = {},
}) => {
  commit(types.TAGS_FETCHING_EDIT_TAGS_PRODUCTS)

  try {
    const payload = {
      op,
      tagIds,
      productIds,
      projectId,
      filters,
    }
    const data = await tagsApi.editProducts(payload)
    return commit(types.TAGS_EDIT_TAGS_PRODUCTS_SUCCESS, data)
  } catch (e) {
    return commit(types.TAGS_EDIT_TAGS_PRODUCTS_ERROR, e.message)
  }
}

export const deleteTags = async ({ commit }, {
  tagIds,
  filters = {},
  projectId,
}) => {
  commit(types.TAGS_DELETING_TAGS, true)

  const payload = {
    tagIds,
    filters,
  }
  if (projectId) {
    payload.projectId = projectId
  }
  try {
    await tagsApi.deleteTags(payload)
    return commit(types.TAGS_DELETING_TAGS, false)
  } catch (e) {
    return commit(types.TAGS_DELETE_TAGS_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.TAGS_SET_PAGE, page)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.TAGS_SET_PAGESIZE, pageSize)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.TAGS_SET_SORT, sort)
  commit(types.TAGS_SET_SORTDIR, sortDir)
}

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys = {}) => commit(types.TAGS_CLEAR_ERRORS, errorKeys)
