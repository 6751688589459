import * as types from '../../types'

export default {
  [types.SEARCH_FETCHING_CREATE_SEARCH](state) {
    Object.assign(state, { fetchingCreateSearch: true, fetchCreateSearchError: '' })
  },
  [types.SEARCH_RECEIVE_CREATE_SEARCH](state, { search }) {
    Object.assign(state, { fetchingCreateSearch: false, search })
  },
  [types.SEARCH_RECEIVE_CREATE_SEARCH_ERROR](state, fetchCreateSearchError) {
    Object.assign(state, { fetchingCreateSearch: false, fetchCreateSearchError })
  },

  [types.SEARCH_FETCHING_SEARCH](state) {
    Object.assign(state, { fetchingSearch: true, fetchSearchError: '' })
  },
  [types.SEARCH_RECEIVE_SEARCH](state, { search }) {
    Object.assign(state, { fetchingSearch: false, search })
  },
  [types.SEARCH_RECEIVE_SEARCH_ERROR](state, fetchSearchError) {
    Object.assign(state, { fetchingSearch: false, fetchSearchError })
  },

  [types.SEARCH_FETCHING_UPDATE_SEARCH](state) {
    Object.assign(state, { fetchingUpdateSearch: true, fetchUpdateSearchError: '' })
  },
  [types.SEARCH_RECEIVE_UPDATED_SEARCH](state, { search }) {
    Object.assign(state, { fetchingUpdateSearch: false, search })
  },
  [types.SEARCH_RECEIVE_UPDATE_SEARCH_ERROR](state, fetchUpdateSearchError) {
    Object.assign(state, { fetchingUpdateSearch: false, fetchUpdateSearchError })
  },

  [types.SEARCH_FETCHING_ADD_SEARCH_URLS](state) {
    Object.assign(state, { fetchingAddSearchUrls: true, fetchAddSearchUrlsError: '' })
  },
  [types.SEARCH_RECEIVE_ADD_SEARCH_URLS](state, { addSearchUrlsResult }) {
    Object.assign(state, { fetchingAddSearchUrls: false, addSearchUrlsResult })
  },
  [types.SEARCH_RECEIVE_ADD_SEARCH_URLS_ERROR](state, fetchAddSearchUrlsError) {
    Object.assign(state, { fetchingAddSearchUrls: false, fetchAddSearchUrlsError })
  },

  [types.SEARCH_FETCHING_SEARCH_NEW_RESULTS](state) {
    Object.assign(state, { fetchingNewResults: true, fetchNewResultsError: '' })
  },
  [types.SEARCH_RECEIVE_SEARCH_NEW_RESULTS](state, { newResults, totalNewResults }) {
    Object.assign(state, { fetchingNewResults: false, newResults, totalNewResults })
  },
  [types.SEARCH_RECEIVE_SEARCH_NEW_RESULTS_ERROR](state, fetchNewResultsError) {
    Object.assign(state, { fetchingNewResults: false, fetchNewResultsError })
  },

  [types.SEARCH_FETCHING_SEARCH_ADDED_RESULTS](state) {
    Object.assign(state, { fetchingAddedResults: true, fetchAddedResultsError: '' })
  },
  [types.SEARCH_RECEIVE_SEARCH_ADDED_RESULTS](state, { addedResults, totalAddedResults }) {
    Object.assign(state, { fetchingAddedResults: false, addedResults, totalAddedResults })
  },
  [types.SEARCH_RECEIVE_SEARCH_ADDED_RESULTS_ERROR](state, fetchAddedResultsError) {
    Object.assign(state, { fetchingAddedResults: false, fetchAddedResultsError })
  },

  [types.SEARCH_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
}
