function cleanFilters(filters) {
  return Object.entries(filters).reduce((memo, [key, value]) => {
    if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
      // eslint-disable-next-line no-param-reassign
      memo[key] = value
    }
    return memo
  }, {})
}

export default cleanFilters
