import VueI18n from 'vue-i18n'
import Vue from 'vue'
import numberFormats from '@/translations/numberFormats'
import es from './es.json'
import en from './en.json'

const I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE
const I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: I18N_LOCALE || 'en',
  fallbackLocale: I18N_FALLBACK_LOCALE || 'es',
  messages: {
    en,
    es,
  },
  numberFormats,
})

export default i18n
