import {
  getReviewsSentimentHistory,
  getReviewsRatingHistory,
} from '@/api/dashboardsData'
import * as types from '../../types'

export const fetchEntityProgressSentimentHistory = async (
  {
    commit,
    rootGetters,
  },
  {
    dashboardId,
    ...queryParams
  }) => {
  commit(types.ENTITYPROGRESS_FETCHING_REVIEWS_SENTIMENT_HISTORY)

  const {
    entity: _,
    entityGroupId: __,
    ...dashboardFilters
  } = rootGetters['dashboardsFilters/appliedFilters']

  try {
    const data = await getReviewsSentimentHistory({
      dashboardId,
      ...queryParams,
      ...dashboardFilters,
    })
    return commit(types.ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY, data)
  } catch (e) {
    return commit(types.ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR, e.message)
  }
}

export const fetchEntityProgressRatingHistory = async (
  {
    commit,
    rootGetters,
  },
  {
    dashboardId,
    ...queryParams
  }) => {
  commit(types.ENTITYPROGRESS_FETCHING_REVIEWS_RATING_HISTORY)
  const {
    entity: _,
    entityGroupId: __,
    ...dashboardFilters
  } = rootGetters['dashboardsFilters/appliedFilters']

  try {
    const data = await getReviewsRatingHistory({
      dashboardId,
      ...queryParams,
      ...dashboardFilters,
    })
    return commit(types.ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY, data)
  } catch (e) {
    return commit(types.ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY_ERROR, e.message)
  }
}

export const resetState = ({ commit }) => commit(types.ENTITYPROGRESS_RESET_STATE)
