import * as types from '../../types'

export default {
  [types.BCR_FETCHING_PROJECTS](state) {
    Object.assign(state, { fetchingProjects: true, fetchingProjectsError: '' })
  },
  [types.BCR_RECEIVE_PROJECTS](state, { projects }) {
    Object.assign(state, { projects, fetchingProjects: false })
  },
  [types.BCR_RECEIVE_PROJECTS_ERROR](state, fetchingProjectsError) {
    Object.assign(state, { fetchingProjects: false, fetchingProjectsError })
  },
}
