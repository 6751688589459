import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingCreateUrls: false,
  fetchCreateUrlsError: '',

  fetchingUrls: false,
  exportingUrls: false,
  exportUrlsError: '',
  fetchingUrlsSummary: false,
  fetchUrlsError: '',
  fetchUrlsSummaryError: '',
  urls: [],
  urlsSummary: [],
  totalUrls: 0,
  maxTotalUrls: 0,
  totalUrlsSummary: 0,
  page: 1,
  pageSummary: 1,
  pageSize: 50,
  pageSummarySize: 50,
  sort: '',
  sortDir: 'asc',

  fetchingValidateUrls: false,
  validUrls: [],
  validateUrlsErrors: [],

  fetchingValidateListingUrls: false,
  validListingUrls: [],
  validateListingUrlsErrors: [],

  deleteUrlFetching: false,
  deleteUrlError: '',
  deleteUrlsFetching: false,
  deleteUrlsError: '',

  hostnames: [],
  fetchingHostnamesError: '',

  fetchingBCRLinkStatus: false,
  bcrLinkStatus: null,
  fetchBCRLinkStatusError: '',

  fetchingRetryLinkUrlToBCR: false,
  fetchingRetryLinkUrlToBCRError: '',

  fetchingLinkUrlToBCR: false,
  fetchingLinkUrlToBCRError: '',

  fetchingCreateBCRQuery: false,
  fetchingCreateBCRQueryError: '',

  fetchingSuggestedBCRQuery: false,
  suggestedBCRQuery: '',
  fetchingSuggestedBCRQueryError: '',

  fetchingDownloadReviews: false,
  fetchingDownloadReviewsError: '',

  fetchingExportUrls: false,
  fetchingExportUrlsError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
