import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import streamgraph from 'highcharts/modules/streamgraph'
import i18n from '@/translations'
import mapInit from 'highcharts/modules/map'
import mapData from '@highcharts/map-collection/custom/world.geo.json'
import sunburstInit from 'highcharts/modules/sunburst'
import App from './App.vue'
import router from './router'
import protectRoutes from './router/protectRoutes'
import store from './store'
import vuetify from './plugins/vuetify'
import highchartsDefaultOptions from './highchartsDefaultOptions.json'

import '@/assets/global.css'

mapInit(Highcharts)
sunburstInit(Highcharts)
Highcharts.maps.countryVolume = mapData

streamgraph(Highcharts)
Highcharts.setOptions(highchartsDefaultOptions)
// workaround for showing
// data: images in render text function
// https://github.com/highcharts/highcharts/issues/16149
Highcharts.AST.allowedReferences.push('data:')

Vue.use(HighchartsVue)
Vue.use(require('vue-moment'))

protectRoutes(router, store)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
