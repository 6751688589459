import { DATA_TYPES } from '@/utils/constants'
import * as types from '../../types'

export default {
  [types.PRODUCTS_CREATE_PRODUCT_FETCHING](state) {
    Object.assign(state, { fetchingCreateProduct: true, fetchCreateProductError: '' })
  },
  [types.PRODUCTS_CREATE_PRODUCT_SUCCESS](state) {
    Object.assign(state, { fetchingCreateProduct: false })
  },
  [types.PRODUCTS_CREATE_PRODUCT_ERROR](state, fetchCreateProductError) {
    Object.assign(state, { fetchingCreateProduct: false, fetchCreateProductError })
  },

  [types.PRODUCTS_FETCHING_PRODUCTS](state) {
    Object.assign(state, { fetchingProducts: true, fetchProductsError: '' })
  },
  [types.PRODUCTS_RECEIVE_PRODUCTS](state, { products, totalRecords }) {
    Object.assign(state, { fetchingProducts: false, products, totalProducts: totalRecords })
  },
  [types.PRODUCTS_RECEIVE_PRODUCTS_ERROR](state, fetchProductsError) {
    Object.assign(state, { fetchingProducts: false, fetchProductsError })
  },

  [types.PRODUCTS_FETCHING_PRODUCTS_SUMMARY](state) {
    Object.assign(state, { fetchingProductsSummary: true, fetchProductsSummaryError: '' })
  },
  [types.PRODUCTS_RECEIVE_PRODUCTS_SUMMARY](state, {
    products, isInfinityScroll, page, totalRecords,
    pageSize,
  }) {
    Object.assign(state, {
      fetchingProductsSummary: false,
      productsSummary: isInfinityScroll ? [...state.productsSummary, ...products] : products,
      summaryPageSize: parseInt(pageSize, 10),
      summaryPage: parseInt(page, 10),
      totalProducts: totalRecords,
    })
  },
  [types.PRODUCTS_RECEIVE_PRODUCTS_SUMMARY_ERROR](state, fetchProductsSummaryError) {
    Object.assign(state, { fetchingProductsSummary: false, fetchProductsSummaryError })
  },
  [types.PRODUCTS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.PRODUCTS_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.PRODUCTS_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.PRODUCTS_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },
  [types.PRODUCTS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
  [types.PRODUCTS_DELETING_PRODUCTS](state, deletingProducts) {
    Object.assign(state, { deletingProducts })
  },
  [types.PRODUCTS_DELETE_PRODUCTS_ERROR](state, deleteProductsError) {
    Object.assign(state, { deletingProducts: false, deleteProductsError })
  },
  [types.PRODUCTS_FETCHING_EDIT_TAGS](state) {
    Object.assign(state, { fetchingEditTags: true, editTagsError: '' })
  },
  [types.PRODUCTS_EDIT_TAGS_SUCCESS](state) {
    Object.assign(state, { fetchingEditTags: false })
  },
  [types.PRODUCTS_EDIT_TAGS_ERROR](state, editTagsError) {
    Object.assign(state, { fetchingEditTags: false, editTagsError })
  },
  [types.PRODUCTS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },

  ///
  [types.PRODUCTS_FETCHING_BCR_LINK_STATUS](state) {
    Object.assign(state, { fetchingBCRLinkStatus: true, fetchBCRLinkStatusError: '' })
  },
  [types.PRODUCTS_RECEIVE_BCR_LINK_STATUS](state, bcrLinkStatus) {
    Object.assign(state, {
      fetchingBCRLinkStatus: false,
      bcrLinkStatus: {
        ...bcrLinkStatus,
        dataType: DATA_TYPES.PRODUCTS,
      },
    })
  },
  [types.PRODUCTS_RECEIVE_BCR_LINK_STATUS_ERROR](state, fetchBCRLinkStatusError) {
    Object.assign(state, { fetchingBCRLinkStatus: false, fetchBCRLinkStatusError })
  },

  [types.PRODUCTS_FETCHING_LINK_PRODUCT_TO_BCR](state) {
    Object.assign(state, { fetchingLinkProductToBCR: true, fetchingLinkProductToBCRError: '' })
  },
  [types.PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR](state) {
    Object.assign(state, { fetchingLinkProductToBCR: false })
  },
  [types.PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR_ERROR](state, fetchingLinkProductToBCRError) {
    Object.assign(state, { fetchingLinkProductToBCR: false, fetchingLinkProductToBCRError })
  },

  [types.PRODUCTS_FETCHING_RETRY_LINK_PRODUCT_TO_BCR](state) {
    Object.assign(state, { fetchingRetryLinkProductToBCR: true, fetchingRetryLinkProductToBCRError: '' })
  },
  [types.PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR](state) {
    Object.assign(state, { fetchingRetryLinkProductToBCR: false })
  },
  // eslint-disable-next-line max-len
  [types.PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR_ERROR](state, fetchingRetryLinkProductToBCRError) {
    Object.assign(state, {
      fetchingRetryLinkProductToBCR: false,
      fetchingRetryLinkProductToBCRError,
    })
  },

  [types.PRODUCTS_FETCHING_CREATE_BCR_QUERY](state) {
    Object.assign(state, { fetchingCreateBCRQuery: true, fetchingCreateBCRQueryError: '' })
  },
  [types.PRODUCTS_RECEIVE_CREATE_BCR_QUERY](state) {
    Object.assign(state, {
      fetchingCreateBCRQuery: false,
      fetchingCreateBCRQueryError: '',
    })
  },
  [types.PRODUCTS_RECEIVE_CREATE_BCR_QUERY_ERROR](state, fetchingCreateBCRQueryError) {
    Object.assign(state, { fetchingCreateBCRQuery: false, fetchingCreateBCRQueryError })
  },

  [types.PRODUCTS_FETCHING_SUGGESTED_BCR_QUERY](state) {
    Object.assign(state, {
      suggestedBCRQuery: '',
      fetchingSuggestedBCRQuery: true,
      fetchingSuggestedBCRQueryError: '',
    })
  },
  [types.PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY](state, { suggestedQuery }) {
    Object.assign(state, {
      suggestedBCRQuery: suggestedQuery,
      fetchingSuggestedBCRQuery: false,
      fetchingSuggestedBCRQueryError: '',
    })
  },
  [types.PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR](state, fetchingSuggestedBCRQueryError) {
    Object.assign(state, {
      fetchingSuggestedBCRQuery: false,
      fetchingSuggestedBCRQueryError,
    })
  },

  [types.PRODUCTS_FETCHING_EDIT_URLS](state) {
    Object.assign(state, { fetchingEditProductsUrls: true, editProductsUrlsError: '' })
  },
  [types.PRODUCTS_EDIT_URLS_SUCCESS](state) {
    Object.assign(state, { fetchingEditProductsUrls: false, editProductsUrlsError: '' })
  },
  [types.PRODUCTS_EDIT_URLS_ERROR](state, editProductsUrlsError) {
    Object.assign(state, { fetchingEditProductsUrls: false, editProductsUrlsError })
  },

  [types.PRODUCTS_DOWNLOAD_REVIEWS](state) {
    Object.assign(state, { fetchingDownloadReviews: true })
  },
  [types.PRODUCTS_RECEIVE_DOWNLOAD_REVIEWS](state) {
    Object.assign(state, { fetchingDownloadReviews: false, fetchDownloadReviewsError: '' })
  },
  [types.PRODUCTS_DOWNLOAD_REVIEWS_ERROR](state, fetchingDownloadReviewsError) {
    Object.assign(state, { fetchingDownloadReviews: false, fetchingDownloadReviewsError })
  },
  [types.PRODUCTS_FETCHING_PRODUCT_UPDATE](state) {
    Object.assign(state, { fetchingProductsUpdate: true, fetchingProductsUpdateError: '' })
  },
  [types.PRODUCTS_FETCHING_PRODUCT_UPDATE_SUCCESS](state) {
    Object.assign(state, { fetchingProductsUpdate: false, fetchingProductsUpdateError: '' })
  },
  [types.PRODUCTS_FETCHING_PRODUCT_UPDATE_FAILURE](state, fetchingProductsUpdateError) {
    Object.assign(state, { fetchingProductsUpdate: false, fetchingProductsUpdateError })
  },
}
