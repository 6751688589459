import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  userData: {},
  loginError: '',

  userQuota: {
    urlsQuota: {},
  },
  fetchingUserQuota: false,
  fetchUserQuotaError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
