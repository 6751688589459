import * as storageApi from './storage'

const apiRoot = process.env.VUE_APP_API_ROOT_V3 || 'http://localhost:4040'

const authorizationHeader = () => {
  const token = storageApi.get('token')

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    }
  }

  return {}
}

const getPath = (path) => `${apiRoot}${path}`

const postHeaders = () => ({
  method: 'POST',
  headers: { 'Content-Type': 'application/json', ...authorizationHeader() },
})

const putHeaders = () => ({
  method: 'PUT',
  headers: { 'Content-Type': 'application/json', ...authorizationHeader() },
})

const patchHeaders = () => ({
  method: 'PATCH',
  headers: { 'Content-Type': 'application/json', ...authorizationHeader() },
})

const getHeaders = () => ({
  method: 'GET',
  headers: { ...authorizationHeader() },
})

const deleteHeaders = () => ({
  method: 'DELETE',
  headers: { 'Content-Type': 'application/json', ...authorizationHeader() },
})

const generateQueryString = (payload) => {
  if (!payload) {
    return ''
  }

  return Object.keys(payload).reduce((memo, key) => {
    if (payload[key] === undefined) {
      return memo
    }

    if (Array.isArray(payload[key])) {
      return memo + payload[key].reduce((arrMemo, v) => {
        if (typeof v === 'object') {
          // eslint-disable-next-line no-param-reassign
          v = Object.values(v).join('|')
        }
        return `${arrMemo}${encodeURIComponent(key)}[]=${encodeURIComponent(v)}&`
      }, '')
    }

    if (payload[key] !== null && typeof payload[key] === 'object') {
      return `${memo}${key}=${JSON.stringify(payload[key])}&`
    }

    return `${memo}${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}&`
  }, '?')
}

const getErrorMessageFromResponse = (err) => {
  if (Array.isArray(err.errors) && err.errors.length) {
    return err.errors[0].msg
  }

  return err.message
}

const parseJson = (options) => async (response) => {
  if (response.status === 204) {
    return null
  }

  if (response.status === 200 || response.status === 201) {
    return response.json()
  }

  const errorResponse = await response.json()

  if (options.rawErrors) {
    throw new Error(JSON.stringify(errorResponse.errors))
  }

  const message = getErrorMessageFromResponse(errorResponse)

  throw new Error(message)
}

const validatePlainContent = (response) => {
  if (response.ok) {
    return response
  }
  const message = 'Error validating plain content'
  console.error({ error: response, message })
  throw new Error(message)
}

const parseBlob = (response) => response.blob()

export const post = (path, payload, options = {}) => fetch(`${getPath(path)}`, { body: JSON.stringify(payload), ...postHeaders() })
  .then(parseJson(options))

export const put = (path, payload, options = {}) => fetch(`${getPath(path)}`, { body: JSON.stringify(payload), ...putHeaders() })
  .then(parseJson(options))

export const get = (path, payload, options = {}) => fetch(`${getPath(path)}${generateQueryString(payload)}`, getHeaders())
  .then(parseJson(options))

export const getPlainContent = (path, payload) => fetch(`${getPath(path)}${generateQueryString(payload)}`, getHeaders())
  .then(validatePlainContent)
export const getRaw = (path, payload) => fetch(`${getPath(path)}${generateQueryString(payload)}`, getHeaders())
  .then(parseBlob)

export const del = (path, options = {}) => fetch(`${getPath(path)}`, deleteHeaders())
  .then(parseJson(options))

export const patch = (path, payload, options = {}) => fetch(`${getPath(path)}`, { body: JSON.stringify(payload), ...patchHeaders() })
  .then(parseJson(options))
