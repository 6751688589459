import * as serverApi from './server'

export const create = ({ project }) => serverApi.post('/v3/projects', { ...project })
export const getAll = (queryParams) => serverApi.get('/v3/projects', queryParams)
export const edit = ({ project }) => serverApi.put(`/v3/projects/${project.id}`, { ...project })
export const getOne = ({ projectId }) => serverApi.get(`/v3/projects/${projectId}`)
export const deleteOne = ({ projectId }) => serverApi.del(`/v3/projects/${projectId}`)
export const addUrls = ({
  op,
  projectIds,
  urlIds,
  filters,
}) => serverApi.patch('/v3/projects/urls', {
  op,
  projectIds,
  urlIds,
  filters,
})
export const addProducts = ({
  op,
  projectIds,
  productIds,
  filters,
}) => serverApi.patch('/v3/projects/products', {
  op,
  projectIds,
  productIds,
  filters,
})
export const addTags = ({
  op,
  projectIds,
  tagIds,
  filters,
}) => serverApi.patch('/v3/projects/tags', {
  op,
  projectIds,
  tagIds,
  filters,
})

export const addCategories = ({
  op,
  projectIds,
  categoryIds,
  filters,
}) => serverApi.patch('/v3/projects/categories', {
  op,
  projectIds,
  categoryIds,
  filters,
})

export const addBrands = ({
  op,
  projectIds,
  brandIds,
  filters,
}) => serverApi.patch('/v3/projects/brands', {
  op,
  projectIds,
  brandIds,
  filters,
})
