export const loggedIn = (state) => state.userData?.user?.username?.length > 0
export const loginError = (state) => state.loginError
export const isOverQuota = (state) => {
  const { userQuota, fetchingUserQuota } = state
  const { urlsQuota } = userQuota

  if (!urlsQuota || fetchingUserQuota) {
    return false
  }

  const { remainingUrlsQuota } = urlsQuota

  return remainingUrlsQuota === 0
}

export const isAdmin = (state) => state.userData.appFeatures.includes(('admin'))

// eslint-disable-next-line max-len
export const hasAppFeature = (state) => (featureName) => {
  const { userData } = state
  return userData.appFeatures.includes(featureName)
}
export const clientCanLinkToBCR = (state) => {
  const { userData } = state
  const { client, appFeatures = [] } = userData
  const { modules = [] } = client

  const hasCustomContentApiModule = modules.includes('CUSTOM_CONTENT_API')
  const hasBCRLinkFeature = appFeatures.includes('bcrconnector')

  return hasCustomContentApiModule && hasBCRLinkFeature
}
