import * as types from '../../types'

export default {
  [types.FILTERS_SET_PRODUCT_IDS](state, { productIds }) {
    let { filterKey } = state
    filterKey += productIds.length
    Object.assign(state, { productIds, filterKey })
  },

  [types.FILTERS_SET_DOMAIN_IDS](state, { domainIds }) {
    let { filterKey } = state
    filterKey += domainIds.length
    Object.assign(state, { domainIds, filterKey })
  },

  [types.FILTERS_SET_TAG_IDS](state, { tagIds }) {
    let { filterKey } = state
    filterKey += tagIds.length
    Object.assign(state, { tagIds, filterKey })
  },

  [types.FILTERS_SET_BRAND_IDS](state, { brandIds }) {
    let { filterKey } = state
    filterKey += brandIds.length
    Object.assign(state, { brandIds, filterKey })
  },

  [types.FILTERS_SET_CATEGORY_IDS](state, { categoryIds }) {
    let { filterKey } = state
    filterKey += categoryIds.length
    Object.assign(state, { categoryIds, filterKey })
  },

  [types.FILTERS_SET_MIN_REVIEWS](state, { minReviews }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { minReviews, filterKey })
  },

  [types.FILTERS_SET_MAX_REVIEWS](state, { maxReviews }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { maxReviews, filterKey })
  },

  [types.FILTERS_SET_MIN_RATING](state, { minRating }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { minRating, filterKey })
  },

  [types.FILTERS_SET_MAX_RATING](state, { maxRating }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { maxRating, filterKey })
  },

  [types.FILTERS_SET_MIN_CREATION_DATE](state, { minCreationDate }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { minCreationDate, filterKey })
  },

  [types.FILTERS_SET_MAX_CREATION_DATE](state, { maxCreationDate }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { maxCreationDate, filterKey })
  },

  [types.FILTERS_SET_MIN_LAST_REVIEWED_DATE](state, { minLastReviewedDate }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { minLastReviewedDate, filterKey })
  },

  [types.FILTERS_SET_MAX_LAST_REVIEWED_DATE](state, { maxLastReviewedDate }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { maxLastReviewedDate, filterKey })
  },

  [types.FILTERS_SET_HOSTNAMES](state, { hostnames }) {
    let { filterKey } = state
    filterKey += hostnames.length
    Object.assign(state, { hostnames, filterKey })
  },
  [types.FILTERS_SET_SEARCH](state, { search }) {
    let { filterKey } = state
    filterKey += 1
    Object.assign(state, { search, filterKey })
  },

  [types.FILTERS_RESET](state) {
    Object.assign(state, {
      filterKey: 0,
      productIds: null,
      domainIds: null,
      tagIds: null,
      brandIds: null,
      categoryIds: null,
      minReviews: null,
      maxReviews: null,
      minRating: null,
      maxRating: null,
      minCreationDate: null,
      maxCreationDate: null,
      minLastReviewedDate: null,
      maxLastReviewedDate: null,
      hostnames: null,
      search: null,
    })
  },
}
