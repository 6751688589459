import * as types from '../../types'

export default {
  [types.ALERTS_FETCHING_RECENT_ALERTS](state) {
    Object.assign(state, { fetchingRecentAlerts: true, fetchRecentAlertsError: '' })
  },
  [types.ALERTS_RECEIVE_RECENT_ALERTS](state, { alerts }) {
    Object.assign(state, { fetchingRecentAlerts: false, recentAlerts: alerts })
  },
  [types.ALERTS_RECEIVE_RECENT_ALERTS_ERROR](state, fetchRecentAlertsError) {
    Object.assign(state, { fetchingRecentAlerts: false, fetchRecentAlertsError })
  },

  [types.ALERTS_FETCHING_ALERTS](state) {
    Object.assign(state, { fetchingAlerts: true, fetchAlertsError: '' })
  },
  [types.ALERTS_RECEIVE_ALERTS](state, { alerts, totalRecords }) {
    Object.assign(state, { fetchingAlerts: false, alerts, totalAlerts: totalRecords })
  },
  [types.ALERTS_RECEIVE_ALERTS_ERROR](state, fetchAlertsError) {
    Object.assign(state, { fetchingAlerts: false, fetchAlertsError })
  },
  [types.ALERTS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.ALERTS_MARK_SEEN_FETCHING](state) {
    Object.assign(state, { fetchingMarkAsSeen: true, fetchingMarkAsSeenError: '' })
  },
  [types.ALERTS_MARK_SEEN_SUCCESS](state) {
    Object.assign(state, { fetchingMarkAsSeen: false, fetchingMarkAsSeenError: '' })
  },
  [types.ALERTS_MARK_SEEN_ERROR](state, fetchingMarkAsSeenError) {
    Object.assign(state, { fetchingMarkAsSeen: false, fetchingMarkAsSeenError })
  },
}
