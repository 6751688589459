import * as serverApi from './server'

export const getAll = (queryParams) => serverApi.get('/v3/entitymaps', queryParams)
export const getOne = ({ entityMapId }) => serverApi.get(`/v3/entitymaps/${entityMapId}`)
export const deleteMany = ({ entityMapIds, filters }) => serverApi.post('/v3/entitymaps/remove', { entityMapIds, filters })
export const deleteOne = ({ entityMapId }) => serverApi.del(`/v3/entitymaps/${entityMapId}`)
export const create = ({ name, groups, ignore }) => serverApi.post('/v3/entitymaps', { name, groups, ignore })
export const addIgnore = ({ entityMapId, ignore }) => serverApi.post(`/v3/entitymaps/${entityMapId}/ignore`, { ignore })
export const createEntityGroup = ({ entityMapId, name, entities }) => serverApi.post(`/v3/entitymaps/${entityMapId}/groups`, { name, entities })
export const editEntityGroup = ({ entityMapId, entityGroupId, entities }) => serverApi.put(`/v3/entitymaps/${entityMapId}/groups/${entityGroupId}`, { entities })
export const editMap = ({
  entityMapId, name, groups, ignore,
}) => serverApi.put(`/v3/entitymaps/${entityMapId}`, { name, groups, ignore })
