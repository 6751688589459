import * as storageApi from './storage'
import * as serverApi from './server'

export const login = ({ username, password }) => serverApi.post('/v3/auth/login', { username, password })
  .then((body) => {
    storageApi.set('token', body.token)

    return { user: { username } }
  })

export const me = () => serverApi.get('/v3/auth/me')
export const quota = () => serverApi.get('/v3/auth/me/quota')

export const logout = async () => {
  await storageApi.del('token')
}
