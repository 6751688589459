import * as serverApi from './server'

export const getDashboard = ({ dashboardId }) => serverApi.get(`/v3/dashboards/${dashboardId}`)
export const getData = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data`, filters)
export const getReviewsHistory = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/history`, filters)
export const getReviewsNetRatingHistory = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/history/rating/net`, filters)
export const getReviewsRatingHistory = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/history/rating`, filters)
export const getReviewsSentimentHistory = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/history/sentiment`, filters)
export const getReviews = ({
  dashboardId, pagination, sorting, ...filters
}) => serverApi.get(`/v3/dashboards/${dashboardId}/data/reviews`, { ...pagination, ...sorting, ...filters })
export const getTrends = ({
  dashboardId, minDate, maxDate, ...filters
}) => serverApi.get(`/v3/dashboards/${dashboardId}/data/trends`, { minDate, maxDate, ...filters })
export const getEntities = ({ dashboardId, includeGroupData = false, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/entities`, { includeGroupData, ...filters })
export const getNetRating = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/rating/net`, filters)
export const getNumReviews = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/reviews/count`, filters)
export const getNetSentiment = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/sentiment`, filters)
export const getProducts = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/products`, filters)
export const getUrlsHistory = ({
  dashboardId, sort, sortDir, ...filters
}) => serverApi.get(`/v3/dashboards/${dashboardId}/data/history/urls`, { sort, sortDir, ...filters })
export const getProductsSentiment = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/sentiment/products`, { ...filters })
export const getProductsHistory = ({ dashboardId, filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/history/products`, { ...filters })
export const getProductsEntities = ({ dashboardId, ...filters }) => serverApi.get(`/v3/dashboards/${dashboardId}/data/entities/products`, { ...filters })
export const saveComponentSettings = ({
  dashboardId, componentId, sort, sortDir, productIds, search,
}) => serverApi.put(`/v3/dashboards/${dashboardId}/components/${componentId}/settings`, {
  sort, sortDir, productIds, search,
})
