import * as types from '../../types'

export default {
  [types.DASHBOARDSFILTERS_SET_SENTIMENT](state, { minSentiment, maxSentiment, sentiment }) {
    Object.assign(state, { minSentiment, maxSentiment, sentiment })
  },
  [types.DASHBOARDSFILTERS_SET_ENTITY_SENTIMENT](state,
    { minEntitySentiment, maxEntitySentiment, entitySentiment }) {
    Object.assign(state, { minEntitySentiment, maxEntitySentiment, entitySentiment })
  },
  [types.DASHBOARDSFILTERS_SET_ENTITY](state, { entity }) {
    Object.assign(state, { entity })
  },
  [types.DASHBOARDSFILTERS_SET_URL_IDS](state, { urlIds }) {
    Object.assign(state, { urlIds })
  },
  [types.DASHBOARDSFILTERS_SET_RATINGS](state, { ratings }) {
    Object.assign(state, { ratings })
  },
  [types.DASHBOARDSFILTERS_SET_ENTITY_MAP_ID](state, { entityMapId }) {
    Object.assign(state, { entityMapId })
  },
  [types.DASHBOARDSFILTERS_SET_ENTITY_GROUP_ID](state, { entityGroupId }) {
    Object.assign(state, { entityGroupId })
  },
  [types.DASHBOARDSFILTERS_SET_COUNTRIES](state, { countries }) {
    Object.assign(state, { countries })
  },
  [types.DASHBOARDSFILTERS_SET_HOSTNAMES](state, { hostnames }) {
    Object.assign(state, { hostnames })
  },
  [types.DASHBOARDSFILTERS_SET_SEARCH](state, { search }) {
    Object.assign(state, { search })
  },
  [types.DASHBOARDSFILTERS_SET_SUB_GROUP](state, { subGroup }) {
    Object.assign(state, { subGroup })
  },
  [types.DASHBOARDSFILTERS_SET_DATE_RANGE](state, { dateRange }) {
    if (!dateRange) {
      return Object.assign(state, { minDate: null, maxDate: null })
    }
    const [minDate, maxDate] = dateRange
    return Object.assign(state, { minDate, maxDate })
  },
  [types.DASHBOARDSFILTERS_SET_COMPARISON_TRENDING_DATE_RANGE](state, { dateRange }) {
    const { trendingDataDateRangeFilter } = state

    return Object.assign(state, {
      trendingDataDateRangeFilter: {
        ...trendingDataDateRangeFilter,
        ...dateRange,
      },
    })
  },
  [types.DASHBOARDSFILTERS_RESET](state) {
    Object.assign(state, {
      sentiment: null,
      minSentiment: null,
      maxSentiment: null,
      entitySentiment: null,
      minEntitySentiment: null,
      maxEntitySentiment: null,
      entity: null,
      urlIds: [],
      ratings: [],
      entityMapId: null,
      countries: [],
      hostnames: [],
      search: '',
      minDate: null,
      maxDate: null,
      entityGroupId: null,
      subGroup: null,
    })
  },
}
