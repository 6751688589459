const getPercentage = ({ value = 0, total }) => {
  if (!total) {
    return 0
  }

  const result = (value / total)
  const formattedResult = (result).toFixed(2)

  return parseFloat(formattedResult)
}

export default getPercentage
