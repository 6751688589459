import { ALL_DATA_PROJECT, STORAGE_KEY_PROJECT_ID } from '@/utils/constants'
import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'
import * as storageApi from '../../../api/storage'

const state = {
  fetchingCreateProject: false,
  fetchCreateProjectError: '',

  fetchingProjects: false,
  projects: [],
  page: 1,
  pageSize: 50,
  totalRecords: 0,
  activeProjectId: storageApi.get(STORAGE_KEY_PROJECT_ID) || ALL_DATA_PROJECT.id,
  fetchProjectsError: '',

  fetchEditProjectError: '',
  fetchingEditProject: false,

  editingProject: {},
  fetchingProject: false,
  fetchProjectError: '',

  fetchingDeleteProjects: false,
  fetchingDeleteProjectError: '',

  addingToProjects: false,
  addingToProjectsError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
