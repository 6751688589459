import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  sentiment: null,
  minSentiment: null,
  maxSentiment: null,
  entitySentiment: null,
  minEntitySentiment: null,
  maxEntitySentiment: null,
  entity: null,
  urlIds: [],
  ratings: [],
  entityMapId: null,
  countries: [],
  hostnames: [],
  search: '',
  minDate: null,
  maxDate: null,
  trendingDataDateRangeFilter: {
    thisPeriod: {
      minDate: null,
      maxDate: null,
    },
    lastPeriod: {
      minDate: null,
      maxDate: null,
    },
  },
  entityGroupId: null,
  subGroup: null,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
