import * as types from '../../types'
import * as productsApi from '../../../api/products'

export const createProduct = async ({ commit }, {
  name, urls, filters, projectId,
}) => {
  commit(types.PRODUCTS_CREATE_PRODUCT_FETCHING)
  try {
    const data = await productsApi.create({
      name,
      urls,
      filters,
      projectId,
    })
    return commit(types.PRODUCTS_CREATE_PRODUCT_SUCCESS, data)
  } catch (e) {
    return commit(types.PRODUCTS_CREATE_PRODUCT_ERROR, e.message)
  }
}

export const fetchProducts = async ({ commit }, fetchParams = {}) => {
  commit(types.PRODUCTS_FETCHING_PRODUCTS)

  try {
    const data = await productsApi.getAll(fetchParams)
    return commit(types.PRODUCTS_RECEIVE_PRODUCTS, data)
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_PRODUCTS_ERROR, e.message)
  }
}

export const fetchProductsSummary = async ({
  commit, state: { summaryPageSize },
}, fetchParams = {}) => {
  commit(types.PRODUCTS_FETCHING_PRODUCTS_SUMMARY)

  try {
    const { isInfinityScroll, ...rest } = fetchParams
    const params = { ...rest, pageSize: summaryPageSize }

    const data = await productsApi.getAll(params)
    return commit(types.PRODUCTS_RECEIVE_PRODUCTS_SUMMARY, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_PRODUCTS_SUMMARY_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.PRODUCTS_SET_PAGE, page)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.PRODUCTS_SET_PAGESIZE, pageSize)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.PRODUCTS_SET_SORT, sort)
  commit(types.PRODUCTS_SET_SORTDIR, sortDir)
}

export const deleteProducts = async ({ commit }, {
  op,
  productIds,
  filters = {},
  projectId,
}) => {
  commit(types.PRODUCTS_DELETING_PRODUCTS)

  try {
    await productsApi.deleteProducts({
      op,
      productIds,
      filters,
      projectId,
    })
    return commit(types.PRODUCTS_DELETING_PRODUCTS, false)
  } catch (e) {
    return commit(types.PRODUCTS_DELETE_PRODUCTS_ERROR, e.message)
  }
}

export const editProductsTags = async ({ commit }, {
  op,
  tagIds,
  productIds,
}) => {
  commit(types.PRODUCTS_FETCHING_EDIT_TAGS)

  try {
    const payload = {
      op,
      tagIds,
      productIds,
    }
    await productsApi.editTags(payload)

    return commit(types.PRODUCTS_EDIT_TAGS_SUCCESS)
  } catch (e) {
    return commit(types.PRODUCTS_EDIT_TAGS_ERROR, e.message)
  }
}

export const fetchBCRLinkStatus = async ({ commit }, { productId }) => {
  commit(types.PRODUCTS_FETCHING_BCR_LINK_STATUS)

  try {
    const data = await productsApi.getBCRLinkStatus({ productId })
    return commit(types.PRODUCTS_RECEIVE_BCR_LINK_STATUS, data)
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_BCR_LINK_STATUS_ERROR, e.message)
  }
}

export const fetchLinkProductToBCR = async ({ commit }, {
  productIds,
  projectId,
  filters = {},
}) => {
  commit(types.PRODUCTS_FETCHING_LINK_PRODUCT_TO_BCR)

  try {
    const payload = {
      productIds,
      projectId,
      filters,
    }

    const data = await productsApi.linkProductToBCR(payload)
    return commit(types.PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR, data)
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR_ERROR, e.message)
  }
}

export const fetchRetryLinkProductToBCR = async ({ commit }, { productIds }) => {
  commit(types.PRODUCTS_FETCHING_RETRY_LINK_PRODUCT_TO_BCR)
  const payload = { productIds }

  try {
    const data = await productsApi.retryLinkProductToBCR(payload)
    return commit(types.PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR, data)
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR_ERROR, e.message)
  }
}

export const fetchCreateBCRQuery = async ({ commit }, {
  productIds,
  projectId,
  filters = {},
  bcrProjectId,
  queryName,
  queryDefinition,
}) => {
  commit(types.PRODUCTS_FETCHING_CREATE_BCR_QUERY)

  try {
    const payload = {
      productIds,
      filters,
      projectId,
      bcrProjectId,
      queryName,
      queryDefinition,
    }

    const data = await productsApi.createBCRQuery(payload)
    return commit(types.PRODUCTS_RECEIVE_CREATE_BCR_QUERY, data)
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_CREATE_BCR_QUERY_ERROR, e.message)
  }
}

export const fetchSuggestedBCRQuery = async ({ commit }, {
  productIds,
  projectId,
  filters = {},
}) => {
  commit(types.PRODUCTS_FETCHING_SUGGESTED_BCR_QUERY)

  try {
    const payload = {
      productIds,
      projectId,
      filters,
    }

    const data = await productsApi.getBCRQuerySuggestion(payload)
    return commit(types.PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY, data)
  } catch (e) {
    return commit(types.PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR, e.message)
  }
}

export const editProductsUrls = async ({ commit }, {
  op,
  urlIds,
  productIds,
}) => {
  commit(types.PRODUCTS_FETCHING_EDIT_URLS)

  try {
    const payload = {
      op,
      urlIds,
      productIds,
    }
    await productsApi.editUrls(payload)

    return commit(types.PRODUCTS_EDIT_URLS_SUCCESS)
  } catch (e) {
    return commit(types.PRODUCTS_EDIT_URLS_ERROR, e.message)
  }
}

export const downloadReviews = async ({ commit }, { productId }) => {
  commit(types.PRODUCTS_DOWNLOAD_REVIEWS)

  try {
    const blob = await productsApi.download({ productId })

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    const date = (new Date()).toISOString().split('T')[0]
    a.href = url
    a.download = `export-products-${productId}-reviews-${date}.json`
    document.body.appendChild(a)
    a.click()
    a.remove()
    return commit(types.PRODUCTS_RECEIVE_DOWNLOAD_REVIEWS)
  } catch (e) {
    return commit(types.PRODUCTS_DOWNLOAD_REVIEWS_ERROR, e.message)
  }
}

export const clearErrors = async (
  { commit },
  errorKeys = {},
) => commit(types.PRODUCTS_CLEAR_ERRORS, errorKeys)

export const updateProduct = async ({ commit }, {
  urls,
  productId,
  name,
  projectId,
}) => {
  commit(types.PRODUCTS_FETCHING_PRODUCT_UPDATE)

  try {
    const payload = {
      urls,
      productId,
      name,
      projectId,
    }
    await productsApi.update(payload)

    return commit(types.PRODUCTS_FETCHING_PRODUCT_UPDATE_SUCCESS)
  } catch (e) {
    return commit(types.PRODUCTS_FETCHING_PRODUCT_UPDATE_FAILURE, e.message)
  }
}
