import * as types from '../../types'

const clearState = (state) => {
  state.userData = {}
  state.loginError = ''
}

export default {
  [types.USER_CLEAR_STATE](state) {
    clearState(state)
  },
  [types.USER_RECEIVE_USER](state, userData) {
    Object.assign(state, { userData })
  },
  [types.USER_LOGIN_ERROR](state, loginError) {
    Object.assign(state, { loginError })
  },

  [types.USER_FETCHING_USER_QUOTA](state) {
    Object.assign(state, { userQuota: {}, fetchingUserQuota: true })
  },
  [types.USER_RECEIVE_USER_QUOTA](state, userQuota) {
    Object.assign(state, { userQuota, fetchingUserQuota: false })
  },
  [types.USER_RECEIVE_USER_QUOTA_ERROR](state, fetchUserQuotaError) {
    Object.assign(state, { fetchingUserQuota: false, fetchUserQuotaError })
  },
}
