export const USER_CLEAR_STATE = 'USER_CLEAR_STATE'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const USER_RECEIVE_USER = 'USER_RECEIVE_USER'
export const USER_FETCHING_USER_QUOTA = 'USER_FETCHING_USER_QUOTA'
export const USER_RECEIVE_USER_QUOTA = 'USER_RECEIVE_USER_QUOTA'
export const USER_RECEIVE_USER_QUOTA_ERROR = 'USER_RECEIVE_USER_QUOTA_ERROR'

export const URLS_CREATE_SUCCESS = 'URLS_CREATE_SUCCESS'
export const URLS_CREATE_FETCHING = 'URLS_CREATE_FETCHING'
export const URLS_CREATE_ERROR = 'URLS_CREATE_ERROR'
export const URLS_FETCHING_URLS = 'URLS_FETCHING_URLS'
export const URLS_RECEIVE_URLS = 'URLS_RECEIVE_URLS'
export const URLS_MAX_URLS = 'URLS_MAX_URLS'
export const URLS_RECEIVE_URLS_ERROR = 'URLS_RECEIVE_URLS_ERROR'
export const URLS_EXPORT_URLS = 'URLS_EXPORT_URLS'
export const URLS_RECEIVE_EXPORT_URLS = 'URLS_RECEIVE_EXPORT_URLS'
export const URLS_RECEIVE_EXPORT_URLS_ERROR = 'URLS_RECEIVE_EXPORT_URLS_ERROR'
export const URLS_FETCHING_URLS_SUMMARY = 'URLS_FETCHING_URLS_SUMMARY'
export const URLS_FETCHING_URLS_SUMMARY_ERROR = 'URLS_FETCHING_URLS_SUMMARY_ERROR'
export const URLS_RECEIVE_URLS_SUMMARY = 'URLS_RECEIVE_URLS_SUMMARY'
export const URLS_SET_PAGE = 'URLS_SET_PAGE'
export const URLS_SET_PAGE_SUMMARY = 'URLS_SET_PAGE_SUMMARY'
export const URLS_SET_PAGESIZE = 'URLS_SET_PAGESIZE'
export const URLS_SET_SORT = 'URLS_SET_SORT'
export const URLS_SET_SORTDIR = 'URLS_SET_SORTDIR'
export const URLS_FETCHING_DELETE_URL = 'URLS_FETCHING_DELETE_URL'
export const URLS_RECEIVE_DELETE_URL = 'URLS_RECEIVE_DELETE_URL'
export const URLS_RECEIVE_DELETE_URL_ERROR = 'URLS_RECEIVE_DELETE_URL_ERROR'
export const URLS_FETCHING_DELETE_URLS = 'URLS_FETCHING_DELETE_URLS'
export const URLS_RECEIVE_DELETE_URLS = 'URLS_RECEIVE_DELETE_URLS'
export const URLS_RECEIVE_DELETE_URLS_ERROR = 'URLS_RECEIVE_DELETE_URLS_ERROR'
export const URLS_FETCHING_VALIDATE_URLS = 'URLS_FETCHING_VALIDATE_URLS'
export const URLS_RECEIVE_VALID_URLS = 'URLS_RECEIVE_VALID_URLS'
export const URLS_RECEIVE_VALID_URLS_ERROR = 'URLS_RECEIVE_VALID_URLS_ERROR'
export const URLS_FETCHING_VALIDATE_LISTING_URLS = 'URLS_FETCHING_VALIDATE_LISTING_URLS'
export const URLS_RECEIVE_VALID_LISTING_URLS = 'URLS_RECEIVE_VALID_LISTING_URLS'
export const URLS_RECEIVE_VALID_LISTING_URLS_ERROR = 'URLS_RECEIVE_VALID_LISTING_URLS_ERROR'
export const URLS_GET_HOST_NAMES_REQUEST = 'URLS_GET_HOST_NAMES_REQUEST'
export const URLS_GET_HOST_NAMES_SUCCESS = 'URLS_GET_HOST_NAMES_SUCCESS'
export const URLS_GET_HOST_NAMES_ERROR = 'URLS_GET_HOST_NAMES_ERROR'
export const URLS_FETCHING_BCR_LINK_STATUS = 'URLS_FETCHING_BCR_LINK_STATUS'
export const URLS_RECEIVE_BCR_LINK_STATUS = 'URLS_RECEIVE_BCR_LINK_STATUS'
export const URLS_RECEIVE_BCR_LINK_STATUS_ERROR = 'URLS_RECEIVE_BCR_LINK_STATUS_ERROR'
export const URLS_FETCHING_LINK_URL_TO_BCR = 'URLS_FETCHING_LINK_URL_TO_BCR'
export const URLS_RECEIVE_LINK_URL_TO_BCR = 'URLS_RECEIVE_LINK_URL_TO_BCR'
export const URLS_RECEIVE_LINK_URL_TO_BCR_ERROR = 'URLS_RECEIVE_LINK_URL_TO_BCR_ERROR'
export const URLS_FETCHING_RETRY_LINK_URL_TO_BCR = 'URLS_FETCHING_RETRY_LINK_URL_TO_BCR'
export const URLS_RECEIVE_RETRY_LINK_URL_TO_BCR = 'URLS_RECEIVE_RETRY_LINK_URL_TO_BCR'
export const URLS_RECEIVE_RETRY_LINK_URL_TO_BCR_ERROR = 'URLS_RECEIVE_RETRY_LINK_URL_TO_BCR_ERROR'
export const URLS_FETCHING_CREATE_BCR_QUERY = 'URLS_FETCHING_CREATE_BCR_QUERY'
export const URLS_RECEIVE_CREATE_BCR_QUERY = 'URLS_RECEIVE_CREATE_BCR_QUERY'
export const URLS_RECEIVE_CREATE_BCR_QUERY_ERROR = 'URLS_RECEIVE_CREATE_BCR_QUERY_ERROR'
export const URLS_FETCHING_SUGGESTED_BCR_QUERY = 'URLS_FETCHING_SUGGESTED_BCR_QUERY'
export const URLS_RECEIVE_SUGGESTED_BCR_QUERY = 'URLS_RECEIVE_SUGGESTED_BCR_QUERY'
export const URLS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR = 'URLS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR'
export const URLS_DOWNLOAD_REVIEWS = 'URLS_DOWNLOAD_REVIEWS'
export const URLS_DOWNLOAD_REVIEWS_ERROR = 'URLS_DOWNLOAD_REVIEWS_ERROR'
export const URLS_RECEIVE_DOWNLOAD_REVIEWS = 'URLS_RECEIVE_DOWNLOAD_REVIEWS'
export const URLS_CLEAR_ERRORS = 'URLS_CLEAR_ERRORS'

export const RECENT_DATA_FETCHING = 'RECENT_DATA_FETCHING'
export const RECENT_DATA_RECEIVE = 'RECENT_DATA_RECEIVE'
export const RECENT_DATA_ERROR = 'RECENT_DATA_ERROR'

export const PROJECTS_CREATE_PROJECT_FETCHING = 'PROJECTS_CREATE_PROJECT_FETCHING'
export const PROJECTS_CREATE_PROJECT_SUCCESS = 'PROJECTS_CREATE_PROJECT_SUCCESS'
export const PROJECTS_CREATE_PROJECT_ERROR = 'PROJECTS_CREATE_PROJECT_ERROR'
export const PROJECTS_FETCHING_PROJECTS = 'PROJECTS_FETCHING_PROJECTS'
export const PROJECTS_RECEIVE_PROJECTS = 'PROJECTS_RECEIVE_PROJECTS'
export const PROJECTS_RECEIVE_PROJECTS_ERROR = 'PROJECTS_RECEIVE_PROJECTS_ERROR'
export const PROJECTS_SELECT_PROJECT = 'PROJECTS_SELECT_PROJECT'
export const PROJECTS_EDIT_PROJECT_FETCHING = 'PROJECTS_EDIT_PROJECT_FETCHING'
export const PROJECTS_EDIT_PROJECT_SUCCESS = 'PROJECTS_EDIT_PROJECT_SUCCESS'
export const PROJECTS_EDIT_PROJECT_ERROR = 'PROJECTS_EDIT_PROJECT_ERROR'
export const PROJECTS_FETCHING_PROJECT = 'PROJECTS_FETCHING_PROJECT'
export const PROJECTS_RECEIVE_PROJECT = 'PROJECTS_RECEIVE_PROJECT'
export const PROJECTS_RECEIVE_PROJECT_ERROR = 'PROJECTS_RECEIVE_PROJECT_ERROR'
export const PROJECTS_DELETE_PROJECT_FETCHING = 'PROJECTS_DELETE_PROJECT_FETCHING'
export const PROJECTS_DELETE_PROJECT_SUCCESS = 'PROJECTS_DELETE_PROJECT_SUCCESS'
export const PROJECTS_DELETE_PROJECT_ERROR = 'PROJECTS_DELETE_PROJECT_ERROR'
export const PROJECTS_RESET_ERRORS = 'PROJECTS_RESET_ERRORS'
export const ADD_TO_PROJECTS = 'ADD_TO_PROJECTS'
export const ADD_TO_PROJECTS_ERROR = 'ADD_TO_PROJECTS_ERROR'

export const PRODUCTS_CREATE_PRODUCT_FETCHING = 'PRODUCTS_CREATE_PRODUCT_FETCHING'
export const PRODUCTS_CREATE_PRODUCT_SUCCESS = 'PRODUCTS_CREATE_PRODUCT_SUCCESS'
export const PRODUCTS_CREATE_PRODUCT_ERROR = 'PRODUCTS_CREATE_PRODUCT_ERROR'
export const PRODUCTS_FETCHING_PRODUCTS = 'PRODUCTS_FETCHING_PRODUCTS'
export const PRODUCTS_RECEIVE_PRODUCTS = 'PRODUCTS_RECEIVE_PRODUCTS'
export const PRODUCTS_RECEIVE_PRODUCTS_ERROR = 'PRODUCTS_RECEIVE_PRODUCTS_ERROR'
export const PRODUCTS_FETCHING_PRODUCTS_SUMMARY = 'PRODUCTS_FETCHING_PRODUCTS_SUMMARY'
export const PRODUCTS_RECEIVE_PRODUCTS_SUMMARY = 'PRODUCTS_RECEIVE_PRODUCTS_SUMMARY'
export const PRODUCTS_RECEIVE_PRODUCTS_SUMMARY_ERROR = 'PRODUCTS_RECEIVE_PRODUCTS_SUMMARY_ERROR'
export const PRODUCTS_SET_PAGE = 'PRODUCTS_SET_PAGE'
export const PRODUCTS_SET_PAGESIZE = 'PRODUCTS_SET_PAGESIZE'
export const PRODUCTS_SET_SORT = 'PRODUCTS_ET_SORT'
export const PRODUCTS_SET_SORTDIR = 'PRODUCTS_SET_SORTDIR'
export const PRODUCTS_DELETING_PRODUCTS = 'PRODUCTS_DELETING_PRODUCTS'
export const PRODUCTS_DELETE_PRODUCTS_ERROR = 'PRODUCTS_DELETE_PRODUCTS_ERROR'
export const PRODUCTS_FETCHING_EDIT_TAGS = 'PRODUCTS_FETCHING_EDIT_TAGS'
export const PRODUCTS_EDIT_TAGS_SUCCESS = 'PRODUCTS_EDIT_TAGS_SUCCESS'
export const PRODUCTS_EDIT_TAGS_ERROR = 'PRODUCTS_EDIT_TAGS_ERROR'
export const PRODUCTS_FETCHING_BCR_LINK_STATUS = 'PRODUCTS_FETCHING_BCR_LINK_STATUS'
export const PRODUCTS_RECEIVE_BCR_LINK_STATUS = 'PRODUCTS_RECEIVE_BCR_LINK_STATUS'
export const PRODUCTS_RECEIVE_BCR_LINK_STATUS_ERROR = 'PRODUCTS_RECEIVE_BCR_LINK_STATUS_ERROR'
export const PRODUCTS_FETCHING_LINK_PRODUCT_TO_BCR = 'PRODUCTS_FETCHING_LINK_PRODUCT_TO_BCR'
export const PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR = 'PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR'
export const PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR_ERROR = 'PRODUCTS_RECEIVE_LINK_PRODUCT_TO_BCR_ERROR'
export const PRODUCTS_FETCHING_RETRY_LINK_PRODUCT_TO_BCR = 'PRODUCTS_FETCHING_RETRY_LINK_PRODUCT_TO_BCR'
export const PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR = 'PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR'
export const PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR_ERROR = 'PRODUCTS_RECEIVE_RETRY_LINK_PRODUCT_TO_BCR_ERROR'
export const PRODUCTS_FETCHING_CREATE_BCR_QUERY = 'PRODUCTS_FETCHING_CREATE_BCR_QUERY'
export const PRODUCTS_RECEIVE_CREATE_BCR_QUERY = 'PRODUCTS_RECEIVE_CREATE_BCR_QUERY'
export const PRODUCTS_RECEIVE_CREATE_BCR_QUERY_ERROR = 'PRODUCTS_RECEIVE_CREATE_BCR_QUERY_ERROR'
export const PRODUCTS_FETCHING_SUGGESTED_BCR_QUERY = 'PRODUCTS_FETCHING_SUGGESTED_BCR_QUERY'
export const PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY = 'PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY'
export const PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR = 'PRODUCTS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR'
export const PRODUCTS_FETCHING_EDIT_URLS = 'PRODUCTS_FETCHING_EDIT_URLS'
export const PRODUCTS_EDIT_URLS_SUCCESS = 'PRODUCTS_EDIT_URLS_SUCCESS'
export const PRODUCTS_EDIT_URLS_ERROR = 'PRODUCTS_EDIT_URLS_ERROR'
export const PRODUCTS_DOWNLOAD_REVIEWS = 'PRODUCTS_DOWNLOAD_REVIEWS'
export const PRODUCTS_DOWNLOAD_REVIEWS_ERROR = 'PRODUCTS_DOWNLOAD_REVIEWS_ERROR'
export const PRODUCTS_RECEIVE_DOWNLOAD_REVIEWS = 'PRODUCTS_RECEIVE_DOWNLOAD_REVIEWS'
export const PRODUCTS_CLEAR_ERRORS = 'PRODUCTS_CLEAR_ERRORS'

export const PRODUCTS_FETCHING_PRODUCT_UPDATE = 'PRODUCTS_FETCHING_PRODUCT_UPDATE'
export const PRODUCTS_FETCHING_PRODUCT_UPDATE_SUCCESS = 'PRODUCTS_FETCHING_PRODUCT_UPDATE_SUCCESS'
export const PRODUCTS_FETCHING_PRODUCT_UPDATE_FAILURE = 'PRODUCTS_FETCHING_PRODUCT_UPDATE_FAILURE'

export const BRANDS_CREATE_BRAND_SUCCESS = 'BRANDS_CREATE_BRAND_SUCCESS'
export const BRANDS_CREATE_BRAND_FETCHING = 'BRANDS_CREATE_BRAND_FETCHING'
export const BRANDS_CREATE_BRAND_ERROR = 'BRANDS_CREATE_BRAND_ERROR'
export const BRANDS_FETCHING_BRANDS = 'BRANDS_FETCHING_BRANDS'
export const BRANDS_RECEIVE_BRANDS = 'BRANDS_RECEIVE_BRANDS'
export const BRANDS_RECEIVE_BRANDS_ERROR = 'BRANDS_RECEIVE_BRANDS_ERROR'
export const BRANDS_FETCHING_BRANDS_SUMMARY = 'BRANDS_FETCHING_BRANDS_SUMMARY'
export const BRANDS_RECEIVE_BRANDS_SUMMARY = 'BRANDS_RECEIVE_BRANDS_SUMMARY'
export const BRANDS_RECEIVE_BRANDS_SUMMARY_ERROR = 'BRANDS_RECEIVE_BRANDS_SUMMARY_ERROR'
export const BRANDS_FETCHING_EDIT_BRANDS_URLS = 'BRANDS_FETCHING_EDIT_BRANDS_URLS'
export const BRANDS_EDIT_BRANDS_URLS_SUCCESS = 'BRANDS_EDIT_BRANDS_URLS_SUCCESS'
export const BRANDS_EDIT_BRANDS_URLS_ERROR = 'BRANDS_EDIT_BRANDS_URLS_ERROR'
export const BRANDS_SET_PAGE = 'BRANDS_SET_PAGE'
export const BRANDS_SET_PAGESIZE = 'BRANDS_SET_PAGESIZE'
export const BRANDS_SET_SORT = 'BRANDS_ET_SORT'
export const BRANDS_SET_SORTDIR = 'BRANDS_SET_SORTDIR'
export const BRANDS_CLEAR_ERRORS = 'BRANDS_CLEAR_ERRORS'
export const BRANDS_FETCHING_EDIT_BRANDS_PRODUCTS = 'BRANDS_FETCHING_EDIT_BRANDS_PRODUCTS'
export const BRANDS_EDIT_BRANDS_PRODUCTS_SUCCESS = 'BRANDS_EDIT_BRANDS_PRODUCTS_SUCCESS'
export const BRANDS_EDIT_BRANDS_PRODUCTS_ERROR = 'BRANDS_EDIT_BRANDS_PRODUCTS_ERROR'
export const BRANDS_DELETING_BRANDS = 'BRANDS_DELETING_BRANDS'
export const BRANDS_DELETE_BRANDS_ERROR = 'BRANDS_DELETE_BRANDS_ERROR'

export const CATEGORIES_CREATE_CATEGORY_SUCCESS = 'CATEGORIES_CREATE_CATEGORY_SUCCESS'
export const CATEGORIES_CREATE_CATEGORY_FETCHING = 'CATEGORIES_CREATE_CATEGORY_FETCHING'
export const CATEGORIES_CREATE_CATEGORY_ERROR = 'CATEGORIES_CREATE_CATEGORY_ERROR'
export const CATEGORIES_FETCHING_CATEGORIES = 'CATEGORIES_FETCHING_CATEGORIES'
export const CATEGORIES_RECEIVE_CATEGORIES = 'CATEGORIES_RECEIVE_CATEGORIES'
export const CATEGORIES_RECEIVE_CATEGORIES_ERROR = 'CATEGORIES_RECEIVE_CATEGORIES_ERROR'
export const CATEGORIES_FETCHING_CATEGORIES_SUMMARY = 'CATEGORIES_FETCHING_CATEGORIES_SUMMARY'
export const CATEGORIES_RECEIVE_CATEGORIES_SUMMARY = 'CATEGORIES_RECEIVE_CATEGORIES_SUMMARY'
export const CATEGORIES_RECEIVE_CATEGORIES_SUMMARY_ERROR = 'CATEGORIES_RECEIVE_CATEGORIES_SUMMARY_ERROR'
export const CATEGORIES_SET_PAGE = 'CATEGORIES_SET_PAGE'
export const CATEGORIES_SET_PAGESIZE = 'CATEGORIES_SET_PAGESIZE'
export const CATEGORIES_SET_SORT = 'CATEGORIES_ET_SORT'
export const CATEGORIES_SET_SORTDIR = 'CATEGORIES_SET_SORTDIR'
export const CATEGORIES_FETCHING_EDIT_CATEGORIES_URLS = 'CATEGORIES_FETCHING_EDIT_CATEGORIES_URLS'
export const CATEGORIES_EDIT_CATEGORIES_URLS_SUCCESS = 'CATEGORIES_EDIT_CATEGORIES_URLS_SUCCESS'
export const CATEGORIES_EDIT_CATEGORIES_URLS_ERROR = 'CATEGORIES_EDIT_CATEGORIES_URLS_ERROR'
export const CATEGORIES_CLEAR_ERRORS = 'CATEGORIES_CLEAR_ERRORS'
export const CATEGORIES_DELETING_CATEGORIES = 'CATEGORIES_DELETING_CATEGORIES'
export const CATEGORIES_DELETE_CATEGORIES_ERROR = 'CATEGORIES_DELETE_CATEGORIES_ERROR'
export const CATEGORIES_FETCHING_EDIT_CATEGORIES_PRODUCTS = 'CATEGORIES_FETCHING_EDIT_CATEGORIES_PRODUCTS'
export const CATEGORIES_EDIT_CATEGORIES_PRODUCTS_SUCCESS = 'CATEGORIES_EDIT_CATEGORIES_PRODUCTS_SUCCESS'
export const CATEGORIES_EDIT_CATEGORIES_PRODUCTS_ERROR = 'CATEGORIES_EDIT_CATEGORIES_PRODUCTS_ERROR'

export const TAGS_CREATE_TAG_SUCCESS = 'TAGS_CREATE_TAG_SUCCESS'
export const TAGS_CREATE_TAG_FETCHING = 'TAGS_CREATE_TAG_FETCHING'
export const TAGS_CREATE_TAG_ERROR = 'TAGS_CREATE_TAG_ERROR'
export const TAGS_FETCHING_TAGS = 'TAGS_FETCHING_TAGS'
export const TAGS_RECEIVE_TAGS = 'TAGS_RECEIVE_TAGS'
export const TAGS_RECEIVE_TAGS_ERROR = 'TAGS_RECEIVE_TAGS_ERROR'
export const TAGS_FETCHING_TAGS_SUMMARY = 'TAGS_FETCHING_TAGS_SUMMARY'
export const TAGS_RECEIVE_TAGS_SUMMARY = 'TAGS_RECEIVE_TAGS_SUMMARY'
export const TAGS_RECEIVE_TAGS_SUMMARY_ERROR = 'TAGS_RECEIVE_TAGS_SUMMARY_ERROR'
export const TAGS_SET_PAGE = 'TAGS_SET_PAGE'
export const TAGS_SET_PAGESIZE = 'TAGS_SET_PAGESIZE'
export const TAGS_SET_SORT = 'TAGS_SET_SORT'
export const TAGS_SET_SORTDIR = 'TAGS_SET_SORTDIR'
export const TAGS_FETCHING_EDIT_TAGS_URLS = 'TAGS_FETCHING_EDIT_TAGS_URLS'
export const TAGS_EDIT_TAGS_URLS_SUCCESS = 'TAGS_EDIT_TAGS_URLS_SUCCESS'
export const TAGS_EDIT_TAGS_URLS_ERROR = 'TAGS_EDIT_TAGS_URLS_ERROR'
export const TAGS_CLEAR_ERRORS = 'TAGS_CLEAR_ERRORS'
export const TAGS_DELETING_TAGS = 'TAGS_DELETING_TAGS'
export const TAGS_DELETE_TAGS_ERROR = 'TAGS_DELETE_TAGS_ERROR'
export const TAGS_FETCHING_EDIT_TAGS_PRODUCTS = 'TAGS_FETCHING_EDIT_TAGS_PRODUCTS'
export const TAGS_EDIT_TAGS_PRODUCTS_SUCCESS = 'TAGS_EDIT_TAGS_PRODUCTS_SUCCESS'
export const TAGS_EDIT_TAGS_PRODUCTS_ERROR = 'TAGS_EDIT_TAGS_PRODUCTS_ERROR'

export const ALERTS_FETCHING_RECENT_ALERTS = 'ALERTS_FETCHING_RECENT_ALERTS'
export const ALERTS_RECEIVE_RECENT_ALERTS = 'ALERTS_RECEIVE_RECENT_ALERTS'
export const ALERTS_RECEIVE_RECENT_ALERTS_ERROR = 'ALERTS_RECEIVE_RECENT_ALERTS_ERROR'
export const ALERTS_FETCHING_ALERTS = 'ALERTS_FETCHING_ALERTS'
export const ALERTS_RECEIVE_ALERTS = 'ALERTS_RECEIVE_ALERTS'
export const ALERTS_RECEIVE_ALERTS_ERROR = 'ALERTS_RECEIVE_ALERTS_ERROR'
export const ALERTS_SET_PAGE = 'ALERTS_SET_PAGE'
export const ALERTS_MARK_SEEN_FETCHING = 'ALERTS_MARK_SEEN_FETCHING'
export const ALERTS_MARK_SEEN_SUCCESS = 'ALERTS_MARK_SEEN_SUCCESS'
export const ALERTS_MARK_SEEN_ERROR = 'ALERTS_MARK_SEEN_ERROR'

/* Dashboard store actions */
export const DASHBOARDS_FETCHING_DASHBOARDS = 'DASHBOARDS_FETCHING_DASHBOARDS'
export const DASHBOARDS_RECEIVE_DASHBOARDS = 'DASHBOARDS_RECEIVE_DASHBOARDS'
export const DASHBOARDS_RECEIVE_DASHBOARDS_ERROR = 'DASHBOARDS_RECEIVE_DASHBOARDS_ERROR'

export const DASHBOARDS_SET_PAGE = 'DASHBOARD_SET_PAGE'
export const DASHBOARDS_SET_PAGESIZE = 'DASHBOARD_SET_PAGESIZE'
export const DASHBOARDS_SET_SORT = 'DASHBOARD_SET_SORT'
export const DASHBOARDS_SET_SORTDIR = 'DASHBOARD_SET_SORTDIR'
export const DASHBOARDS_CREATE_DASHBOARD_FETCHING = 'DASHBOARDS_CREATE_DASHBOARD_FETCHING'
export const DASHBOARDS_CREATE_DASHBOARD_SUCCESS = 'DASHBOARDS_CREATE_DASHBOARD_SUCCESS'
export const DASHBOARDS_CREATE_DASHBOARD_ERROR = 'DASHBOARDS_CREATE_DASHBOARD_ERROR'
export const DASHBOARDS_SAVING_DASHBOARD = 'DASHBOARDS_SAVING_DASHBOARD'
export const DASHBOARDS_RECEIVE_SAVE = 'DASHBOARDS_RECEIVE_SAVE'
export const DASHBOARDS_RECEIVE_SAVE_ERROR = 'DASHBOARDS_RECEIVE_SAVE_ERROR'
export const DASHBOARDS_SAVING_DASHBOARD_AS = 'DASHBOARDS_SAVING_DASHBOARD_AS'
export const DASHBOARDS_RECEIVE_SAVE_AS = 'DASHBOARDS_RECEIVE_SAVE_AS'
export const DASHBOARDS_RECEIVE_SAVE_AS_ERROR = 'DASHBOARDS_RECEIVE_SAVE_AS_ERROR'
export const DASHBOARDS_RESET_SAVE_AS = 'DASHBOARDS_RESET_SAVE_AS'
export const DASHBOARDS_DELETING_DASHBOARDS = 'DASHBOARDS_DELETING_DASHBOARDS'
export const DASHBOARDS_DELETE_DASHBOARDS_ERROR = 'DASHBOARDS_DELETE_DASHBOARDS_ERROR'
export const DASHBOARDS_CLEAR_ERRORS = 'DASHBOARDS_CLEAR_ERRORS'

export const DASHBOARDSDATA_FETCHING_DATA = 'DASHBOARDSDATA_FETCHING_DATA'
export const DASHBOARDSDATA_RECEIVE_DATA = 'DASHBOARDSDATA_RECEIVE_DATA'
export const DASHBOARDSDATA_RECEIVE_DATA_ERROR = 'DASHBOARDSDATA_RECEIVE_DATA_ERROR'
export const DASHBOARDSDATA_FETCHING_REVIEWS = 'DASHBOARDSDATA_FETCHING_REVIEWS'
export const DASHBOARDSDATA_RECEIVE_REVIEWS = 'DASHBOARDSDATA_RECEIVE_REVIEWS'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_ERROR = 'DASHBOARDSDATA_RECEIVE_REVIEWS_ERROR'
export const DASHBOARDSDATA_FETCHING_REVIEWS_HISTORY = 'DASHBOARDSDATA_FETCHING_REVIEWS_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY = 'DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY_ERROR = 'DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY_ERROR'
export const DASHBOARDSDATA_FETCHING_TRENDS = 'DASHBOARDSDATA_FETCHING_TRENDS'
export const DASHBOARDSDATA_RECEIVE_TRENDS = 'DASHBOARDSDATA_RECEIVE_TRENDS'
export const DASHBOARDSDATA_RECEIVE_TRENDS_ERROR = 'DASHBOARDSDATA_RECEIVE_TRENDS_ERROR'
export const DASHBOARDSDATA_FETCHING_REVIEWS_NET_RATING_HISTORY = 'DASHBOARDSDATA_FETCHING_REVIEWS_NET_RATING_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY = 'DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY_ERROR = 'DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY_ERROR'
export const DASHBOARDSDATA_FETCHING_REVIEWS_SENTIMENT_HISTORY = 'DASHBOARDSDATA_FETCHING_REVIEWS_SENTIMENT_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY = 'DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR = 'DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR'
export const DASHBOARDSDATA_FETCHING_REVIEWS_RATING_HISTORY = 'DASHBOARDSDATA_FETCHING_REVIEWS_RATING_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY = 'DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY'
export const DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY_ERROR = 'DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY_ERROR'
export const DASHBOARDSDATA_FETCHING_URLS_HISTORY = 'DASHBOARDSDATA_FETCHING_URLS_HISTORY'
export const DASHBOARDSDATA_RECEIVE_URLS_HISTORY = 'DASHBOARDSDATA_RECEIVE_URLS_HISTORY'
export const DASHBOARDSDATA_RECEIVE_URLS_HISTORY_ERROR = 'DASHBOARDSDATA_RECEIVE_URLS_HISTORY_ERROR'
export const DASHBOARDSDATA_FETCHING_DASHBOARD = 'DASHBOARDSDATA_FETCHING_DASHBOARD'
export const DASHBOARDSDATA_RECEIVE_DASHBOARD = 'DASHBOARDSDATA_RECEIVE_DASHBOARD'
export const DASHBOARDSDATA_RECEIVE_DASHBOARD_ERROR = 'DASHBOARDSDATA_RECEIVE_DASHBOARD_ERROR'
export const DASHBOARDSDATA_FETCHING_TRENDING_DATA = 'DASHBOARDSDATA_FETCHING_TRENDING_DATA'
export const DASHBOARDSDATA_RECEIVE_TRENDING_DATA = 'DASHBOARDSDATA_RECEIVE_TRENDING_DATA'
export const DASHBOARDSDATA_RECEIVE_TRENDING_DATA_ERROR = 'DASHBOARDSDATA_RECEIVE_TRENDING_DATA_ERROR'
export const DASHBOARDSDATA_FETCHING_ENTITIES = 'DASHBOARDSDATA_FETCHING_ENTITIES'
export const DASHBOARDSDATA_RECEIVE_ENTITIES = 'DASHBOARDSDATA_RECEIVE_ENTITIES'
export const DASHBOARDSDATA_RECEIVE_ENTITIES_ERROR = 'DASHBOARDSDATA_RECEIVE_ENTITIES_ERROR'
export const DASHBOARDSDATA_FETCHING_ENTITIES_WITH_GROUP_DATA = 'DASHBOARDSDATA_FETCHING_ENTITIES_WITH_GROUP_DATA'
export const DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA = 'DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA'
export const DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA_ERROR = 'DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA_ERROR'
export const DASHBOARDSDATA_FETCHING_PRODUCTS_TABLE = 'DASHBOARDSDATA_FETCHING_PRODUCTS_TABLE'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE_ERROR = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE_ERROR'

export const DASHBOARDSDATA_FETCHING_PRODUCTS_HISTORY = 'DASHBOARDSDATA_FETCHING_PRODUCTS_HISTORY'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY_ERROR = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY_ERROR'

export const DASHBOARDSDATA_FETCHING_GROUPED_ENTITIES = 'DASHBOARDSDATA_FETCHING_GROUPED_ENTITIES'
export const DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES = 'DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES'
export const DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES_ERROR = 'DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES_ERROR'
export const DASHBORDSDATA_FETCHING_PRODUCTS = 'DASHBORDSDATA_FETCHING_PRODUCTS'
export const DASHBORDSDATA_FETCHING_PRODUCTS_SUCCESS = 'DASHBORDSDATA_FETCHING_PRODUCTS_SUCCESS'
export const DASHBORDSDATA_FETCHING_PRODUCTS_ERROR = 'DASHBORDSDATA_FETCHING_PRODUCTS_ERROR'

export const DASHBOARDSDATA_FETCHING_PRODUCTS_SENTIMENT = 'DASHBOARDSDATA_FETCHING_PRODUCTS_SENTIMENT'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT_ERROR = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT_ERROR'
export const DASHBOARDSDATA_FETCHING_PRODUCTS_ENTITIES = 'DASHBOARDSDATA_FETCHING_PRODUCTS_ENTITIES'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES'
export const DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES_ERROR = 'DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES_ERROR'
export const DASHBOARDSDATA_SET_DASHBOARD_COMPONENT_SETTINGS = 'DASHBOARDSDATA_SET_DASHBOARD_COMPONENT_SETTINGS'
export const DASHBOARDSDATA_RESET = 'DASHBOARDSDATA_RESET'

export const DASHBOARDS_SET_PRODUCTS_AS_SPD = 'DASHBOARDS_SET_PRODUCTS_AS_SPD'

export const SEARCH_FETCHING_CREATE_SEARCH = 'SEARCH_FETCHING_CREATE_SEARCH'
export const SEARCH_RECEIVE_CREATE_SEARCH = 'SEARCH_RECEIVE_CREATE_SEARCH'
export const SEARCH_RECEIVE_CREATE_SEARCH_ERROR = 'SEARCH_RECEIVE_CREATE_SEARCH_ERROR'
export const SEARCH_FETCHING_SEARCH = 'SEARCH_FETCHING_SEARCH'
export const SEARCH_RECEIVE_SEARCH = 'SEARCH_RECEIVE_SEARCH'
export const SEARCH_RECEIVE_SEARCH_ERROR = 'SEARCH_RECEIVE_SEARCH_ERROR'
export const SEARCH_FETCHING_UPDATE_SEARCH = 'SEARCH_FETCHING_UPDATE_SEARCH'
export const SEARCH_RECEIVE_UPDATED_SEARCH = 'SEARCH_RECEIVE_UPDATED_SEARCH'
export const SEARCH_RECEIVE_UPDATE_SEARCH_ERROR = 'SEARCH_RECEIVE_UPDATE_SEARCH_ERROR'
export const SEARCH_FETCHING_ADD_SEARCH_URLS = 'SEARCH_FETCHING_ADD_SEARCH_URLS'
export const SEARCH_RECEIVE_ADD_SEARCH_URLS = 'SEARCH_RECEIVE_ADD_SEARCH_URLS'
export const SEARCH_RECEIVE_ADD_SEARCH_URLS_ERROR = 'SEARCH_RECEIVE_ADD_SEARCH_URLS_ERROR'
export const SEARCH_FETCHING_SEARCH_NEW_RESULTS = 'SEARCH_FETCHING_SEARCH_NEW_RESULTS'
export const SEARCH_RECEIVE_SEARCH_NEW_RESULTS = 'SEARCH_RECEIVE_SEARCH_NEW_RESULTS'
export const SEARCH_RECEIVE_SEARCH_NEW_RESULTS_ERROR = 'SEARCH_RECEIVE_SEARCH_NEW_RESULTS_ERROR'
export const SEARCH_FETCHING_SEARCH_ADDED_RESULTS = 'SEARCH_FETCHING_SEARCH_ADDED_RESULTS'
export const SEARCH_RECEIVE_SEARCH_ADDED_RESULTS = 'SEARCH_RECEIVE_SEARCH_ADDED_RESULTS'
export const SEARCH_RECEIVE_SEARCH_ADDED_RESULTS_ERROR = 'SEARCH_RECEIVE_SEARCH_ADDED_RESULTS_ERROR'
export const SEARCH_CLEAR_ERRORS = 'SEARCH_CLEAR_ERRORS'

export const FILTERS_SET_PRODUCT_IDS = 'SET_PRODUCT_IDS'
export const FILTERS_SET_DOMAIN_IDS = 'SET_DOMAIN_IDS'
export const FILTERS_SET_TAG_IDS = 'SET_TAG_IDS'
export const FILTERS_SET_BRAND_IDS = 'SET_BRAND_IDS'
export const FILTERS_SET_CATEGORY_IDS = 'SET_CATEGORY_IDS'
export const FILTERS_SET_MIN_REVIEWS = 'SET_MIN_REVIEWS'
export const FILTERS_SET_MAX_REVIEWS = 'SET_MAX_REVIEWS'
export const FILTERS_SET_MIN_RATING = 'SET_MIN_RATING'
export const FILTERS_SET_MAX_RATING = 'SET_MAX_RATING'
export const FILTERS_SET_MIN_CREATION_DATE = 'SET_MIN_CREATION_DATE'
export const FILTERS_SET_MAX_CREATION_DATE = 'SET_MAX_CREATION_DATE'
export const FILTERS_SET_MIN_LAST_REVIEWED_DATE = 'SET_MIN_LAST_REVIEWED_DATE'
export const FILTERS_SET_MAX_LAST_REVIEWED_DATE = 'SET_MAX_LAST_REVIEWED_DATE'
export const FILTERS_SET_HOSTNAMES = 'FILTERS_SET_HOSTNAMES'
export const FILTERS_SET_SEARCH = 'FILTERS_SET_SEARCH'
export const FILTERS_RESET = 'RESET'

export const DASHBOARDSFILTERS_SET_SENTIMENT = 'DASHBOARDSFILTERS_SET_SENTIMENT'
export const DASHBOARDSFILTERS_SET_ENTITY_SENTIMENT = 'DASHBOARDSFILTERS_SET_ENTITY_SENTIMENT'
export const DASHBOARDSFILTERS_SET_ENTITY = 'DASHBOARDSFILTERS_SET_ENTITY'
export const DASHBOARDSFILTERS_SET_URL_IDS = 'DASHBOARDSFILTERS_SET_URL_IDS'
export const DASHBOARDSFILTERS_SET_RATINGS = 'DASHBOARDSFILTERS_SET_RATINGS'
export const DASHBOARDSFILTERS_SET_ENTITY_MAP_ID = 'DASHBOARDSFILTERS_SET_ENTITY_MAP_ID'
export const DASHBOARDSFILTERS_SET_ENTITY_GROUP_ID = 'DASHBOARDSFILTERS_SET_ENTITY_GROUP_ID'
export const DASHBOARDSFILTERS_SET_COUNTRIES = 'DASHBOARDSFILTERS_SET_COUNTRIES'
export const DASHBOARDSFILTERS_SET_HOSTNAMES = 'DASHBOARDSFILTERS_SET_HOSTNAMES'
export const DASHBOARDSFILTERS_SET_SEARCH = 'DASHBOARDSFILTERS_SET_SEARCH'
export const DASHBOARDSFILTERS_SET_DATE_RANGE = 'DASHBOARDSFILTERS_SET_DATE_RANGE'
export const DASHBOARDSFILTERS_SET_COMPARISON_TRENDING_DATE_RANGE = 'DASHBOARDSFILTERS_SET_COMPARISON_TRENDING_DATE_RANGE'

export const DASHBOARDSFILTERS_SET_SUB_GROUP = 'DASHBOARDSFILTERS_SET_SUB_GROUP'
export const DASHBOARDSFILTERS_RESET = 'DASHBOARDSFILTERS_RESET'

export const ENTITYMAPS_FETCHING_ENTITY_MAPS = 'ENTITYMAPS_FETCHING_ENTITY_MAPS'
export const ENTITYMAPS_RECEIVE_ENTITY_MAPS = 'ENTITYMAPS_RECEIVE_ENTITY_MAPS'
export const ENTITYMAPS_RECEIVE_ENTITY_MAPS_ERROR = 'ENTITYMAPS_RECEIVE_ENTITY_MAPS_ERROR'
export const ENTITYMAPS_FETCHING_ENTITY_MAP = 'ENTITYMAPS_FETCHING_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_ENTITY_MAP = 'ENTITYMAPS_RECEIVE_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_ENTITY_MAP_ERROR = 'ENTITYMAPS_RECEIVE_ENTITY_MAP_ERROR'
export const ENTITYMAPS_FETCHING_EDIT_IGNORE = 'ENTITYMAPS_FETCHING_EDIT_IGNORE'
export const ENTITYMAPS_RECEIVE_EDIT_IGNORE = 'ENTITYMAPS_RECEIVE_EDIT_IGNORE'
export const ENTITYMAPS_RECEIVE_EDIT_IGNORE_ERROR = 'ENTITYMAPS_RECEIVE_EDIT_IGNORE_ERROR'
export const ENTITYMAPS_FETCHING_CREATE_GROUP = 'ENTITYMAPS_FETCHING_CREATE_GROUP'
export const ENTITYMAPS_RECEIVE_CREATE_GROUP = 'ENTITYMAPS_RECEIVE_CREATE_GROUP'
export const ENTITYMAPS_RECEIVE_CREATE_GROUP_ERROR = 'ENTITYMAPS_RECEIVE_CREATE_GROUP_ERROR'
export const ENTITYMAPS_FETCHING_CREATE_ENTITY_MAP = 'ENTITYMAPS_FETCHING_CREATE_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP = 'ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP_ERROR = 'ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP_ERROR'
export const ENTITYMAPS_FETCHING_EDIT_GROUP = 'ENTITYMAPS_FETCHING_EDIT_GROUP'
export const ENTITYMAPS_RECEIVE_EDIT_GROUP = 'ENTITYMAPS_RECEIVE_EDIT_GROUP'
export const ENTITYMAPS_RECEIVE_EDIT_GROUP_ERROR = 'ENTITYMAPS_RECEIVE_EDIT_GROUP_ERROR'
export const ENTITYMAPS_FETCHING_EDIT_ENTITY_MAP = 'ENTITYMAPS_FETCHING_EDIT_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP = 'ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP_ERROR = 'ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP_ERROR'
export const ENTITYMAPS_FETCHING_DELETE_ENTITY_MAP = 'ENTITYMAPS_FETCHING_DELETE_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP = 'ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP'
export const ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP_ERROR = 'ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP_ERROR'
export const ENTITYMAPS_SET_PAGE = 'ENTITYMAPS_SET_PAGE'
export const ENTITYMAPS_SET_PAGESIZE = 'ENTITYMAPS_SET_PAGESIZE'
export const ENTITYMAPS_SET_SORT = 'ENTITYMAPS_SET_SORT'
export const ENTITYMAPS_SET_SORTDIR = 'ENTITYMAPS_SET_SORTDIR'
export const ENTITYMAPS_RESET_STATE = 'ENTITYMAPS_RESET_STATE'
export const ENTITYMAPS_CLEAR_SELECTED_ENTITYMAP = 'ENTITYMAPS_CLEAR_SELECTED_ENTITYMAP'
export const ENTITYMAPS_CLEAR_ERRORS = 'ENTITYMAPS_CLEAR_ERRORS'

export const ENTITYPROGRESS_FETCHING_REVIEWS_SENTIMENT_HISTORY = 'ENTITYPROGRESS_FETCHING_REVIEWS_SENTIMENT_HISTORY'
export const ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY = 'ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY'
export const ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR = 'ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR'
export const ENTITYPROGRESS_FETCHING_REVIEWS_RATING_HISTORY = 'ENTITYPROGRESS_FETCHING_REVIEWS_RATING_HISTORY'
export const ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY = 'ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY'
export const ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY_ERROR = 'ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY_ERROR'
export const ENTITYPROGRESS_RESET_STATE = 'ENTITYPROGRESS_RESET_STATE'

export const BCR_FETCHING_PROJECTS = 'BCR_FETCHING_PROJECTS'
export const BCR_RECEIVE_PROJECTS = 'BCR_RECEIVE_PROJECTS'
export const BCR_RECEIVE_PROJECTS_ERROR = 'BCR_RECEIVE_PROJECTS_ERROR'

export const ADMIN_FETCHING_CLIENTS = 'CLIENTS_FETCHING_CLIENTS'
export const ADMIN_FETCHING_CLIENTS_ERROR = 'CLIENTS_FETCHING_CLIENTS_ERROR'
export const ADMIN_FETCHING_CLIENTS_SUCCESS = 'CLIENTS_FETCHING_CLIENTS_SUCCESS'

export const ADMIN_FETCHING_APP_FEATURES = 'ADMIN_FETCHING_CLIENTS'
export const ADMIN_FETCHING_APP_FEATURES_ERROR = 'ADMIN_FETCHING_CLIENTS_ERROR'
export const ADMIN_FETCHING_APP_FEATURES_SUCCESS = 'ADMIN_FETCHING_CLIENTS_SUCCESS'

export const ADMIN_CREATING_CLIENT = 'ADMIN_CREATING_CLIENT'
export const ADMIN_CREATE_CLIENT_SUCCESS = 'ADMIN_CREATE_CLIENT_SUCCESS'
export const ADMIN_CREATE_CLIENT_ERROR = 'ADMIN_CREATE_CLIENT_ERROR'

export const ADMIN_FETCHING_DOMAINS = 'ADMIN_FETCHING_DOMAINS'
export const ADMIN_FETCHING_DOMAINS_ERROR = 'ADMIN_FETCHING_DOMAINS_ERROR'
export const ADMIN_FETCHING_DOMAINS_SUCCESS = 'ADMIN_FETCHING_DOMAINS_SUCCESS'

export const ADMIN_FETCHING_BECOME_CLIENT = 'ADMIN_FETCHING_BECOME_CLIENT'
export const ADMIN_FETCHING_BECOME_CLIENT_ERROR = 'ADMIN_FETCHING_BECOME_CLIENT_ERROR'
export const ADMIN_FETCHING_BECOME_CLIENT_SUCCESS = 'ADMIN_FETCHING_BECOME_CLIENT_SUCCESS'

export const ADMIN_UPDATING_CLIENT = 'ADMIN_UPDATING_CLIENT'
export const ADMIN_UPDATE_CLIENT_SUCCESS = 'ADMIN_UPDATE_CLIENT_SUCCESS'
export const ADMIN_UPDATE_CLIENT_ERROR = 'ADMIN_UPDATE_CLIENT_ERROR'

export const ADMIN_FETCHING_LOGOUT_ALL_USERS_OF_CLIENT = 'ADMIN_FETCHING_LOGOUT_ALL_USERS_OF_CLIENT'
export const ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_SUCCESS = 'ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_SUCCESS'
export const ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_ERROR = 'ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_ERROR'

export const ADMIN_FETCHING_URL_DETAILS = 'ADMIN_FETCHING_URL_DETAILS'
export const ADMIN_FETCHING_URL_DETAILS_SUCCESS = 'ADMIN_FETCHING_URL_DETAILS_SUCCESS'
export const ADMIN_FETCHING_URL_DETAILS_ERROR = 'ADMIN_FETCHING_URL_DETAILS_ERROR'

export const ADMIN_UPDATING_URL_SCRAPE_STATUS_SUCCESS = 'ADMIN_UPDATING_URL_SCRAPE_STATUS_SUCCESS'
export const ADMIN_UPDATING_URL_SCRAPE_STATUS_ERROR = 'ADMIN_UPDATING_URL_SCRAPE_STATUS_ERROR'

export const ADMIN_CLEAR_ERRORS = 'ADMIN_CLEAR_ERRORS'

export const ADMIN_FETCHING_URLS = 'ADMIN_FETCHING_URLS'
export const ADMIN_FETCHING_URLS_ERROR = 'ADMIN_FETCHING_URLS_ERROR'
export const ADMIN_FETCHING_URLS_SUCCESS = 'ADMIN_FETCHING_URLS_SUCCESS'

export const ADMIN_FETCHING_A_CLIENT_ERROR = 'ADMIN_FETCHING_A_CLIENT_ERROR'

export const APP_INFO_FETCHING_SUPPORTED_DOMAINS = 'APP_INFO_FETCHING_SUPPORTED_DOMAINS'
export const APP_INFO_RECEIVE_SUPPORTED_DOMAINS = 'APP_INFO_RECEIVE_SUPPORTED_DOMAINS'
export const APP_INFO_RECEIVE_SUPPORTED_DOMAINS_ERROR = 'APP_INFO_RECEIVE_SUPPORTED_DOMAINS_ERROR'
export const APP_INFO_CLEAR_SUGGESTED_DOMAINS = 'APP_INFO_CLEAR_SUGGESTED_DOMAINS'
