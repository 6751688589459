import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingProjects: false,
  projects: [],
  fetchingProjectsError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
