import errorKeys from './errorKeys'
import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const urlsModule = {
  totalRecords: 0,
  urls: [],
  fetchingUrls: false,
  fetchingUrlsError: '',
}
const state = {
  fetchingClients: false,
  fetchingAppFeatures: false,
  fetchingDomains: false,
  creatingClient: false,
  updatingClient: false,
  clients: [],
  totalRecords: 0,
  pageDomains: 1,
  pageSizeDomains: 50,
  totalDomains: 0,
  page: 1,
  pageSize: 50,
  appFeatures: [],
  domains: [],
  fetchingBecomeClient: false,
  fetchingLogoutAllUsers: false,
  urlsModule,
  fetchingUrlDetails: false,
  fetchUrlDetailsError: '',
  urlDetails: null,
  ...errorKeys,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
