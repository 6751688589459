import * as types from '../../types'

export default {
  [types.CATEGORIES_CREATE_CATEGORY_FETCHING](state) {
    Object.assign(state, { fetchingCreateCategory: true, fetchCreateCategoryError: '' })
  },
  [types.CATEGORIES_CREATE_CATEGORY_SUCCESS](state) {
    Object.assign(state, { fetchingCreateCategory: false })
  },
  [types.CATEGORIES_CREATE_CATEGORY_ERROR](state, fetchCreateCategoryError) {
    Object.assign(state, { fetchingCreateCategory: false, fetchCreateCategoryError })
  },

  [types.CATEGORIES_FETCHING_CATEGORIES](state) {
    Object.assign(state, { fetchingCategories: true, fetchCategoriesError: '' })
  },
  [types.CATEGORIES_RECEIVE_CATEGORIES](state, { categories, totalRecords }) {
    Object.assign(state, { fetchingCategories: false, categories, totalCategories: totalRecords })
  },
  [types.CATEGORIES_RECEIVE_CATEGORIES_ERROR](state, fetchCategoriesError) {
    Object.assign(state, { fetchingCategories: false, fetchCategoriesError })
  },

  [types.CATEGORIES_FETCHING_CATEGORIES_SUMMARY](state) {
    Object.assign(state, { fetchingCategoriesSummary: true, fetchCategoriesSummaryError: '' })
  },
  [types.CATEGORIES_RECEIVE_CATEGORIES_SUMMARY](state, {
    categories, page, pageSize, isInfinityScroll, totalRecords,
  }) {
    Object.assign(state, {
      fetchingCategoriesSummary: false,
      categoriesSummary: isInfinityScroll
        ? [...state.categoriesSummary, ...categories] : categories,
      summaryPageSize: parseInt(pageSize, 10),
      summaryPage: parseInt(page, 10),
      totalCategories: totalRecords,
    })
  },
  [types.CATEGORIES_RECEIVE_CATEGORIES_SUMMARY_ERROR](state, fetchCategoriesSummaryError) {
    Object.assign(state, { fetchingCategoriesSummary: false, fetchCategoriesSummaryError })
  },

  [types.CATEGORIES_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.CATEGORIES_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.CATEGORIES_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.CATEGORIES_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },

  [types.CATEGORIES_FETCHING_EDIT_CATEGORIES_URLS](state) {
    Object.assign(state, { fetchingEditCategoriesUrls: true, fetchEditCategoriesUrlsError: '' })
  },
  [types.CATEGORIES_EDIT_CATEGORIES_URLS_SUCCESS](state) {
    Object.assign(state, { fetchingEditCategoriesUrls: false })
  },
  [types.CATEGORIES_EDIT_CATEGORIES_URLS_ERROR](state, fetchEditCategoriesUrlsError) {
    Object.assign(state, { fetchingEditCategoriesUrls: false, fetchEditCategoriesUrlsError })
  },

  [types.CATEGORIES_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
  [types.CATEGORIES_DELETING_CATEGORIES](state, deletingCategories) {
    Object.assign(state, { deletingCategories, deleteCategoriesError: '' })
  },
  [types.CATEGORIES_DELETE_CATEGORIES_ERROR](state, deleteCategoriesError) {
    Object.assign(state, { deletingCategories: false, deleteCategoriesError })
  },

  [types.CATEGORIES_FETCHING_EDIT_CATEGORIES_PRODUCTS](state) {
    Object.assign(state, { fetchingEditCategoriesProducts: true, fetchEditCategoriesProductsError: '' })
  },
  [types.CATEGORIES_EDIT_CATEGORIES_PRODUCTS_SUCCESS](state) {
    Object.assign(state, { fetchingEditCategoriesProducts: false })
  },
  [types.CATEGORIES_EDIT_CATEGORIES_PRODUCTS_ERROR](state, fetchEditCategoriesProductsError) {
    Object.assign(state, {
      fetchingEditCategoriesProducts: false,
      fetchEditCategoriesProductsError,
    })
  },
}
