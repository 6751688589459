import * as types from '../../types'

export default {
  [types.ENTITYMAPS_FETCHING_ENTITY_MAPS](state) {
    Object.assign(state, { fetchingEntityMaps: true, fetchEntityMapsError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_ENTITY_MAPS](state, { entityMaps, totalRecords }) {
    Object.assign(state, { fetchingEntityMaps: false, entityMaps, totalEntityMaps: totalRecords })
  },
  [types.ENTITYMAPS_RECEIVE_ENTITY_MAPS_ERROR](state, fetchEntityMapsError) {
    Object.assign(state, { fetchingEntityMaps: false, fetchEntityMapsError })
  },
  [types.ENTITYMAPS_FETCHING_ENTITY_MAP](state) {
    Object.assign(state, { fetchingEntityMap: true, fetchEntityMapError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_ENTITY_MAP](state, { entityMap }) {
    Object.assign(state, { fetchingEntityMap: false, entityMap })
  },
  [types.ENTITYMAPS_RECEIVE_ENTITY_MAP_ERROR](state, fetchEntityMapError) {
    Object.assign(state, { fetchingEntityMap: false, fetchEntityMapError })
  },
  [types.ENTITYMAPS_FETCHING_EDIT_IGNORE](state) {
    Object.assign(state, { fetchingEditIgnore: true, fetchEditIgnoreError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_EDIT_IGNORE](state, { ignore }) {
    const { entityMap } = state
    Object.assign(state, {
      fetchingEditIgnore: false,
      entityMap: { ...entityMap, ignore },
    })
  },
  [types.ENTITYMAPS_RECEIVE_EDIT_IGNORE_ERROR](state, fetchEditIgnoreError) {
    Object.assign(state, { fetchingEditIgnore: false, fetchEditIgnoreError })
  },
  [types.ENTITYMAPS_FETCHING_CREATE_GROUP](state) {
    Object.assign(state, { fetchingCreateGroup: true, fetchCreateGroupError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_CREATE_GROUP](state, { entityGroup }) {
    const { entityMap } = state
    Object.assign(state, {
      fetchingCreateGroup: false,
      entityMap: {
        ...entityMap,
        groups: entityMap.groups.concat(entityGroup),
      },
    })
  },
  [types.ENTITYMAPS_RECEIVE_CREATE_GROUP_ERROR](state, fetchCreateGroupError) {
    Object.assign(state, { fetchingCreateGroup: false, fetchCreateGroupError })
  },
  [types.ENTITYMAPS_FETCHING_EDIT_GROUP](state) {
    Object.assign(state, { fetchingEditGroup: true, fetchEditGroupError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_EDIT_GROUP](state, { entityGroup }) {
    const { entityMap } = state
    const groups = entityMap.groups.map((group) => {
      if (group._id === entityGroup._id) {
        return entityGroup
      }
      return group
    })
    Object.assign(state, {
      fetchingEditGroup: false,
      entityMap: { ...entityMap, groups },
    })
  },
  [types.ENTITYMAPS_RECEIVE_EDIT_GROUP_ERROR](state, fetchEditGroupError) {
    Object.assign(state, { fetchingEditGroup: false, fetchEditGroupError })
  },
  [types.ENTITYMAPS_FETCHING_CREATE_ENTITY_MAP](state) {
    Object.assign(state, { fetchingCreateEntityMap: true, fetchCreateEntityMapError: '', createdEntityMap: null })
  },
  [types.ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP](state, { entityMap }) {
    Object.assign(state, { fetchingCreateEntityMap: false, fetchCreateEntityMapError: '', createdEntityMap: entityMap })
  },
  [types.ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP_ERROR](state, fetchCreateEntityMapError) {
    Object.assign(state, {
      fetchingCreateEntityMap: false,
      createdEntityMap: null,
      fetchCreateEntityMapError,
    })
  },
  [types.ENTITYMAPS_FETCHING_EDIT_ENTITY_MAP](state) {
    Object.assign(state, { fetchingEditEntityMap: true, fetchEditEntityMapError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP](state) {
    Object.assign(state, { fetchingEditEntityMap: false })
  },
  [types.ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP_ERROR](state, fetchEditEntityMapError) {
    Object.assign(state, { fetchingEditEntityMap: false, fetchEditEntityMapError })
  },
  [types.ENTITYMAPS_FETCHING_DELETE_ENTITY_MAP](state) {
    Object.assign(state, { fetchingDeleteEntityMap: true, fetchDeleteEntityMapError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP](state) {
    Object.assign(state, { fetchingDeleteEntityMap: false, fetchDeleteEntityMapError: '' })
  },
  [types.ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP_ERROR](state, fetchDeleteEntityMapError) {
    Object.assign(state, { fetchingDeleteEntityMap: false, fetchDeleteEntityMapError })
  },
  [types.ENTITYMAPS_RESET_STATE](state) {
    const { initialState } = state
    Object.assign(state, { ...initialState, initialState })
  },
  [types.ENTITYMAPS_CLEAR_SELECTED_ENTITYMAP](state) {
    Object.assign(state, { entityMap: null })
  },
  [types.ENTITYMAPS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.ENTITYMAPS_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.ENTITYMAPS_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.ENTITYMAPS_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },
  [types.ENTITYMAPS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, { ...errorKeys })
  },
}
