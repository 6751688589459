import * as types from '../../types'

export default {
  [types.TAGS_CREATE_TAG_FETCHING](state) {
    Object.assign(state, { fetchingCreateTag: true, fetchCreateTagError: '' })
  },
  [types.TAGS_CREATE_TAG_SUCCESS](state) {
    Object.assign(state, { fetchingCreateTag: false })
  },
  [types.TAGS_CREATE_TAG_ERROR](state, fetchCreateTagError) {
    Object.assign(state, { fetchingCreateTag: false, fetchCreateTagError })
  },

  [types.TAGS_FETCHING_TAGS](state) {
    Object.assign(state, { fetchingTags: true, fetchTagsError: '' })
  },
  [types.TAGS_RECEIVE_TAGS](state, { tags, totalRecords }) {
    Object.assign(state, { fetchingTags: false, tags, totalTags: totalRecords })
  },
  [types.TAGS_RECEIVE_TAGS_ERROR](state, fetchTagsError) {
    Object.assign(state, { fetchingTags: false, fetchTagsError })
  },

  [types.TAGS_FETCHING_TAGS_SUMMARY](state) {
    Object.assign(state, { fetchingTagsSummary: true, fetchTagsSummaryError: '' })
  },
  [types.TAGS_RECEIVE_TAGS_SUMMARY](state, {
    tags, pageSize, page, isInfinityScroll, totalRecords,
  }) {
    Object.assign(state, {
      fetchingTagsSummary: false,
      tagsSummary: isInfinityScroll ? [...state.tagsSummary, ...tags] : tags,
      summaryPageSize: parseInt(pageSize, 10),
      summaryPage: parseInt(page, 10),
      totalTags: totalRecords,
    })
  },
  [types.TAGS_RECEIVE_TAGS_SUMMARY_ERROR](state, fetchTagsSummaryError) {
    Object.assign(state, { fetchingTagsSummary: false, fetchTagsSummaryError })
  },

  [types.TAGS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.TAGS_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.TAGS_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.TAGS_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },

  [types.TAGS_FETCHING_EDIT_TAGS_URLS](state) {
    Object.assign(state, { fetchingEditTagsUrls: true, fetchEditTagsUrlsError: '' })
  },
  [types.TAGS_EDIT_TAGS_URLS_SUCCESS](state) {
    Object.assign(state, { fetchingEditTagsUrls: false })
  },
  [types.TAGS_EDIT_TAGS_URLS_ERROR](state, fetchEditTagsUrlsError) {
    Object.assign(state, { fetchingEditTagsUrls: false, fetchEditTagsUrlsError })
  },

  [types.TAGS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
  [types.TAGS_DELETING_TAGS](state, deletingTags) {
    Object.assign(state, { deletingTags, deleteTagsError: '' })
  },
  [types.TAGS_DELETE_TAGS_ERROR](state, deleteTagsError) {
    Object.assign(state, { deletingTags: false, deleteTagsError })
  },

  [types.TAGS_FETCHING_EDIT_TAGS_PRODUCTS](state) {
    Object.assign(state, { fetchingEditTagsProducts: true, fetchEditTagsUrlsError: '' })
  },
  [types.TAGS_EDIT_TAGS_PRODUCTS_SUCCESS](state) {
    Object.assign(state, { fetchingEditTagsProducts: false })
  },
  [types.TAGS_EDIT_TAGS_PRODUCTS_ERROR](state, fetchEditTagsProductsError) {
    Object.assign(state, { fetchingEditTagsProducts: false, fetchEditTagsProductsError })
  },
}
