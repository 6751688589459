import * as types from '../../types'
import * as searchApi from '../../../api/search'

export const createSearch = async ({ commit }, search) => {
  commit(types.SEARCH_FETCHING_CREATE_SEARCH)

  try {
    const data = await searchApi.create(search)
    return commit(types.SEARCH_RECEIVE_CREATE_SEARCH, data)
  } catch (e) {
    return commit(types.SEARCH_RECEIVE_CREATE_SEARCH_ERROR, e.message)
  }
}

export const fetchSearch = async ({ commit }, { searchId }) => {
  commit(types.SEARCH_FETCHING_SEARCH)

  try {
    const data = await searchApi.getOne({ searchId })
    return commit(types.SEARCH_RECEIVE_SEARCH, data)
  } catch (e) {
    return commit(types.SEARCH_RECEIVE_SEARCH_ERROR, e.message)
  }
}

export const updateSearch = async ({ commit }, search) => {
  commit(types.SEARCH_FETCHING_UPDATE_SEARCH)

  try {
    const data = await searchApi.updateOne(search)
    return commit(types.SEARCH_RECEIVE_UPDATED_SEARCH, data)
  } catch (e) {
    return commit(types.SEARCH_RECEIVE_UPDATE_SEARCH_ERROR, e.message)
  }
}

export const addSearchResultsToUrls = async ({ commit }, {
  searchId,
  results,
  filters = {},
  projectId,
}) => {
  commit(types.SEARCH_FETCHING_ADD_SEARCH_URLS)

  try {
    const data = await searchApi.addResultsToUrls({
      searchId,
      results,
      filters,
      projectId,
    })
    return commit(types.SEARCH_RECEIVE_ADD_SEARCH_URLS, data)
  } catch (e) {
    return commit(types.SEARCH_RECEIVE_ADD_SEARCH_URLS_ERROR, e.message)
  }
}

export const fetchSearchNewResults = async ({ commit }, fetchParams = {}) => {
  commit(types.SEARCH_FETCHING_SEARCH_NEW_RESULTS)

  const {
    search,
    searchId,
    page,
    pageSize,
    sort,
    sortDir,
    filters = {},
  } = fetchParams

  const queryParams = {
    search,
    added: false,
    page,
    pageSize,
    sort,
    sortDir,
    ...filters,
  }
  try {
    const {
      results,
      totalRecords,
    } = await searchApi.getResults({ searchId, queryParams })

    const data = { newResults: results, totalNewResults: totalRecords }

    return commit(types.SEARCH_RECEIVE_SEARCH_NEW_RESULTS, data)
  } catch (e) {
    return commit(types.SEARCH_RECEIVE_SEARCH_NEW_RESULTS_ERROR, e.message)
  }
}

export const fetchSearchAddedResults = async ({ commit }, fetchParams = {}) => {
  commit(types.SEARCH_FETCHING_SEARCH_ADDED_RESULTS)

  const {
    search,
    searchId,
    page,
    pageSize,
    sort,
    sortDir,
  } = fetchParams

  const queryParams = {
    page,
    pageSize,
    search,
    sort,
    sortDir,
    added: true,

  }
  try {
    const {
      results,
      totalRecords,
    } = await searchApi.getResults({ searchId, queryParams })

    const data = { addedResults: results, totalAddedResults: totalRecords }

    return commit(types.SEARCH_RECEIVE_SEARCH_ADDED_RESULTS, data)
  } catch (e) {
    return commit(types.SEARCH_RECEIVE_SEARCH_ADDED_RESULTS_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys = {}) => commit(types.SEARCH_CLEAR_ERRORS, errorKeys)
