import { RECENT_DATA_KEY } from '@/utils/constants'
import * as types from '../../types'
import * as urlsApi from '../../../api/urls'
import * as productsApi from '../../../api/products'
import * as brandsApi from '../../../api/brands'
import * as categoriesApi from '../../../api/categories'
import * as tagsApi from '../../../api/tags'

// eslint-disable-next-line import/prefer-default-export
export const fetchRecentData = async ({ commit }, { projectId }) => {
  commit(types.RECENT_DATA_FETCHING)

  const params = {
    sort: RECENT_DATA_KEY, sortDir: 'desc', pageSize: 4, projectId,
  }

  try {
    const [
      { urls }, { products }, { brands }, { categories }, { tags },
    ] = await Promise.all([
      urlsApi.getAll(params),
      productsApi.getAll(params),
      brandsApi.getAll(params),
      categoriesApi.getAll(params),
      tagsApi.getAll(params),
    ])
    return commit(types.RECENT_DATA_RECEIVE, {
      urls, products, brands, categories, tags,
    })
  } catch (e) {
    return commit(types.RECENT_DATA_ERROR, e.message)
  }
}
