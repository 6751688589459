import * as types from '../../types'
import * as adminApi from '../../../api/admin'

export const fetchClients = async ({ commit }, fetchParams = {}) => {
  commit(types.ADMIN_FETCHING_CLIENTS)

  const paginationAndSortParams = {
    page: 1,
    pageSize: 50,
    sort: 'created',
    sortDir: 'desc',
  }

  try {
    const data = await adminApi.getAllClients({
      ...paginationAndSortParams,
      ...fetchParams,
    })
    return commit(types.ADMIN_FETCHING_CLIENTS_SUCCESS, data)
  } catch (e) {
    return commit(types.ADMIN_FETCHING_CLIENTS_ERROR, e.message)
  }
}

export const fetchAppFeatures = async ({ commit }) => {
  commit(types.ADMIN_FETCHING_APP_FEATURES)
  try {
    const data = await adminApi.getAppFeatures()
    return commit(types.ADMIN_FETCHING_APP_FEATURES_SUCCESS, data)
  } catch (e) {
    return commit(types.ADMIN_FETCHING_APP_FEATURES_ERROR, e.message)
  }
}

export const createClient = async ({ commit }, params) => {
  commit(types.ADMIN_CREATING_CLIENT)
  try {
    const data = await adminApi.createClient(params)
    return commit(types.ADMIN_CREATE_CLIENT_SUCCESS, data)
  } catch (e) {
    return commit(types.ADMIN_CREATE_CLIENT_ERROR, e.message)
  }
}

export const updateClient = async ({ commit }, params) => {
  commit(types.ADMIN_UPDATING_CLIENT)
  try {
    const data = await adminApi.updateClient(params)
    return commit(types.ADMIN_UPDATE_CLIENT_SUCCESS, data)
  } catch (e) {
    return commit(types.ADMIN_UPDATE_CLIENT_ERROR, e.message)
  }
}

export const fetchDomains = async ({ commit }, params) => {
  commit(types.ADMIN_FETCHING_DOMAINS)
  try {
    const { isInfinityScroll, ...rest } = params
    const data = await adminApi.getDomains(rest)
    return commit(types.ADMIN_FETCHING_DOMAINS_SUCCESS, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.ADMIN_FETCHING_DOMAINS_ERROR, e.message)
  }
}

export const becomeClient = async ({ commit, rootState }, { clientId }) => {
  commit(types.ADMIN_FETCHING_BECOME_CLIENT)
  try {
    const { username } = rootState.users.userData.user

    const payload = {
      username,
      clientId: parseInt(clientId, 10),
    }

    const data = await adminApi.becomeClient(payload)
    return commit(types.ADMIN_FETCHING_BECOME_CLIENT_SUCCESS, data)
  } catch (e) {
    return commit(types.ADMIN_FETCHING_BECOME_CLIENT_ERROR, e.message)
  }
}

export const logoutAllUsers = async ({ commit }, { clientId }) => {
  commit(types.ADMIN_FETCHING_LOGOUT_ALL_USERS_OF_CLIENT)
  try {
    await adminApi.logoutAllUsers({ clientId })

    return commit(types.ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_SUCCESS)
  } catch (e) {
    return commit(types.ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_ERROR, e.message)
  }
}

export const getUrlDetails = async ({ commit }, { urlId }) => {
  commit(types.ADMIN_FETCHING_URL_DETAILS)
  try {
    const urlDetails = await adminApi.getUrlDetails({ urlId })

    return commit(types.ADMIN_FETCHING_URL_DETAILS_SUCCESS, urlDetails)
  } catch (e) {
    return commit(types.ADMIN_FETCHING_URL_DETAILS_ERROR, e.message)
  }
}

export const getScrapeStatus = async ({ commit }, { urlId }) => {
  try {
    const { status } = await adminApi.getScrapeStatus({ urlId })

    return commit(types.ADMIN_UPDATING_URL_SCRAPE_STATUS_SUCCESS, status)
  } catch (e) {
    return commit(types.ADMIN_UPDATING_URL_SCRAPE_STATUS_ERROR, e.message)
  }
}

export const rescrapeUrl = async ({ commit }, { urlId }) => {
  try {
    const { status } = await adminApi.rescrapeUrl({ urlId })

    return commit(types.ADMIN_UPDATING_URL_SCRAPE_STATUS_SUCCESS, status)
  } catch (e) {
    return commit(types.ADMIN_UPDATING_URL_SCRAPE_STATUS_ERROR, e.message)
  }
}

export const fetchClient = async ({ commit }, { clientId }) => {
  try {
    const client = await adminApi.getClient({ clientId })

    return client
  } catch (e) {
    return commit(types.ADMIN_FETCHING_A_CLIENT_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys) => commit(types.ADMIN_CLEAR_ERRORS, errorKeys)

export const fetchUrls = async ({ commit }, fetchParams = {}) => {
  commit(types.ADMIN_FETCHING_URLS)

  const paginationAndSortParams = {
    page: 1,
    pageSize: 50,
    sort: 'created',
    sortDir: 'desc',
  }

  try {
    const data = await adminApi.getAllUrls({
      ...paginationAndSortParams,
      ...fetchParams,
    })
    return commit(types.ADMIN_FETCHING_URLS_SUCCESS, data)
  } catch (e) {
    return commit(types.ADMIN_FETCHING_URLS_ERROR, e.message)
  }
}
