import * as types from '../../types'
import * as entityMapsApi from '../../../api/entityMaps'

export const fetchEntityMaps = async ({ commit, getters }, fetchParams = {}) => {
  commit(types.ENTITYMAPS_FETCHING_ENTITY_MAPS)

  const {
    page, pageSize, sort, sortDir,
  } = getters
  const payload = {
    page, pageSize, sort, sortDir, ...fetchParams,
  }

  try {
    const data = await entityMapsApi.getAll(payload)
    return commit(types.ENTITYMAPS_RECEIVE_ENTITY_MAPS, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_ENTITY_MAPS_ERROR, e.message)
  }
}

export const fetchEntityMap = async ({ commit }, { entityMapId }) => {
  commit(types.ENTITYMAPS_FETCHING_ENTITY_MAP)
  try {
    const data = await entityMapsApi.getOne({ entityMapId })
    return commit(types.ENTITYMAPS_RECEIVE_ENTITY_MAP, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_ENTITY_MAP_ERROR, e.message)
  }
}

export const createEntityMap = async ({ commit }, { name, groups, ignore }) => {
  commit(types.ENTITYMAPS_FETCHING_CREATE_ENTITY_MAP)
  try {
    const data = await entityMapsApi.create({ name, groups, ignore })
    return commit(types.ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_CREATE_ENTITY_MAP_ERROR, e.message)
  }
}

export const editEntityMap = async ({ commit }, {
  entityMapId, name, groups, ignore,
}) => {
  commit(types.ENTITYMAPS_FETCHING_EDIT_ENTITY_MAP)
  try {
    const data = await entityMapsApi.editMap({
      entityMapId, name, groups, ignore,
    })
    return commit(types.ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_EDIT_ENTITY_MAP_ERROR, e.message)
  }
}

export const deleteEntityMap = async ({ commit }, { entityMapId }) => {
  commit(types.ENTITYMAPS_FETCHING_DELETE_ENTITY_MAP)
  try {
    await entityMapsApi.deleteOne({ entityMapId })
    return commit(types.ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP_ERROR, e.message)
  }
}

export const deleteEntityMaps = async ({ commit }, { entityMapIds, filters }) => {
  commit(types.ENTITYMAPS_FETCHING_DELETE_ENTITY_MAP)
  try {
    await entityMapsApi.deleteMany({ entityMapIds, filters })
    return commit(types.ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_DELETE_ENTITY_MAP_ERROR, e.message)
  }
}

export const addIgnore = async ({ commit, getters }, { ignore }) => {
  const { entityMapIsApplied, appliedEntityMapId: entityMapId } = getters
  if (!entityMapIsApplied) {
    console.error('You cannot add ignores without an entity map applied')
    return Promise.resolve()
  }

  commit(types.ENTITYMAPS_FETCHING_EDIT_IGNORE)
  try {
    const data = await entityMapsApi.addIgnore({ entityMapId, ignore })
    return commit(types.ENTITYMAPS_RECEIVE_EDIT_IGNORE, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_EDIT_IGNORE_ERROR, e.message)
  }
}

export const createEntityGroup = async ({ commit, getters }, { name, entities }) => {
  const { entityMapIsApplied, appliedEntityMapId: entityMapId } = getters
  if (!entityMapIsApplied) {
    console.error('You cannot create an entity group without an entity map applied')
    return Promise.resolve()
  }
  commit(types.ENTITYMAPS_FETCHING_CREATE_GROUP)
  try {
    const data = await entityMapsApi.createEntityGroup({ entityMapId, name, entities })
    return commit(types.ENTITYMAPS_RECEIVE_CREATE_GROUP, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_CREATE_GROUP_ERROR, e.message)
  }
}

export const editEntityGroup = async ({ commit, getters }, { entityGroupId, entities }) => {
  const { entityMapIsApplied, appliedEntityMapId: entityMapId } = getters
  if (!entityMapIsApplied) {
    console.error('You cannot edit an entity group without an entity map applied')
    return Promise.resolve()
  }
  commit(types.ENTITYMAPS_FETCHING_EDIT_GROUP)
  try {
    const data = await entityMapsApi.editEntityGroup({ entityMapId, entityGroupId, entities })
    return commit(types.ENTITYMAPS_RECEIVE_EDIT_GROUP, data)
  } catch (e) {
    return commit(types.ENTITYMAPS_RECEIVE_EDIT_GROUP_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.ENTITYMAPS_SET_PAGE, page)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.ENTITYMAPS_SET_PAGESIZE, pageSize)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.ENTITYMAPS_SET_SORT, sort)
  commit(types.ENTITYMAPS_SET_SORTDIR, sortDir)
}

export const resetState = ({ commit }) => commit(types.ENTITYMAPS_RESET_STATE)

// eslint-disable-next-line max-len
export const clearSelectedEntityMap = ({ commit }) => commit(types.ENTITYMAPS_CLEAR_SELECTED_ENTITYMAP)

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys = {}) => commit(types.ENTITYMAPS_CLEAR_ERRORS, errorKeys)
