import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingCreateProduct: false,
  fetchCreateProductError: '',

  fetchingProducts: false,
  fetchProductsError: '',
  products: [],
  totalProducts: 0,
  page: 1,
  pageSize: 50,
  summaryPage: 1,
  summaryPageSize: 50,
  sort: '',
  sortDir: 'asc',

  deletingProducts: false,
  deleteProductsError: '',

  fetchingEditTags: false,
  editTagsError: '',

  productsSummary: [],
  fetchingProductsSummary: false,
  fetchProductsSummaryError: '',

  fetchingBCRLinkStatus: false,
  bcrLinkStatus: null,
  fetchBCRLinkStatusError: '',

  fetchingRetryLinkProductToBCR: false,
  fetchingRetryLinkProductToBCRError: '',

  fetchingLinkProductToBCR: false,
  fetchingLinkProductToBCRError: '',

  fetchingCreateBCRQuery: false,
  fetchingCreateBCRQueryError: '',

  fetchingSuggestedBCRQuery: false,
  suggestedBCRQuery: '',
  fetchingSuggestedBCRQueryError: '',
  fetchingEditProductsUrls: false,
  editProductsUrlsError: '',

  fetchingDownloadReviews: false,
  fetchingDownloadReviewsError: '',
  fetchingProductsUpdate: false,
  fetchingProductsUpdateError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
