import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  entityMap: null,
  fetchingEntityMap: false,
  fetchEntityMapError: '',

  fetchingEntityMaps: false,
  fetchEntityMapsError: '',

  fetchingEditIgnore: false,
  fetchEditIgnoreError: '',

  fetchingCreateGroup: false,
  fetchCreateGroupError: '',

  fetchingEditGroup: false,
  fetchEditGroupError: '',

  createdEntityMap: null,
  fetchingCreateEntityMap: false,
  fetchCreateEntityMapError: '',

  fetchingEditEntityMap: false,
  fetchEditEntityMapError: '',

  fetchingDeleteEntityMap: false,
  fetchDeleteEntityMapError: '',

  entityMaps: [],
  totalEntityMaps: 0,
  page: 1,
  pageSize: 50,
  sort: 'name',
  sortDir: 'asc',
}

const initialState = { ...state }
state.initialState = initialState

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
