import Vue from 'vue'
import Vuex from 'vuex'

import alerts from './modules/alerts'
import brands from './modules/brands'
import categories from './modules/categories'
import projects from './modules/projects'
import products from './modules/products'
import recentdata from './modules/recentdata'
import urls from './modules/urls'
import users from './modules/users'
import tags from './modules/tags'
import dashboards from './modules/dashboards'
import dashboardsData from './modules/dashboardsData'
import dashboardsFilters from './modules/dashboardsFilters'
import search from './modules/search'
import filters from './modules/filters'
import entityMaps from './modules/entityMaps'
import entityProgress from './modules/entityProgress'
import bcr from './modules/bcr'
import admin from './modules/admin'
import appInfo from './modules/appInfo'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alerts,
    brands,
    categories,
    projects,
    products,
    recentdata,
    urls,
    users,
    tags,
    dashboards,
    dashboardsData,
    dashboardsFilters,
    search,
    filters,
    entityMaps,
    entityProgress,
    admin,
    bcr,
    appInfo,
  },
})
