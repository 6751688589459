export const COLORS = {
  BLUE: '#57B7DD',
  PURPLE: '#906BA7',
  RED: '#FF6D56',
  ORANGE: '#F99132',
  YELLOW: '#FFBE0A',
  GREEN: '#8AC539',
  DARK_GREEN: '#4ca15a',
  SOFT_GREEN: '#00A682',
  DARK_RED: '#D74A4A',
  GREY: '#BBB9C0',
}

export const PRODUCT_COLORS = {
  TINY_CLANGER: '#F498B7',
  CRITICAL_MASS: '#E35A7A',
  PARADISE_LOST: '#906BA7',
  SERENITY: '#90A0D6',
  GIANT_LEAP: '#55CDE6',
  MOON_LAGOON: '#24ADBD',
  TERRAFORM: '#8AC539',
  PRIMEVAL_SOUP: '#C8C819',
  NEW_HORIZON: '#FFBE0A',
  BLAS_OFF: '#F99132',
  GROUND_CONTROL: '#9E6139',
  LUNA_DUST: '#78909C',
  FORBIDDEN_PLANET: '#F5B1A4',
  FANTASTIC_VOYAGER: '#A72E5E',
  EVENT_HORIZON: '#6063A1',
  ELECTRIC_DREAMS: '#3B79CC',
  OUTER_LIMITS: '#7FD6F8',
  SPACE_INVADER: '#2F9170',
  EXTRATERRESTRIAL: '#60B454',
  FUTURE_SHOCK: '#F0EC1D',
  SUN_MAKER: '#FCD512',
  CRASH_COURSE: '#FF6D56',
  SOLAR_RUST: '#CD811A',
  SPACE_ODDITY: '#BDA35B',
  ROCKY_PLANET: '#9EBE75',
  DEEP_THOUGHT: '#BBB9C0',
}

export const KEY_TO_COLOR_MAP = {
  one: COLORS.YELLOW,
  two: COLORS.ORANGE,
  three: COLORS.RED,
  four: COLORS.PURPLE,
  five: COLORS.BLUE,
  positive: COLORS.DARK_GREEN,
  negative: COLORS.DARK_RED,
  neutral: COLORS.GREY,
}

const COLOR_NAMES = [
  COLORS.GREY,
  COLORS.BLUE,
  COLORS.PURPLE,
  COLORS.RED,
  COLORS.ORANGE,
  COLORS.YELLOW,
  COLORS.GREEN,
  COLORS.DARK_GREEN,
  COLORS.DARK_RED,
]

export const DASHBOARD_TYPES = {
  PRODUCT: 'product',
  TAG: 'tag',
  DASHBOARD: 'dashboard',
  URL: 'url',
  CATEGORY: 'category',
  BRAND: 'brand',
}

export const ALERT_TYPES = {
  SEARCH_PROGRESS: 'search-progress',
  UPLOAD_COMPLETED: 'upload-completed',
  URL_ERROR: 'url-error',
  NEW_PRODUCT: 'new-product',
  NON_CANONICAL_URL: 'non-canonical-url',
  SEARCH_NEW_FOUNDS: 'search-new-founds',
  SEARCH_ERRORS: 'search-errors',
}

export const RATING_COLORS = [
  '#FCD512',
  '#F99132',
  '#FF6D56',
  '#906BA7',
  '#55CDE6',
]

export const SENTIMENT_BOUNDARY = 0.1

export const SENTIMENT_COLORS = {
  POSITIVE: '#4CA15A',
  NEUTRAL: '#D6D6D6',
  NEGATIVE: '#D74A4A',
}

export const SENTIMENT_RANGES = {
  POSITIVE: [SENTIMENT_BOUNDARY, 1],
  NEUTRAL: [-SENTIMENT_BOUNDARY + 0.001, SENTIMENT_BOUNDARY - 0.001],
  NEGATIVE: [-1, -SENTIMENT_BOUNDARY],
}

export const getColorForNumber = (i) => COLOR_NAMES[i % COLOR_NAMES.length]

export const getProductColorForNumber = (i) => {
  const PRODUCT_COLOR_LIST = Object.values(PRODUCT_COLORS)
  return PRODUCT_COLOR_LIST[i % PRODUCT_COLOR_LIST.length]
}

export const getColorForSentiment = (sentimentScore) => {
  if (sentimentScore > SENTIMENT_BOUNDARY) { return SENTIMENT_COLORS.POSITIVE }
  if (sentimentScore < -SENTIMENT_BOUNDARY) { return SENTIMENT_COLORS.NEGATIVE }
  return SENTIMENT_COLORS.NEUTRAL
}

export const getColorForRating = (rating) => RATING_COLORS[Math.ceil(rating) - 1]

export const ALL_DATA_PROJECT = {
  id: 'alldata',
  name: 'All data',
}

export const STORAGE_KEY_PROJECT_ID = 'projectId'

export const ALLOWED_SCRAPE_REVIEWS_CADENCE = [
  { label: 'Every 24h', value: 1 },
  { label: 'Every 7d', value: 7 },
  { label: 'Every 30d', value: 30 },
]

export const RECENT_DATA_KEY = 'lastOpened'

export const RESOURCE_TYPE_TO_ICON = {
  url: 'mdi-link-variant',
  product: 'mdi-file',
  brand: 'mdi-alpha-b-circle',
  category: 'mdi-view-grid',
  tag: 'mdi-tag',
  dashboard: 'mdi-view-parallel',
}

export const BATCH_OPERATIONS_MAP = {
  ADD: 'add',
  REMOVE: 'remove',
}

export const TYPES_OF_DASHBOARD = {
  SINGLE_PRODUCT: 'single-product',
  SINGLE_CATEGORY: 'single-category',
  SINGLE_TAG: 'single-tag',
  SINGLE_BRAND: 'single-brand',
  MULTI_PRODUCT: 'multi-product',
}

export const SINGLE_PRODUCT_GROUPED_TABS = [
  {
    group: 'Single product',
    color: getColorForNumber(5),
    items: [
      {
        label: 'Summary',
        key: 'single-product--summary',
        content: 'Example content of tab "Summary"',
      },
      {
        label: 'Trending',
        key: 'single-product--trending',
        content: 'Example content of tab "Trending"',
      },
      {
        label: 'Entity',
        key: 'single-product--entity',
        content: 'Example content of tab "Entity"',
      },
      {
        label: 'Location',
        key: 'single-product--location',
        content: 'Example content of tab "Location"',
      },
    ],
  },
]

export const SINGLE_CATEGORY_GROUPED_TABS = [
  {
    group: 'Category',
    color: getColorForNumber(5),
    items: [
      {
        label: 'Summary',
        key: 'single-category--summary',
      },
      {
        label: 'Trending',
        key: 'single-category--trending',
      },
      {
        label: 'Entity',
        key: 'single-category--entity',
      },
      {
        label: 'Location',
        key: 'single-category--location',
      },
    ],
  },
]

export const SINGLE_TAG_GROUPED_TABS = [
  {
    group: 'Tag',
    color: getColorForNumber(5),
    items: [
      {
        label: 'Summary',
        key: 'single-tag--summary',
      },
      {
        label: 'Trending',
        key: 'single-tag--trending',
      },
      {
        label: 'Entity',
        key: 'single-tag--entity',
      },
      {
        label: 'Location',
        key: 'single-tag--location',
      },
    ],
  },
]

export const SINGLE_BRAND_GROUPED_TABS = [
  {
    group: 'Brand',
    color: getColorForNumber(5),
    items: [
      {
        label: 'Summary',
        key: 'single-brand--summary',
      },
      {
        label: 'Trending',
        key: 'single-brand--trending',
      },
      {
        label: 'Entity',
        key: 'single-brand--entity',
      },
      {
        label: 'Location',
        key: 'single-brand--location',
      },
    ],
  },
]

export const MULTI_PRODUCT_GROUPED_TABS = [
  {
    group: 'Multi products',
    color: getColorForNumber(5),
    items: [
      {
        label: 'Summary',
        key: 'single-product--summary',
        content: 'Example content of tab "Summary"',
      },
      {
        label: 'Entity',
        key: 'single-product--entity',
        content: 'Example content of tab "Entity"',
      },
    ],
  },
]

export const COMPARISON_CHART_COLORS = {
  SENTIMENT: Object.values(SENTIMENT_COLORS),
  RATING_DISTRIBUTION: RATING_COLORS,
  VOLUME: ['#30ABE8'],
  AVERAGE_RATING: ['#F99132'],
}

export const COMPARISON_CHART_DATA_TYPES = {
  BREAKDOWN: 'breakdown',
  AGGREGATE: 'aggregate',
}

export const MAX_LINKS_NUMBER_PER_SEARCH = 10

export const DATA_TYPES = {
  PRODUCTS: 'products',
  URLS: 'urls',
  CATEGORIES: 'categories',
  TAGS: 'tags',
  BRANDS: 'brands',
}

// colors for brands, categories and tags
export const GROUPING_COLORS = [
  '#ED8424',
  '#F25D46',
  '#D64B6B',
  '#515594',
  '#CCCCCC',
  '#52A84C',
  '#9C2452',
  '#E89F90',
]
export const TIME_GROUPING_COLORS = [
  '#F25D46',
  '#ED8424',
  '#515594',
  '#CCCCCC',
  '#52A84C',
]

export const PAGE_HEADER_TYPES = {
  TITLE: 'title',
  SUB_TITLE: 'sub-title',
}
export const DATE_FORMAT_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/

export const RPOP_UP_TOP_MARGIN = 20

export const ENTITYMAPS_LIMITS = {
  GROUPS_PER_MAP: 30,
  ENTITIES_PER_GROUP: 200,
  IGNORES_PER_MAP: 1500,
}

export const SCROLL_RESET_POSITION = {
  top: 0,
  left: 0,
}

export const SCRAPE_JOB_STATUSES = {
  ready: 'Ready',
  in_progress: 'In Progress',
  failed: 'Failed',
  complete: 'Complete',
  paused: 'Paused',
  skipped: 'Skipped',
}

export const MULTI_PRODUCTS_DASHBOARD_MAX_PRODUCT_SELECT = 15
export const MAX_DASHBOARD_TAB_GROUPS = 4

export const PAGINATION_ACTION_ITEMS = {
  EXPORT: 'EXPORT',
}
