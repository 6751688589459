import * as types from '../../types'

export default {
  [types.ENTITYPROGRESS_FETCHING_REVIEWS_SENTIMENT_HISTORY](state) {
    Object.assign(state, {
      fetchingEntityProgressSentimentHistory: true,
      fetchEntityProgressSentimentHistoryError: '',
    })
  },
  [types.ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY](state, data) {
    const { sentimentBreakdown, type } = data

    Object.assign(state, {
      fetchingEntityProgressSentimentHistory: false,
      reviewsSentimentHistory: {
        history: sentimentBreakdown,
        type,
      },
    })
  },
  [types.ENTITYPROGRESS_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR](state, fetchEntityProgressSentimentHistoryError) { // eslint-disable-line max-len
    Object.assign(state, {
      fetchingEntityProgressSentimentHistory: false,
      fetchEntityProgressSentimentHistoryError,
    })
  },

  [types.ENTITYPROGRESS_FETCHING_REVIEWS_RATING_HISTORY](state) {
    Object.assign(state, {
      fetchingEntityProgressRatingHistory: true,
      fetchEntityProgressRatingHistoryError: '',
    })
  },
  [types.ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY](state, data) {
    const { ratingsBreakdown } = data
    Object.assign(state, {
      fetchingEntityProgressRatingHistory: false,
      reviewsRatingHistory: {
        history: ratingsBreakdown,
        data: '',
      },
    })
  },
  [types.ENTITYPROGRESS_RECEIVE_REVIEWS_RATING_HISTORY_ERROR](state, fetchEntityProgressRatingHistoryError) { // eslint-disable-line max-len
    Object.assign(state, {
      fetchingEntityProgressRatingHistory: false,
      fetchEntityProgressRatingHistoryError,
    })
  },
  [types.ENTITYPROGRESS_RESET_STATE](state) {
    const { initialState } = state
    Object.assign(state, { ...initialState, initialState })
  },
}
