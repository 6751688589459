import * as serverApi from './server'

export const create = ({ urls, projectId }) => serverApi.post('/v3/urls', { urls, projectId })
export const getAll = (queryParams) => serverApi.get('/v3/urls', queryParams)
export const exportUrls = (queryParams) => serverApi.getPlainContent('/v3/urls/export/csv', queryParams)
export const deleteOne = ({ urlId, projectId }) => {
  if (projectId) {
    return serverApi.del(`/v3/projects/${projectId}/urls/${urlId}`)
  }
  return serverApi.del(`/v3/urls/${urlId}`)
}
export const del = (payload) => serverApi.post('/v3/urls/remove', payload)
export const validateURLs = ({ urls, validateAlreadyExistingUrls }) => serverApi.post('/v3/urls/validate', { urls, validateAlreadyExistingUrls }, { rawErrors: true })
export const validateListingURLs = ({ urls }) => serverApi.post('/v3/urls/validate/search', { urls }, { rawErrors: true })
export const getHostnames = () => serverApi.get('/v3/hostnames')
export const getBCRLinkStatus = ({ urlId }) => serverApi.get(`/v3/urls/${urlId}/bcrlinks/status`)
export const linkUrlToBCR = (payload) => serverApi.post('/v3/urls/bcrlinks', payload)
export const retryLinkUrlToBCR = (payload) => serverApi.post('/v3/urls/bcrlinks/retry', payload)
export const createBCRQuery = (payload) => serverApi.post('/v3/urls/bcrlinks/queries', payload)
export const getBCRQuerySuggestion = (payload) => serverApi.post('/v3/urls/bcrlinks/queries/suggested', payload)
export const download = ({ urlId }) => serverApi.getRaw(`/v3/urls/${urlId}/reviews/export`)
