const any = (...vals) => {
  for (let i = 0; i < vals.length; i += 1) {
    if (vals[i]) {
      return vals[i]
    }
  }
  return null
}

export const entityMaps = (state) => state.entityMaps || []
export const entityMapsItems = (state) => (appliedMapId) => {
  const { entityMaps: maps } = state

  if (!maps?.length) {
    return []
  }

  return maps.map(({ name, _id }) => ({
    label: name,
    value: _id,
    selected: _id === appliedMapId,
  }))
}
export const totalEntityMaps = (state) => state.totalEntityMaps || 0
/* eslint-disable max-len */
export const fetching = (state) => {
  const {
    fetchingEntityMaps, fetchingEntityMap, fetchingEditEntityMap, fetchingDeleteEntityMap, fetchingCreateEntityMap,
  } = state
  return any(fetchingEntityMaps, fetchingCreateEntityMap, fetchingEntityMap, fetchingEditEntityMap, fetchingDeleteEntityMap)
}
export const fetchError = (state) => {
  const {
    fetchEntityMapsError, fetchEntityMapError, fetchEditEntityMapError, fetchDeleteEntityMapError, fetchCreateEntityMapError,
  } = state
  return any(fetchEntityMapsError, fetchCreateEntityMapError, fetchEntityMapError, fetchEditEntityMapError, fetchDeleteEntityMapError) || ''
}
/* eslint-enable max-len */
export const page = (state) => state.page || 0
export const pageSize = (state) => state.pageSize || 0
export const sort = (state) => state.sort || 'name'
export const sortDir = (state) => state.sortDir || 'asc'
export const entityMapIsApplied = (state) => state.entityMap !== null
export const appliedEntityMap = (state) => state.entityMap || null
export const appliedEntityMapId = (state) => state.entityMap?._id
export const fetchingEditMap = (state) => state.fetchingEditIgnore
export const entityMapName = (state) => state.entityMap?.name || ''
export const entityMapIgnores = (state) => state.entityMap?.ignore || []
export const entityMapDashboards = (state) => state.entityMap?.dashboards || []
export const entityMapGroupItems = (state) => (appliedGroupId) => {
  if (!state.entityMap?.groups?.length) {
    return []
  }

  const groups = [...state.entityMap.groups]
  const mapFn = ({ name, _id }) => ({
    label: name,
    value: _id,
    selected: _id === appliedGroupId,
  })

  if (!appliedGroupId) {
    const firstGroup = groups.shift()
    return [{
      label: firstGroup.name,
      value: firstGroup._id,
      selected: true,
    }].concat(groups.map(mapFn))
  }

  return groups.map(mapFn)
}
export const entityMapGroups = (state) => (groupId) => {
  if (!state.entityMap?.groups?.length) {
    return null
  }
  if (groupId) {
    return state.entityMap.groups.find(({ _id }) => _id === groupId)
  }
  return state.entityMap.groups
}
export const createdEntityMap = (state) => state.createdEntityMap
