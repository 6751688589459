import * as serverApi from './server'

export const create = ({ name, links, projectId }) => serverApi.post('/v3/search/', { name, links, projectId })
export const getOne = ({ searchId }) => serverApi.get(`/v3/search/${searchId}`)
export const updateOne = ({
  searchId,
  name,
  projectId,
  automaticallyUpdate,
  automaticallyCreateUrls,
}) => serverApi.patch(`/v3/search/${searchId}`, {
  name,
  projectId,
  automaticallyUpdate,
  automaticallyCreateUrls,
})
export const addResultsToUrls = ({
  searchId,
  results,
  filters,
  projectId,
}) => serverApi.post(`/v3/search/${searchId}/urls`, { results, filters, projectId })
export const getResults = ({ searchId, queryParams }) => serverApi.get(`/v3/search/${searchId}/results`, queryParams)
