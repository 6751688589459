import * as storageApi from '@/api/storage'
import * as serverApi from './server'

// eslint-disable-next-line import/prefer-default-export
export const getAllClients = (queryParams) => serverApi.get('/v3/admin/clients', queryParams)
export const getClient = ({ clientId }) => serverApi.get(`/v3/admin/clients/${clientId}`)
export const getAppFeatures = (queryParams) => serverApi.get('/v3/admin/app-features', queryParams)
export const getDomains = (queryParams) => serverApi.get('/v3/admin/domains', queryParams)
export const createClient = (queryParams) => serverApi.post('/v3/admin/clients', queryParams)
export const logoutAllUsers = ({ clientId }) => serverApi.post(`/v3/admin/clients/${clientId}/logout`)
export const updateClient = ({ clientId, ...rest }) => serverApi.put(`/v3/admin/clients/${clientId}`, rest)
export const becomeClient = ({ username, clientId }) => serverApi.post('/v3/admin/become', { username, clientId })
  .then((body) => {
    storageApi.set('token', body.token)

    return { user: { username } }
  })
export const getAllUrls = (queryParams) => serverApi.get('/v3/admin/urls', queryParams)
export const getUrlDetails = ({ urlId }) => serverApi.get(`/v3/admin/urls/${urlId}`)
export const rescrapeUrl = ({ urlId }) => serverApi.post(`/v3/admin/urls/${urlId}/scrape`)
export const getScrapeStatus = ({ urlId }) => serverApi.get(`/v3/admin/urls/${urlId}/scrapestatus`)
