export default {
  creatingClientError: '',
  updatingClientError: '',
  fetchAppFeaturesError: '',
  fetchClientsError: '',
  fetchDomainsError: '',
  fetchBecomeClientError: '',
  fetchLogoutAllUsersError: '',
  fetchUrlDetailsError: '',
  fetchClientError: '',
}
