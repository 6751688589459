import { v4 as uuid } from 'uuid'
import getPercentage from '@/utils/getPercentage'

const wordCloudDataMapper = ({ entities }) => entities.map((item) => ({
  _id: uuid(),
  label: item.entity,
  volume: item.numReviews,
  netSentiment: item.netSentiment,
  rating: item.netRating,
  isEntityGroup: Object.hasOwn(item, 'entityMapId') && Object.hasOwn(item, 'entityGroupId'),
  entityMapId: item.entityMapId,
  entityGroupId: item.entityGroupId,
  dateInterval: `${item.avgDate}-${item.avgDate}`,
}))

const highlightWordCloudData = ({ wordCloudGrouped, appliedHighlightFilter }) => {
  const groups = Object.keys(wordCloudGrouped)
  if (groups.length === 1) {
    return { ...wordCloudGrouped }
  }

  return groups.reduce((accumulator, currentGroup) => {
    const groupsExceptCurrent = groups.filter((group) => group !== currentGroup)

    const newItems = wordCloudGrouped[currentGroup].map((item) => {
      const { label } = item
      const isUnique = appliedHighlightFilter === 'uniqueWords' && groupsExceptCurrent.every((group) => wordCloudGrouped[group].every(({ label: entityLabel }) => entityLabel !== label))
      const isCommon = appliedHighlightFilter === 'commonWords' && groupsExceptCurrent.some((group) => wordCloudGrouped[group].some(({ label: entityLabel }) => entityLabel === label))

      return { ...item, isUnique: !!isUnique, isCommon: !!isCommon }
    })

    return { ...accumulator, [currentGroup]: newItems }
  }, {})
}

export const name = (state) => state.name || ''
export const pinned = (state) => state.pinned || false
export const urls = (state) => state.urls || []
export const hostnames = (state) => state.hostnames || []
export const starRatingDistribution = (state) => {
  const { ratingsBreakdown } = state
  const keys = Object.keys(ratingsBreakdown)
  const sum = Object.values(ratingsBreakdown).reduce((memo, value) => memo + value, 0)

  return keys
    .map((key) => ({
      label: key,
      value: getPercentage({ value: ratingsBreakdown[key], total: sum }),
    }))
    .sort((a, b) => b.label.localeCompare(a.label))
}
export const sentimentBreakdown = (state) => state.sentimentBreakdown || {}
export const netSentiment = (state) => state.netSentiment || 0
export const topPositiveReview = (state) => state.topPositiveReview || {}
export const topNegativeReview = (state) => state.topNegativeReview || {}
export const numReviews = (state) => state.numReviews || 0
export const reviews = (state) => state.reviews || []
export const sites = (state) => state.sites || []
export const reviewsVolumeHistory = (state) => state.reviewsHistory.history || []
export const reviewsNetRatingHistory = (state) => state.reviewsNetRatingHistory.history || []
export const reviewsSentimentHistory = (state) => state.reviewsSentimentHistory.history || []
export const reviewsRatingDistributionHistory = (state) => state.reviewsRatingHistory.history || []
export const wordCloudData = (state) => {
  const { entities } = state

  if (!entities || !entities.length) {
    return []
  }

  return wordCloudDataMapper({ entities })
}
export const groupedWordCloudData = (state) => (highlightFilter) => {
  const { entityGroups } = state
  const { groups, filters } = entityGroups

  if (!groups || !groups.length) {
    return []
  }

  const result = groups
    .map(({ entities }, index) => ({ [filters[index].keyField]: wordCloudData({ entities }) }))
    .reduce((a, v) => ({ ...a, [Object.keys(v)[0]]: Object.values(v)[0] }), {})

  if (highlightFilter && highlightFilter !== 'none') {
    return highlightWordCloudData({
      wordCloudGrouped: result,
      appliedHighlightFilter: highlightFilter,
    })
  }

  return result
}
export const topicWheelData = (state) => {
  const limit = 10
  const { entitiesWithGroupData } = state

  if (!entitiesWithGroupData || !entitiesWithGroupData.length) {
    return []
  }

  const limitedItems = entitiesWithGroupData.slice(0, limit)
  return limitedItems.map((item) => ({
    label: item.entity,
    value: item.numReviews,
    data: item.data || [],
    isEntityGroup: Object.hasOwn(item, 'entityMapId') && Object.hasOwn(item, 'entityGroupId'),
    entityMapId: item.entityMapId,
    entityGroupId: item.entityGroupId,
  }))
}
export const sentimentBarChartData = (state) => {
  const limit = 10
  const { entities } = state

  if (!entities || !entities.length) {
    return []
  }

  return entities.slice(0, limit).map((item) => ({
    label: item.entity,
    value: item.sentimentBreakdown,
    isEntityGroup: Object.hasOwn(item, 'entityMapId') && Object.hasOwn(item, 'entityGroupId'),
    entityMapId: item.entityMapId,
    entityGroupId: item.entityGroupId,
  }))
}
export const ratingsBarChartData = (state) => {
  const limit = 10
  const { entities } = state

  if (!entities || !entities.length) {
    return []
  }

  const result = entities.slice(0, limit).map((item) => ({
    label: item.entity,
    value: item.ratingsBreakdown,
    isEntityGroup: Object.hasOwn(item, 'entityMapId') && Object.hasOwn(item, 'entityGroupId'),
    entityMapId: item.entityMapId,
    entityGroupId: item.entityGroupId,
  }))

  return result
}
export const netRatingHistorySeriesData = (state) => {
  const { netRatingHistory } = state
  if (!netRatingHistory || !netRatingHistory.length) {
    return []
  }

  const [netRatingHistoryData] = netRatingHistory
  if (!netRatingHistoryData || !netRatingHistoryData.length) {
    return []
  }

  return netRatingHistoryData.map(({
    volume,
    dateFrom,
  }) => ({
    value: volume,
    date: dateFrom,
  }))
}
export const timeLineChartSeriesData = (state) => {
  const { reviewsHistory: { history } } = state
  if (!history || !history.length) {
    return []
  }

  return history.map(({ volume, dateTo }) => ({
    value: volume,
    date: dateTo,
  }))
}
export const timeLineChartDurationGap = (state) => {
  const { reviewsHistory: { type } } = state

  return type
}
export const dashboardDataStartDate = (state) => {
  const { reviewsHistory: { history } } = state

  if (!history || !history.length) {
    return ''
  }

  return history[0]?.dateFrom
}
export const dashboardDataEndDate = (state) => {
  const { reviewsHistory: { history } } = state
  if (!history || !history.length) {
    return ''
  }

  return history[history.length - 1]?.dateTo
}
export const dashboardId = (state) => state.activeDashboard?._id
export const dashboardType = (state) => state.activeDashboard?.type
export const dashboardProductIds = (state) => state.activeDashboard?.productIds
export const dashboardCategoryIds = (state) => state.activeDashboard?.categoryIds
export const dashboardTagIds = (state) => state.activeDashboard?.tagIds
export const dashboardBrandIds = (state) => state.activeDashboard?.brandIds
export const comparisonTrendingData = (state) => state.trendingData || {}
export const locationsChart = (state) => (state.locations || []).map(({ country, volume }) => ({
  label: country || 'Unknown Country',
  value: volume,
}))
export const locationsMap = (state) => (state.locations || []).map((datum) => ({
  label: datum.countryCode,
  value: datum.volume.positive + datum.volume.neutral + datum.volume.negative,
}))
export const getComponentSettings = (state) => (componentId) => {
  const { componentSettings = [] } = state.activeDashboard

  const index = componentSettings.findIndex((component) => component.componentId === componentId)
  if (index === -1) {
    return {
      sort: 'name',
      sortDir: 'asc',
      productIds: [],
    }
  }
  const { settings } = componentSettings[index]
  const { sort, sortDir, productIds = [] } = settings

  return { sort, sortDir, productIds }
}
export const highestRatedUrls = (state) => state.topUrls?.highestRatedUrls || []
export const mostReviewedUrls = (state) => state.topUrls?.mostReviewedUrls || []
export const getUrlsHistory = (state) => state?.urlBreakdown || []
export const productsForSPD = (state) => state.selectedProductsAsSPD
