import { initTracking } from '@/tracking'
import * as storageApi from '../api/storage'

const isTrackingEnabled = process.env.VUE_APP_ENABLE_TRACKING === 'true'
let mixpanelHasntAlreadyBeenInitialised = true

const getAuthenticatedUser = async (store) => {
  const token = storageApi.get('token')
  if (!token) {
    return null
  }

  try {
    await store.dispatch('users/me')
    const { users: userStore } = store.state
    const { userData } = userStore

    return {
      username: userData.user.username,
      clientId: userData.client.id,
      appFeatures: userData.appFeatures,
    }
  } catch (_) {
    return null
  }
}

export default (router, store) => router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    return next()
  }

  if (to.path === '/logout') {
    store.dispatch('users/logout')
    return next('/')
  }

  const user = await getAuthenticatedUser(store)
  if (!user) {
    window.location.href = '/login'
  }

  const isRouteChanged = from.path !== to.path && to.path !== '/login'
  if (user && isTrackingEnabled && isRouteChanged && mixpanelHasntAlreadyBeenInitialised) {
    await initTracking()
    mixpanelHasntAlreadyBeenInitialised = false
  }

  const { appFeatures } = user
  if (to.path === '/components') {
    if (!appFeatures.includes('componentlibrary')) {
      return next('/data')
    }

    return next()
  }

  if (to.path.startsWith('/admin')) {
    if (!appFeatures.includes('admin')) {
      return next('/data')
    }

    return next()
  }

  if (to.path.endsWith('/multi-product')) {
    if (!appFeatures.includes('beta/mpd')) {
      return next('/dashboards/add')
    }

    return next()
  }

  if (to.path.endsWith('/single-tag')) {
    if (!appFeatures.includes('beta/std')) {
      return next('/dashboards/add')
    }

    return next()
  }

  if (to.path.endsWith('/single-brand')) {
    if (!appFeatures.includes('beta/sbd')) {
      return next('/dashboards/add')
    }

    return next()
  }

  return next()
})
