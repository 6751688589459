import Vue from 'vue'
import VueRouter from 'vue-router'

const ComponentLibrary = () => import('@/components/library/ComponentLibrary')
const Dashboard = () => import('@/components/app/dashboards/Dashboard')
const SelectDashboardType = () => import('@/components/app/dashboards/SelectDashboardType')
const DataListing = () => import('@/components/app/data/DataListing')
const CreateDashboard = () => import('@/components/app/dashboards/CreateDashboard')
const DashboardsListing = () => import('@/components/app/dashboards/DashboardsListing')
const EntityMapsListing = () => import('@/components/app/tools/EntityMapsListing')
const CreateEditEntityMap = () => import('@/components/app/tools/CreateEditEntityMap')
const SearchResults = () => import('@/components/app/search/SearchResults')
const AlertList = () => import('@/components/app/data/AlertList')
const Clients = () => import('@/components/app/admin/Clients')
const CreateEdit = () => import('@/components/app/admin/CreateEditClient')
const UrlDetails = () => import('@/components/app/admin/UrlDetails')
const PageNotFound = () => import('@/components/app/common/PageNotFound')
const Urls = () => import('@/components/app/admin/Urls')
const EditProject = () => import('@/components/app/data/add/EditProject')
const AddSearch = () => import('@/components/app/data/add/AddSearch')
const AddProject = () => import('@/components/app/data/add/AddProject')
const AddEditProduct = () => import('@/components/app/data/add/AddEditProduct')
const AddUrls = () => import('@/components/app/data/add/AddUrls')
const Login = () => import('@/views/Login')
const Page = () => import('@/views/Page')
const AppPage = () => import('@/views/AppPage')

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Admin Page',
    component: AppPage,
    redirect: '/admin/clients',
    children: [
      {
        path: '/admin/clients',
        name: 'Clients',
        component: Clients,
      },
      {
        path: '/admin/clients/add',
        strict: true,
        sensitive: true,
        name: 'Add clients',
        component: CreateEdit,
      },
      {
        path: '/admin/clients/:clientId',
        name: 'Update Client',
        component: CreateEdit,
      },
      {
        path: '/admin/urls',
        name: 'Urls',
        strict: true,
        sensitive: true,
        component: Urls,
      },
      {
        path: '/admin/urls/:urlId',
        name: 'URL Details',
        component: UrlDetails,
      },
    ],
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/data',
    component: AppPage,
    children: [
      {
        path: '/data',
        name: 'Product Data',
        component: DataListing,
      },
      {
        path: '/dashboards',
        name: 'My Dashboards',
        component: DashboardsListing,
      },
      {
        path: '/tools',
        name: 'Tools',
        component: EntityMapsListing,
      },
      {
        path: '/client/admin',
        name: 'Admin',
      },
      {
        path: '/projects/add',
        name: 'Create Project',
        component: AddProject,
      },
      {
        path: '/projects/:projectId/edit',
        name: 'Edit Project',
        component: EditProject,
      },
      {
        path: 'data/alerts',
        name: 'Alerts',
        component: AlertList,
      },
    ],
  },
  {
    path: '/login',
    component: Page,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '/data/add',
    component: AppPage,
    children: [
      {
        path: '/data/add/urls',
        name: 'Add URLs',
        component: AddUrls,
      },
      {
        path: '/data/add/product',
        name: 'Add Product',
        component: AddEditProduct,
      },
      {
        path: '/data/add/search',
        name: 'Add Search',
        component: AddSearch,
      },
    ],
  },
  {
    path: '/components',
    name: 'Component Library Page',
    component: Page,
    children: [
      {
        path: '/components',
        name: 'Component Library',
        component: ComponentLibrary,
      },
    ],
  },
  {
    path: '/dashboards',
    name: 'Dashboards Page',
    component: AppPage,
    children: [
      {
        path: '/dashboards/add',
        name: 'Select Dashboard Type',
        component: SelectDashboardType,
      },
      {
        path: '/dashboards/add/:type',
        name: 'Create Dashboard',
        component: CreateDashboard,
      },
    ],
  },
  {
    path: '/dashboards/:dashboardId',
    name: 'Dashboard Detail Page',
    component: Page,
    children: [
      {
        path: '/dashboards/:dashboardId',
        name: 'Dashboard Detail',
        component: Dashboard,
        props: true,
      },
    ],
  },
  {
    path: '/data/search/',
    name: 'Search Detail Page',
    component: AppPage,
    props: { showProjectDropdown: false },
    children: [
      {
        path: ':searchId/results',
        name: 'Search Results',
        component: SearchResults,
      },
    ],
  },
  {
    path: '/entitymaps',
    name: 'Entity maps',
    component: Page,
    children: [
      {
        path: '/entitymaps/:entityMapId',
        name: 'Edit entity map',
        component: CreateEditEntityMap,
        props: true,
      },
    ],
  },
  { path: '*', component: PageNotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
