import { ALL_DATA_PROJECT, STORAGE_KEY_PROJECT_ID } from '@/utils/constants'
import * as types from '../../types'
import * as projectsApi from '../../../api/projects'
import * as storageApi from '../../../api/storage'

export const selectProject = async ({ commit }, { projectId }) => {
  storageApi.set(STORAGE_KEY_PROJECT_ID, projectId)
  commit(types.PROJECTS_SELECT_PROJECT, projectId)
}

export const createProject = async ({ commit }, { project }) => {
  commit(types.PROJECTS_CREATE_PROJECT_FETCHING)

  try {
    const data = await projectsApi.create({ project })
    const { project: createdProject } = data
    const { _id: createdProjectId } = createdProject
    selectProject({ commit }, { projectId: createdProjectId })
    return commit(types.PROJECTS_CREATE_PROJECT_SUCCESS, data)
  } catch (e) {
    commit(types.PROJECTS_CREATE_PROJECT_ERROR, e.message)
    throw e
  }
}

export const editProject = async ({ commit }, { project }) => {
  commit(types.PROJECTS_EDIT_PROJECT_FETCHING)

  try {
    const data = await projectsApi.edit({ project })
    const { project: editedProject } = data
    const { _id: editedProjectId } = editedProject
    selectProject({ commit }, { projectId: editedProjectId })
    return commit(types.PROJECTS_EDIT_PROJECT_SUCCESS, data)
  } catch (e) {
    commit(types.PROJECTS_EDIT_PROJECT_ERROR, e.message)
    throw e
  }
}

export const fetchProject = async ({ commit }, { projectId }) => {
  commit(types.PROJECTS_FETCHING_PROJECT)

  try {
    const { project } = await projectsApi.getOne({ projectId })
    return commit(types.PROJECTS_RECEIVE_PROJECT, { project })
  } catch (e) {
    commit(types.PROJECTS_RECEIVE_PROJECT_ERROR, e.message)
    throw e
  }
}

export const deleteProject = async ({ commit }, { projectId }) => {
  commit(types.PROJECTS_DELETE_PROJECT_FETCHING)

  try {
    await projectsApi.deleteOne({ projectId })
    selectProject({ commit }, { projectId: ALL_DATA_PROJECT.id })
    return commit(types.PROJECTS_DELETE_PROJECT_SUCCESS)
  } catch (e) {
    commit(types.PROJECTS_DELETE_PROJECT_ERROR, e.message)
    throw e
  }
}

export const fetchProjects = async ({
  commit, state: {
    totalRecords, projects, pageSize, page,
  },
}, fetchParams = {}) => {
  commit(types.PROJECTS_FETCHING_PROJECTS)
  try {
    const { isInfinityScroll, ...rest } = fetchParams
    let params = { ...rest, pageSize }
    if (isInfinityScroll) {
      if (totalRecords === projects.length) {
        return undefined
      }
      params = { ...params, page: parseInt(page, 10) + 1 }
    }

    const data = await projectsApi.getAll(params)
    return commit(types.PROJECTS_RECEIVE_PROJECTS, { ...data, isInfinityScroll })
  } catch (e) {
    commit(types.PROJECTS_RECEIVE_PROJECTS_ERROR, e.message)
    throw e
  }
}

export const addUrls = async ({ commit }, {
  op,
  projectIds,
  urlIds,
  filters = {},
}) => {
  commit(types.ADD_TO_PROJECTS)

  try {
    await projectsApi.addUrls({
      op,
      projectIds,
      urlIds,
      filters,
    })
    return commit(types.ADD_TO_PROJECTS, false)
  } catch (e) {
    commit(types.ADD_TO_PROJECTS_ERROR, e.message)
    throw e
  }
}

export const addProducts = async ({ commit }, {
  op,
  projectIds,
  productIds = [],
  filters = {},
}) => {
  commit(types.ADD_TO_PROJECTS)

  try {
    await projectsApi.addProducts({
      op,
      projectIds,
      productIds,
      filters,
    })
    return commit(types.ADD_TO_PROJECTS, false)
  } catch (e) {
    commit(types.ADD_TO_PROJECTS_ERROR, e.message)
    throw e
  }
}

export const addTags = async ({ commit }, {
  op,
  projectIds,
  tagIds = [],
  filters = {},
}) => {
  commit(types.ADD_TO_PROJECTS)

  try {
    await projectsApi.addTags({
      op,
      projectIds,
      tagIds,
      filters,
    })
    return commit(types.ADD_TO_PROJECTS, false)
  } catch (e) {
    commit(types.ADD_TO_PROJECTS_ERROR, e.message)
    throw e
  }
}

export const addCategories = async ({ commit }, {
  op,
  projectIds,
  categoryIds = [],
  filters = {},
}) => {
  commit(types.ADD_TO_PROJECTS)

  try {
    await projectsApi.addCategories({
      op,
      projectIds,
      categoryIds,
      filters,
    })
    return commit(types.ADD_TO_PROJECTS, false)
  } catch (e) {
    commit(types.ADD_TO_PROJECTS_ERROR, e.message)
    throw e
  }
}

export const addBrands = async ({ commit }, {
  op,
  projectIds,
  brandIds = [],
  filters = {},
}) => {
  commit(types.ADD_TO_PROJECTS)

  try {
    await projectsApi.addBrands({
      op,
      projectIds,
      brandIds,
      filters,
    })
    return commit(types.ADD_TO_PROJECTS, false)
  } catch (e) {
    commit(types.ADD_TO_PROJECTS_ERROR, e.message)
    throw e
  }
}

// eslint-disable-next-line max-len
export const resetErrors = async ({ commit }, errorKeys = {}) => commit(types.PROJECTS_RESET_ERRORS, errorKeys)
