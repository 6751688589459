import * as userApi from '../../../api/users'
import * as types from '../../types'

export const login = async ({ commit }, { username, password }) => {
  commit(types.USER_CLEAR_STATE)
  try {
    const userData = await userApi.login({ username, password })
    return commit(types.USER_RECEIVE_USER, userData)
  } catch (e) {
    return commit(types.USER_LOGIN_ERROR, e.message)
  }
}

export const logout = async ({ commit }) => {
  commit(types.USER_CLEAR_STATE)
  await userApi.logout()
  window.location.reload()
}

export const me = async ({ commit }) => {
  try {
    const userData = await userApi.me()
    return commit(types.USER_RECEIVE_USER, userData)
  } catch (e) {
    return commit(types.USER_CLEAR_STATE)
  }
}

export const fetchUserQuota = async ({ commit }) => {
  commit(types.USER_FETCHING_USER_QUOTA)

  try {
    const { userQuota } = await userApi.quota()
    return commit(types.USER_RECEIVE_USER_QUOTA, userQuota)
  } catch (e) {
    return commit(types.USER_RECEIVE_USER_QUOTA_ERROR, e.message)
  }
}
