import * as types from '../../types'

export default {
  [types.PROJECTS_CREATE_PROJECT_FETCHING](state) {
    Object.assign(state, { fetchingCreateProject: true })
  },
  [types.PROJECTS_CREATE_PROJECT_SUCCESS](state) {
    Object.assign(state, { fetchingCreateProject: false, fetchCreateProjectError: null })
  },
  [types.PROJECTS_CREATE_PROJECT_ERROR](state, fetchCreateProjectError) {
    Object.assign(state, { fetchingCreateProject: false, fetchCreateProjectError })
  },
  [types.PROJECTS_FETCHING_PROJECTS](state) {
    Object.assign(state, { fetchingProjects: true })
  },
  [types.PROJECTS_RECEIVE_PROJECTS](state, {
    projects, totalRecords, isInfinityScroll, pageSize, page,
  }) {
    Object.assign(state, {
      fetchingProjects: false,
      projects: isInfinityScroll ? [...state.projects, ...projects] : projects,
      totalRecords,
      pageSize,
      page,
    })
  },
  [types.PROJECTS_RECEIVE_PROJECTS_ERROR](state, fetchProjectsError) {
    Object.assign(state, { fetchingProjects: false, fetchProjectsError })
  },
  [types.PROJECTS_SELECT_PROJECT](state, activeProjectId) {
    Object.assign(state, { fetchingProjects: false, activeProjectId })
  },
  [types.PROJECTS_EDIT_PROJECT_FETCHING](state) {
    Object.assign(state, { fetchingEditProject: true })
  },
  [types.PROJECTS_EDIT_PROJECT_SUCCESS](state) {
    Object.assign(state, { fetchingEditProject: false, fetchEditProjectError: null })
  },
  [types.PROJECTS_EDIT_PROJECT_ERROR](state, fetchEditProjectError) {
    Object.assign(state, { fetchingEditProject: false, fetchEditProjectError })
  },
  [types.PROJECTS_FETCHING_PROJECT](state) {
    Object.assign(state, { fetchingProject: true })
  },
  [types.PROJECTS_RECEIVE_PROJECT](state, { project }) {
    Object.assign(state, { fetchingProject: false, editingProject: project })
  },
  [types.PROJECTS_RECEIVE_PROJECT_ERROR](state, fetchProjectError) {
    Object.assign(state, { fetchingProject: false, fetchProjectError })
  },
  [types.PROJECTS_DELETE_PROJECT_FETCHING](state) {
    Object.assign(state, { fetchingDeleteProjects: true })
  },
  [types.PROJECTS_DELETE_PROJECT_SUCCESS](state) {
    Object.assign(state, { fetchingDeleteProjects: false, fetchingDeleteProjectError: null })
  },
  [types.PROJECTS_DELETE_PROJECT_ERROR](state, fetchingDeleteProjectError) {
    Object.assign(state, { fetchingDeleteProjects: false, fetchingDeleteProjectError })
  },
  [types.PROJECTS_RESET_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
  [types.ADD_TO_PROJECTS](state, addingToProjects) {
    Object.assign(state, { addingToProjects })
  },
  [types.ADD_TO_PROJECTS_ERROR](state, addingToProjectsError) {
    Object.assign(state, { addingToProjects: false, addingToProjectsError })
  },
}
