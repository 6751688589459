// eslint-disable-next-line import/prefer-default-export
export const appliedFilters = (state) => {
  const filters = {
    productIds: state.productIds,
    domainIds: state.domainIds,
    tagIds: state.tagIds,
    brandIds: state.brandIds,
    categoryIds: state.categoryIds,
    minReviews: state.minReviews,
    maxReviews: state.maxReviews,
    minRating: state.minRating,
    maxRating: state.maxRating,
    minCreationDate: state.minCreationDate,
    maxCreationDate: state.maxCreationDate,
    minLastReviewedDate: state.minLastReviewedDate,
    maxLastReviewedDate: state.maxLastReviewedDate,
    hostnames: state.hostnames,
    search: state.search,
  }

  return Object.entries(filters).reduce((memo, [key, value]) => {
    if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
      // eslint-disable-next-line no-param-reassign
      memo[key] = value
    }
    return memo
  }, {})
}
