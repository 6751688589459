import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingTags: false,
  fetchTagsError: '',
  tags: [],
  totalTags: 0,
  page: 1,
  pageSize: 50,
  summaryPage: 1,
  summaryPageSize: 50,
  sort: '',
  sortDir: 'asc',

  fetchingCreateTag: false,
  fetchCreateTagError: '',
  fetchingEditTagsUrls: false,
  fetchEditTagsUrlsError: '',

  fetchingEditTagsProducts: false,
  fetchEditTagsProductsError: '',

  deletingTags: false,
  deleteTagsError: '',

  tagsSummary: [],
  fetchingTagsSummary: false,
  fetchTagsSummaryError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
