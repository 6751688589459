/* eslint-disable no-unused-vars */
import { RECENT_DATA_KEY, DASHBOARD_TYPES } from '@/utils/constants'
import * as types from '../../types'

const NUM_RECENT_DATA_CARDS = 4
const mapType = (arr, type) => arr.map((item) => ({ ...item, type }))

export default {
  [types.RECENT_DATA_FETCHING](state) {
    Object.assign(state, { fetchingRecentData: true })
  },
  [types.RECENT_DATA_RECEIVE](state, {
    urls, products, brands, categories, tags,
  }) {
    const zipped = []
      .concat(mapType(urls, DASHBOARD_TYPES.URL))
      .concat(mapType(products, DASHBOARD_TYPES.PRODUCT))
      .concat(mapType(brands, DASHBOARD_TYPES.BRAND))
      .concat(mapType(categories, DASHBOARD_TYPES.CATEGORY))
      .concat(mapType(tags, DASHBOARD_TYPES.TAG))
    const sorted = zipped.sort((a, b) => (b[RECENT_DATA_KEY] || '').localeCompare(a[RECENT_DATA_KEY] || ''))
    const recentData = sorted.slice(0, NUM_RECENT_DATA_CARDS)
    Object.assign(state, { fetchingRecentData: false, recentData })
  },
  [types.RECENT_DATA_ERROR](state, fetchRecentDataError) {
    Object.assign(state, { fetchingRecentData: false, fetchRecentDataError })
  },
}
