import * as types from '../../types'

export const setFilters = ({ commit }, {
  productIds,
  domainIds,
  tagIds,
  brandIds,
  categoryIds,
  minReviews,
  maxReviews,
  minRating,
  maxRating,
  minCreationDate,
  maxCreationDate,
  minLastReviewedDate,
  maxLastReviewedDate,
  hostnames,
  search,
}) => {
  if (productIds) {
    commit(types.FILTERS_SET_PRODUCT_IDS, { productIds })
  }
  if (domainIds) {
    commit(types.FILTERS_SET_DOMAIN_IDS, { domainIds })
  }
  if (tagIds) {
    commit(types.FILTERS_SET_TAG_IDS, { tagIds })
  }
  if (brandIds) {
    commit(types.FILTERS_SET_BRAND_IDS, { brandIds })
  }
  if (categoryIds) {
    commit(types.FILTERS_SET_CATEGORY_IDS, { categoryIds })
  }
  if (minReviews !== undefined) {
    commit(types.FILTERS_SET_MIN_REVIEWS, { minReviews })
  }
  if (maxReviews !== undefined) {
    commit(types.FILTERS_SET_MAX_REVIEWS, { maxReviews })
  }
  if (minRating) {
    if (minRating > 1) {
      commit(types.FILTERS_SET_MIN_RATING, { minRating })
    } else {
      commit(types.FILTERS_SET_MIN_RATING, { minRating: null })
    }
  }
  if (maxRating) {
    if (maxRating < 5) {
      commit(types.FILTERS_SET_MAX_RATING, { maxRating })
    } else {
      commit(types.FILTERS_SET_MAX_RATING, { maxRating: null })
    }
  }
  if (minCreationDate !== undefined) {
    commit(types.FILTERS_SET_MIN_CREATION_DATE, { minCreationDate })
  }
  if (maxCreationDate !== undefined) {
    commit(types.FILTERS_SET_MAX_CREATION_DATE, { maxCreationDate })
  }
  if (minLastReviewedDate !== undefined) {
    commit(types.FILTERS_SET_MIN_LAST_REVIEWED_DATE, { minLastReviewedDate })
  }
  if (maxLastReviewedDate !== undefined) {
    commit(types.FILTERS_SET_MAX_LAST_REVIEWED_DATE, { maxLastReviewedDate })
  }
  if (hostnames) {
    commit(types.FILTERS_SET_HOSTNAMES, { hostnames })
  }
  if (search !== undefined) {
    commit(types.FILTERS_SET_SEARCH, { search })
  }
}

// eslint-disable-next-line import/prefer-default-export
export const resetFilters = ({ commit }) => {
  commit(types.FILTERS_RESET)
}
