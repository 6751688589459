import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingRecentAlerts: false,
  fetchRecentAlertsError: '',
  recentAlerts: [],

  fetchingAlerts: false,
  fetchAlertsError: '',
  alerts: [],
  totalAlerts: 0,
  page: 1,
  pageSize: 8,
  fetchingMarkAsSeen: false,
  fetchingMarkAsSeenError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
