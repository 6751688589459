import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  productIds: null,
  domainIds: null,
  tagIds: null,
  brandIds: null,
  categoryIds: null,
  minReviews: null,
  maxReviews: null,
  minRating: null,
  maxRating: null,
  minCreationDate: null,
  maxCreationDate: null,
  minLastReviewedDate: null,
  maxLastReviewedDate: null,
  filterKey: 0,
  hostnames: null,
  search: null,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
