import arraysEqual from '@/utils/arraysEqual'
import cleanFilters from '@/utils/cleanFilters'
import getSentimentRangeKey from '@/utils/getSentimentRangeKey'
import Vue from 'vue'

const insertIf = (condition, elements) => (condition ? elements : [])

const getDateRange = ({ amount, unit = 'days' }) => {
  const startDate = Vue.moment().subtract((amount - 1), unit).format('YYYY-MM-DD')
  const endDate = Vue.moment().format('YYYY-MM-DD')

  return { startDate, endDate }
}

export const appliedFilters = (state) => {
  const filters = cleanFilters({
    sentiment: state.sentiment,
    minSentiment: state.minSentiment,
    maxSentiment: state.maxSentiment,
    entitySentiment: state.entitySentiment,
    minEntitySentiment: state.minEntitySentiment,
    maxEntitySentiment: state.maxEntitySentiment,
    entity: state.entity,
    urlIds: state.urlIds,
    ratings: state.ratings,
    entityMapId: state.entityMapId,
    countries: state.countries,
    hostnames: state.hostnames,
    search: state.search,
    minDate: state.minDate,
    maxDate: state.maxDate,
    entityGroupId: state.entityGroupId,
    subGroup: state.subGroup,
  })

  if (filters.minSentiment === -1 && filters.maxSentiment === 1) {
    delete filters.minSentiment
    delete filters.maxSentiment
  }

  if (filters.minEntitySentiment === -1 && filters.maxEntitySentiment === 1) {
    delete filters.minEntitySentiment
    delete filters.maxEntitySentiment
  }

  if (arraysEqual(filters.ratings, [1, 2, 3, 4, 5])) {
    delete filters.ratings
  }

  return filters
}

export const appliedTrendingDataFilters = (state) => cleanFilters({
  trendingDataDateRangeFilter: state.trendingDataDateRangeFilter,
})

export const sentimentRangeKey = (state) => getSentimentRangeKey(state)

export const entitySentimentRangeKey = (state) => getSentimentRangeKey({
  minSentiment: state.minEntitySentiment,
  maxSentiment: state.maxEntitySentiment,
})

export const dateRange = (state) => {
  const { minDate, maxDate } = state
  if (!minDate || !maxDate) {
    return null
  }
  return [minDate, maxDate]
}

export const dateRangeIsToday = (state) => {
  const { minDate, maxDate } = state
  const { startDate, endDate } = getDateRange({ amount: 1 })
  return minDate === startDate && maxDate === endDate
}

export const dateRangeIsOneWeek = (state) => {
  const { minDate, maxDate } = state
  const { startDate, endDate } = getDateRange({ amount: 7 })
  return minDate === startDate && maxDate === endDate
}

export const dateRangeIsTwoWeek = (state) => {
  const { minDate, maxDate } = state
  const { startDate, endDate } = getDateRange({ amount: 14 })
  return minDate === startDate && maxDate === endDate
}

export const dateRangeIs30Days = (state) => {
  const { minDate, maxDate } = state
  const { startDate, endDate } = getDateRange({ amount: 30 })
  return minDate === startDate && maxDate === endDate
}

export const dateRangeIs60Days = (state) => {
  const { minDate, maxDate } = state
  const { startDate, endDate } = getDateRange({ amount: 60 })
  return minDate === startDate && maxDate === endDate
}

export const dateRangeAllIsSelected = (state) => {
  const { minDate, maxDate } = state
  return !minDate || !maxDate
}

export const appliedEntityFilters = (state, getters, rootState, rootGetters) => {
  const { entity, entityGroupId } = state
  const matchingEntityGroup = rootGetters['entityMaps/entityMapGroups'](entityGroupId)

  return [
    ...insertIf(entity, [`${entity}`]),
    ...insertIf(entityGroupId, [matchingEntityGroup?.name]),
    ...insertIf(entityGroupId, matchingEntityGroup ? matchingEntityGroup.entities : []),
  ]
}

export const entityGroupName = (state, getters, rootState, rootGetters) => {
  const { entityGroupId } = state
  const entityGroup = rootGetters['entityMaps/entityMapGroups'](entityGroupId)
  if (entityGroup?.name) {
    return entityGroup.name
  }
  return null
}
