import createFileClientFromBlob from '@/utils/createFileClientFromBlob'
import * as types from '../../types'
import * as urlsApi from '../../../api/urls'

export const createURLs = async ({ commit }, { urls, projectId }) => {
  commit(types.URLS_CREATE_FETCHING)

  try {
    await urlsApi.create({ urls, projectId })
    return commit(types.URLS_CREATE_SUCCESS)
  } catch (e) {
    return commit(types.URLS_CREATE_ERROR, e.message)
  }
}

export const updateMaxUrl = ({ commit }, maxTotalUrls) => {
  commit(types.URLS_MAX_URLS, maxTotalUrls)
}

export const fetchUrls = async ({ commit }, fetchParams = {}) => {
  commit(types.URLS_FETCHING_URLS)

  try {
    const data = await urlsApi.getAll(fetchParams)
    const response = commit(types.URLS_RECEIVE_URLS, data)
    if (fetchParams.projectId) {
      if (!fetchParams.search) {
        updateMaxUrl({ commit }, data.totalRecords)
      }
    }
    return response
  } catch (e) {
    return commit(types.URLS_RECEIVE_URLS_ERROR, e.message)
  }
}

export const exportUrls = async ({ commit }, fetchParams = {}) => {
  commit(types.URLS_EXPORT_URLS)

  try {
    const csvRawContent = await urlsApi.exportUrls(fetchParams)
    const blob = await csvRawContent.blob()
    const date = new Date()
    const yearMonthDate = date.toISOString().slice(0, 10)
    const time = date.getTime()
    const fileName = `${yearMonthDate}-${time}-urls.csv`
    createFileClientFromBlob(blob, fileName)
    return commit(types.URLS_RECEIVE_EXPORT_URLS)
  } catch (e) {
    return commit(types.URLS_RECEIVE_EXPORT_URLS_ERROR, e.message)
  }
}

export const fetchUrlsSummary = async ({ commit }, fetchParams = {}) => {
  commit(types.URLS_FETCHING_URLS_SUMMARY)
  try {
    const { isInfinityScroll, ...params } = fetchParams
    const data = await urlsApi.getAll(params)
    return commit(types.URLS_RECEIVE_URLS_SUMMARY, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.URLS_FETCHING_URLS_SUMMARY_ERROR, e.message)
  }
}

export const deleteOneUrl = async ({ commit }, { urlId, projectId }) => {
  commit(types.URLS_FETCHING_DELETE_URL)
  try {
    await urlsApi.deleteOne({ urlId, projectId })
    return commit(types.URLS_RECEIVE_DELETE_URL)
  } catch (e) {
    return commit(types.URLS_RECEIVE_DELETE_URL_ERROR, e.message)
  }
}

export const deleteUrls = async ({ commit }, {
  urlIds,
  projectId,
  filters = {},
}) => {
  commit(types.URLS_FETCHING_DELETE_URLS)
  try {
    const payload = {
      urlIds,
      projectId,
      filters,
    }
    await urlsApi.del(payload)
    return commit(types.URLS_RECEIVE_DELETE_URLS)
  } catch (e) {
    return commit(types.URLS_RECEIVE_DELETE_URLS_ERROR)
  }
}

export const validateURLs = async ({ commit }, { urls, validateAlreadyExistingUrls }) => {
  commit(types.URLS_FETCHING_VALIDATE_URLS)
  try {
    const data = await urlsApi.validateURLs({ urls, validateAlreadyExistingUrls })
    return commit(types.URLS_RECEIVE_VALID_URLS, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_VALID_URLS_ERROR, JSON.parse(e.message))
  }
}

export const validateListingUrls = async ({ commit }, { urls }) => {
  commit(types.URLS_FETCHING_VALIDATE_LISTING_URLS)
  try {
    const data = await urlsApi.validateListingURLs({ urls })
    return commit(types.URLS_RECEIVE_VALID_LISTING_URLS, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_VALID_LISTING_URLS_ERROR, JSON.parse(e.message))
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.URLS_SET_PAGE, page)
}
export const setPageSummary = ({ commit }, { pageSummary }) => {
  commit(types.URLS_SET_PAGE_SUMMARY, pageSummary)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.URLS_SET_PAGESIZE, pageSize)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.URLS_SET_SORT, sort)
  commit(types.URLS_SET_SORTDIR, sortDir)
}

export const fetchHostnames = async ({ commit }, fetchParams = {}) => {
  commit(types.URLS_GET_HOST_NAMES_REQUEST)

  try {
    const data = await urlsApi.getHostnames(fetchParams)
    return commit(types.URLS_GET_HOST_NAMES_SUCCESS, data)
  } catch (e) {
    return commit(types.URLS_GET_HOST_NAMES_ERROR, e.message)
  }
}

export const fetchBCRLinkStatus = async ({ commit }, { urlId }) => {
  commit(types.URLS_FETCHING_BCR_LINK_STATUS)

  try {
    const data = await urlsApi.getBCRLinkStatus({ urlId })
    return commit(types.URLS_RECEIVE_BCR_LINK_STATUS, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_BCR_LINK_STATUS_ERROR, e.message)
  }
}

export const fetchLinkUrlToBCR = async ({ commit }, {
  urlIds,
  projectId,
  filters = {},
}) => {
  commit(types.URLS_FETCHING_LINK_URL_TO_BCR)

  try {
    const payload = {
      urlIds,
      projectId,
      filters,
    }

    const data = await urlsApi.linkUrlToBCR(payload)
    return commit(types.URLS_RECEIVE_LINK_URL_TO_BCR, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_LINK_URL_TO_BCR_ERROR, e.message)
  }
}

export const fetchRetryLinkUrlToBCR = async ({ commit }, { urlIds }) => {
  commit(types.URLS_FETCHING_RETRY_LINK_URL_TO_BCR)
  const payload = { urlIds }

  try {
    const data = await urlsApi.retryLinkUrlToBCR(payload)
    return commit(types.URLS_RECEIVE_RETRY_LINK_URL_TO_BCR, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_RETRY_LINK_URL_TO_BCR_ERROR, e.message)
  }
}

export const fetchCreateBCRQuery = async ({ commit }, {
  urlIds,
  projectId,
  filters = {},
  bcrProjectId,
  queryName,
  queryDefinition,
}) => {
  commit(types.URLS_FETCHING_CREATE_BCR_QUERY)

  try {
    const payload = {
      urlIds,
      filters,
      projectId,
      bcrProjectId,
      queryName,
      queryDefinition,
    }

    const data = await urlsApi.createBCRQuery(payload)
    return commit(types.URLS_RECEIVE_CREATE_BCR_QUERY, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_CREATE_BCR_QUERY_ERROR, e.message)
  }
}

export const fetchSuggestedBCRQuery = async ({ commit }, {
  urlIds,
  projectId,
  filters = {},
}) => {
  commit(types.URLS_FETCHING_SUGGESTED_BCR_QUERY)

  try {
    const payload = {
      urlIds,
      projectId,
      filters,
    }

    const data = await urlsApi.getBCRQuerySuggestion(payload)
    return commit(types.URLS_RECEIVE_SUGGESTED_BCR_QUERY, data)
  } catch (e) {
    return commit(types.URLS_RECEIVE_SUGGESTED_BCR_QUERY_ERROR, e.message)
  }
}

export const downloadReviews = async ({ commit }, { urlId }) => {
  commit(types.URLS_DOWNLOAD_REVIEWS)

  try {
    const blob = await urlsApi.download({ urlId })

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    const date = (new Date()).toISOString().split('T')[0]
    a.href = url
    a.download = `export-url-${urlId}-reviews-${date}.json`
    document.body.appendChild(a)
    a.click()
    a.remove()
    return commit(types.URLS_RECEIVE_DOWNLOAD_REVIEWS)
  } catch (e) {
    return commit(types.URLS_DOWNLOAD_REVIEWS_ERROR, e.message)
  }
}

export const clearErrors = async (
  { commit },
  errorKeys = {},
) => commit(types.URLS_CLEAR_ERRORS, errorKeys)
