import * as types from '../../types'

export default {
  [types.DASHBOARDS_CREATE_DASHBOARD_FETCHING](state) {
    Object.assign(state, { fetchingCreateDashboard: true, fetchCreateDashboardError: '' })
  },
  [types.DASHBOARDS_CREATE_DASHBOARD_SUCCESS](state, { dashboard }) {
    const { _id: newlyCreatedDashboardId } = dashboard
    Object.assign(state, { fetchingCreateDashboard: false, newlyCreatedDashboardId })
  },
  [types.DASHBOARDS_CREATE_DASHBOARD_ERROR](state, fetchCreateDashboardError) {
    Object.assign(state, { fetchingCreateDashboard: false, fetchCreateDashboardError })
  },
  [types.DASHBOARDS_FETCHING_DASHBOARDS](state) {
    Object.assign(state, { fetchingDashboards: true, fetchDashboardsError: '' })
  },
  [types.DASHBOARDS_RECEIVE_DASHBOARDS](state, { dashboards, totalRecords }) {
    Object.assign(state, { fetchingDashboards: false, dashboards, totalDashboards: totalRecords })
  },
  [types.DASHBOARDS_RECEIVE_DASHBOARDS_ERROR](state, fetchDashboardsError) {
    Object.assign(state, { fetchingDashboards: false, fetchDashboardsError })
  },
  [types.DASHBOARDS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.DASHBOARDS_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.DASHBOARDS_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.DASHBOARDS_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },
  [types.DASHBOARDS_SAVING_DASHBOARD](state) {
    Object.assign(state, { fetchingSaveDashboard: true, saveDashboardError: '' })
  },
  [types.DASHBOARDS_RECEIVE_SAVE](state) {
    Object.assign(state, { fetchingSaveDashboard: false, saveDashboardError: '' })
  },
  [types.DASHBOARDS_RECEIVE_SAVE_ERROR](state, saveDashboardError) {
    Object.assign(state, { fetchingSaveDashboard: false, saveDashboardError })
  },
  [types.DASHBOARDS_SAVING_DASHBOARD_AS](state) {
    Object.assign(state, { fetchingSaveDashboardAs: true, saveDashboardAsError: '' })
  },
  [types.DASHBOARDS_RECEIVE_SAVE_AS](state, { dashboard }) {
    const { _id: duplicatedDashboardId } = dashboard
    Object.assign(state, { fetchingSaveDashboardAs: false, duplicatedDashboardId })
  },
  [types.DASHBOARDS_RECEIVE_SAVE_AS_ERROR](state, saveDashboardAsError) {
    Object.assign(state, { fetchingSaveDashboardAs: false, saveDashboardAsError })
  },
  [types.DASHBOARDS_RESET_SAVE_AS](state) {
    Object.assign(state, { fetchingSaveDashboardAs: false, saveDashboardAsError: '' })
  },
  [types.DASHBOARDS_DELETING_DASHBOARDS](state, fetchingDeleteDashboard = true) {
    Object.assign(state, { fetchingDeleteDashboard, deleteDashboardError: '' })
  },
  [types.DASHBOARDS_DELETE_DASHBOARDS_ERROR](state, deleteDashboardError) {
    Object.assign(state, { fetchingDeleteDashboard: false, deleteDashboardError })
  },
  [types.DASHBOARDS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },
}
