import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  fetchingDashboardsData: false,
  fetchDashboardsDataError: '',
  name: '',
  pinned: false,
  urls: [],
  hostnames: [],
  sites: [],
  ratingsBreakdown: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  sentimentBreakdown: {
    negative: 0,
    neutral: 0,
    positive: 0,
  },
  netSentiment: 0,
  topPositiveReview: {},
  topNegativeReview: {},
  reviewsHistory: {
    type: '',
    history: [],
  },
  fetchingDashboardsReviewsHistory: false,
  fetchDashboardsReviewsHistoryError: '',
  reviewsNetRatingHistory: {
    type: '',
    history: [],
  },
  fetchingDashboardsReviewsNetRatingHistory: false,
  fetchDashboardsReviewsNetRatingHistoryError: '',
  reviewsSentimentHistory: {
    type: '',
    history: [],
  },
  fetchingDashboardsSentimentHistory: false,
  fetchDashboardsSentimentHistoryError: '',
  reviewsRatingHistory: {
    type: '',
    history: [],
  },
  fetchingDashboardsReviewsRatingHistory: false,
  fetchDashboardsReviewsRatingHistoryError: '',
  fetchingTrends: false,
  fetchingTrendsError: '',
  type: '',
  reviews: [],
  numReviews: 0,
  fetchingReviews: false,
  fetchReviewsError: '',
  netRatingHistory: [],
  trends: {
    totalPositive: 0,
    totalNegative: 0,
    positiveData: [],
    negativeData: [],
    positiveEntities: [],
    negativeEntities: [],
    positivePercentIncrease: 0,
    negativePercentIncrease: 0,
  },
  fetchingUrlsHistory: false,
  fetchingUrlsHistoryError: '',
  urlBreakdown: [],
  entities: [],
  fetchingDashboardsEntities: false,
  fetchDashboardsEntitiesError: '',
  entitiesWithGroupData: [],
  fetchingDashboardsEntitiesWithGroupData: false,
  fetchDashboardsEntitiesWithGroupDataError: '',
  entityGroups: [],
  fetchingDashboardsEntityGroups: false,
  fetchDashboardsEntityGroupError: '',
  fetchingDashboardsTrendingData: false,
  fetchDashboardsTrendingDataError: '',
  trendingData: {
    lastPeriod: {
      entities: [],
      netRating: null,
      numReviews: null,
      sentiment: {
        sentimentBreakdown: {
          positive: 0,
          neutral: 0,
          negative: 0,
        },
        netSentiment: 0,
      },
    },
    thisPeriod: {
      entities: [],
      netRating: null,
      numReviews: null,
      sentiment: {
        sentimentBreakdown: {
          positive: 0,
          neutral: 0,
          negative: 0,
        },
        netSentiment: 0,
      },
    },
  },
  locations: [],
  fetchingDashboard: false,
  fetchDashboardError: '',
  activeDashboard: null,
  topUrls: {
    highestRatedUrls: [],
    mostReviewedUrls: [],
  },
  allowComponentsToFetch: false,

  products: [],
  totalProducts: 0,
  fetchingProducts: false,
  fetchingProductsError: '',

  productsSentiment: [],
  totalProductsSentiment: 0,
  fetchingProductsSentiment: false,
  fetchProductsSentimentError: '',

  productsHistory: [],
  fetchingProductsHistory: false,
  fetchProductsHistoryError: '',

  productsEntities: [],
  totalProductsEntities: 0,
  fetchingProductsEntities: false,
  fetchProductsEntitiesError: '',

  productsOfTable: [],
  totalProductsOfTable: 0,
  fetchingProductsOfTable: false,
  fetchProductsOfTableError: '',

  selectedProductsAsSPD: [],
}

const initialState = { ...state }
state.initialState = initialState

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
