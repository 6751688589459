function getMixpanelConfiguration() {
  const { VUE_APP_MIXPANEL_WRITE_KEY, VUE_APP_ENABLE_SECURE_COOKIE } = process.env
  const isSecureCookieEnabled = VUE_APP_ENABLE_SECURE_COOKIE === 'true'
  return {
    mixpanelWriteKey: VUE_APP_MIXPANEL_WRITE_KEY,
    mixpanelConfig: {
      api_host: 'https://mixpanel-proxy.brandwatch.com',
      secure_cookie: isSecureCookieEnabled,
      cross_subdomain_cookie: false,
      persistence: 'localStorage',
      persistence_name: 'core',
    },
  }
}

module.exports = {
  getMixpanelConfiguration,
}
