import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  fetchingEntityProgressSentimentHistory: false,
  fetchEntityProgressSentimentHistoryError: '',
  reviewsSentimentHistory: {
    type: '',
    history: [],
  },

  fetchingEntityProgressRatingHistory: false,
  fetchEntityProgressRatingHistoryError: '',
  reviewsRatingHistory: {
    type: '',
    history: [],
  },
}

const initialState = { ...state }
state.initialState = initialState

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
