import * as types from '../../types'
import * as alertsApi from '../../../api/alerts'

export const fetchRecentAlerts = async ({ commit }, fetchParams = {}) => {
  commit(types.ALERTS_FETCHING_RECENT_ALERTS)

  const paginationAndSortParams = {
    page: 1,
    pageSize: 4,
    sort: 'created',
    sortDir: 'desc',
    onlyUnseen: true,
  }

  try {
    const data = await alertsApi.getAll({
      ...paginationAndSortParams,
      ...fetchParams,
    })
    return commit(types.ALERTS_RECEIVE_RECENT_ALERTS, data)
  } catch (e) {
    return commit(types.ALERTS_RECEIVE_RECENT_ALERTS_ERROR, e.message)
  }
}

export const fetchAlerts = async ({ commit }, fetchParams = {}) => {
  commit(types.ALERTS_FETCHING_ALERTS)

  const sortParams = {
    sort: 'created',
    sortDir: 'desc',
  }

  try {
    const data = await alertsApi.getAll({
      ...fetchParams,
      ...sortParams,
    })
    return commit(types.ALERTS_RECEIVE_ALERTS, data)
  } catch (e) {
    return commit(types.ALERTS_RECEIVE_ALERTS_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.ALERTS_SET_PAGE, page)
}

export const markAsSeen = async ({ commit }, { alertId }) => {
  commit(types.ALERTS_MARK_SEEN_FETCHING)
  try {
    const data = await alertsApi.markAsSeen({
      alertId,
    })
    return commit(types.ALERTS_MARK_SEEN_SUCCESS, data)
  } catch (e) {
    return commit(types.ALERTS_MARK_SEEN_ERROR, e.message)
  }
}
