import * as types from '../../types'

export default {
  [types.BRANDS_CREATE_BRAND_FETCHING](state) {
    Object.assign(state, { fetchingCreateBrand: true, fetchCreateBrandError: '' })
  },
  [types.BRANDS_CREATE_BRAND_SUCCESS](state) {
    Object.assign(state, { fetchingCreateBrand: false })
  },
  [types.BRANDS_CREATE_BRAND_ERROR](state, fetchCreateBrandError) {
    Object.assign(state, { fetchingCreateBrand: false, fetchCreateBrandError })
  },

  [types.BRANDS_FETCHING_BRANDS](state) {
    Object.assign(state, { fetchingBrands: true, fetchBrandsError: '' })
  },
  [types.BRANDS_RECEIVE_BRANDS](state, { brands, totalRecords }) {
    Object.assign(state, { fetchingBrands: false, brands, totalBrands: totalRecords })
  },
  [types.BRANDS_RECEIVE_BRANDS_ERROR](state, fetchBrandsError) {
    Object.assign(state, { fetchingBrands: false, fetchBrandsError })
  },

  [types.BRANDS_FETCHING_BRANDS_SUMMARY](state) {
    Object.assign(state, { fetchingBrandsSummary: true, fetchBrandsSummaryError: '' })
  },
  [types.BRANDS_RECEIVE_BRANDS_SUMMARY](state, {
    brands, page, pageSize, isInfinityScroll, totalRecords,
  }) {
    Object.assign(state, {
      fetchingBrandsSummary: false,
      brandsSummary: isInfinityScroll ? [...state.brandsSummary, ...brands] : brands,
      summaryPageSize: parseInt(pageSize, 10),
      summaryPage: parseInt(page, 10),
      totalBrands: totalRecords,
    })
  },
  [types.BRANDS_RECEIVE_BRANDS_SUMMARY_ERROR](state, fetchBrandsSummaryError) {
    Object.assign(state, { fetchingBrandsSummary: false, fetchBrandsSummaryError })
  },

  [types.BRANDS_SET_PAGE](state, page) {
    Object.assign(state, { page })
  },
  [types.BRANDS_SET_PAGESIZE](state, pageSize) {
    Object.assign(state, { pageSize })
  },
  [types.BRANDS_SET_SORT](state, sort) {
    Object.assign(state, { sort })
  },
  [types.BRANDS_SET_SORTDIR](state, sortDir) {
    Object.assign(state, { sortDir })
  },

  [types.BRANDS_FETCHING_EDIT_BRANDS_URLS](state) {
    Object.assign(state, { fetchingEditBrandsUrls: true, fetchEditBrandsUrlsError: '' })
  },
  [types.BRANDS_EDIT_BRANDS_URLS_SUCCESS](state) {
    Object.assign(state, { fetchingEditBrandsUrls: false })
  },
  [types.BRANDS_EDIT_BRANDS_URLS_ERROR](state, fetchEditBrandsUrlsError) {
    Object.assign(state, { fetchingEditBrandsUrls: false, fetchEditBrandsUrlsError })
  },

  [types.BRANDS_CLEAR_ERRORS](state, errorKeys) {
    Object.assign(state, errorKeys)
  },

  [types.BRANDS_FETCHING_EDIT_BRANDS_PRODUCTS](state) {
    Object.assign(state, { fetchingEditBrandsProducts: true, fetchEditBrandsProductsError: '' })
  },
  [types.BRANDS_EDIT_BRANDS_PRODUCTS_SUCCESS](state) {
    Object.assign(state, { fetchingEditBrandsProducts: false, fetchEditBrandsProductsError: '' })
  },
  [types.BRANDS_EDIT_BRANDS_PRODUCTS_ERROR](state, error) {
    Object.assign(state, { fetchingEditBrandsProducts: false, fetchEditBrandsProductsError: error })
  },
  [types.BRANDS_DELETING_BRANDS](state, deletingBrands) {
    Object.assign(state, { deletingBrands, deleteBrandsError: '' })
  },
  [types.BRANDS_DELETE_BRANDS_ERROR](state, deleteBrandsError) {
    Object.assign(state, { deletingBrands: false, deleteBrandsError })
  },
}
