import * as types from '../../types'
import * as brandsApi from '../../../api/brands'

export const createBrand = async ({ commit }, {
  name,
  color,
  projectId,
}) => {
  commit(types.BRANDS_CREATE_BRAND_FETCHING)
  try {
    const payload = {
      name,
      color,
      projectId,
    }
    const data = await brandsApi.createBrand(payload)
    return commit(types.BRANDS_CREATE_BRAND_SUCCESS, data)
  } catch (e) {
    return commit(types.BRANDS_CREATE_BRAND_ERROR, e.message)
  }
}

export const fetchBrands = async ({ commit }, fetchParams = {}) => {
  commit(types.BRANDS_FETCHING_BRANDS)

  try {
    const data = await brandsApi.getAll(fetchParams)
    return commit(types.BRANDS_RECEIVE_BRANDS, data)
  } catch (e) {
    return commit(types.BRANDS_RECEIVE_BRANDS_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const fetchBrandsSummary = async ({
  commit, state: {
    summaryPageSize,
  },
}, fetchParams = {}) => {
  commit(types.BRANDS_FETCHING_BRANDS_SUMMARY)
  try {
    const { isInfinityScroll, ...rest } = fetchParams
    const params = { ...rest, pageSize: summaryPageSize }
    const data = await brandsApi.getAll(params)
    return commit(types.BRANDS_RECEIVE_BRANDS_SUMMARY, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.BRANDS_RECEIVE_BRANDS_SUMMARY_ERROR, e.message)
  }
}

export const editBrandsUrls = async ({ commit }, {
  op,
  brandIds,
  projectId,
  urlIds,
  filters = {},
}) => {
  commit(types.BRANDS_FETCHING_EDIT_BRANDS_URLS)

  try {
    const payload = {
      op,
      brandIds,
      urlIds,
      projectId,
      filters,
    }
    const data = await brandsApi.editUrls(payload)
    return commit(types.BRANDS_EDIT_BRANDS_URLS_SUCCESS, data)
  } catch (e) {
    return commit(types.BRANDS_EDIT_BRANDS_URLS_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.BRANDS_SET_PAGE, page)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.BRANDS_SET_PAGESIZE, pageSize)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.BRANDS_SET_SORT, sort)
  commit(types.BRANDS_SET_SORTDIR, sortDir)
}

export const editBrandsProducts = async ({ commit }, {
  brandIds,
  productIds,
  filters,
  op,
}) => {
  commit(types.BRANDS_FETCHING_EDIT_BRANDS_PRODUCTS)
  try {
    const payload = {
      op,
      brandIds,
      productIds,
      filters,
    }
    const data = await brandsApi.editProducts(payload)
    return commit(types.BRANDS_EDIT_BRANDS_PRODUCTS_SUCCESS, data)
  } catch (e) {
    return commit(types.BRANDS_EDIT_BRANDS_PRODUCTS_ERROR, e.message)
  }
}

export const deleteBrands = async ({ commit }, {
  brandIds,
  filters = {},
  projectId,
}) => {
  commit(types.BRANDS_DELETING_BRANDS, true)

  try {
    await brandsApi.deleteBrands({
      brandIds,
      filters,
      projectId,
    })
    return commit(types.BRANDS_DELETING_BRANDS, false)
  } catch (e) {
    return commit(types.BRANDS_DELETE_BRANDS_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys = {}) => commit(types.BRANDS_CLEAR_ERRORS, errorKeys)
