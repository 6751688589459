import {
  getData,
  getReviewsHistory,
  getReviewsNetRatingHistory,
  getReviewsSentimentHistory,
  getReviewsRatingHistory,
  getReviews,
  getTrends,
  getDashboard,
  getEntities,
  getNetRating,
  getNumReviews,
  getNetSentiment,
  getUrlsHistory,
  getProductsSentiment,
  getProductsHistory,
  getProductsEntities,
  saveComponentSettings,
  getProducts,
} from '@/api/dashboardsData'
import cleanFilters from '@/utils/cleanFilters'
import removeNullUndefined from '@/utils/removeNullUndefined'
import * as types from '../../types'

const getFilters = ({ separateBy, urls, timeBuckets }) => {
  const obj = {
    netSentiment: () => [
      { keyField: 'Positive', filterFields: [{ filterField: 'minSentiment', filter: 0.1 }] },
      { keyField: 'Negative', filterFields: [{ filterField: 'maxSentiment', filter: -0.1 }] },
      { keyField: 'Neutral', filterFields: [{ filterField: 'minSentiment', filter: -0.099 }, { filterField: 'maxSentiment', filter: 0.099 }] },
    ],
    url: () => urls.map(({
      _id,
      url,
    }) => ({
      keyField: url,
      filterFields: [{ filterField: 'urlIds', filter: [_id] }],
    })),
    rating: () => {
      const optionKeys = [['1'], ['2'], ['3'], ['4'], ['5']]
      return optionKeys.map((option) => ({ keyField: option, filterFields: [{ filterField: 'ratings', filter: option }] }))
    },
    time: () => {
      const [
        firstIntervalStart,
        firstIntervalEnd,
        secondIntervalEnd,
        thirdIntervalEnd,
        fourthIntervalEnd,
        fifthIntervalEnd,
      ] = timeBuckets
      const items = [
        [firstIntervalStart.format('YYYY-MM-DD'), firstIntervalEnd.format('YYYY-MM-DD')],
        [firstIntervalEnd.format('YYYY-MM-DD'), secondIntervalEnd.format('YYYY-MM-DD')],
        [secondIntervalEnd.format('YYYY-MM-DD'), thirdIntervalEnd.format('YYYY-MM-DD')],
        [thirdIntervalEnd.format('YYYY-MM-DD'), fourthIntervalEnd.format('YYYY-MM-DD')],
        [fourthIntervalEnd.format('YYYY-MM-DD'), fifthIntervalEnd.format('YYYY-MM-DD')],
      ]

      return items.map(([firstDate, secondDate]) => (
        {
          keyField: `${firstDate}-${secondDate}`,
          filterFields: [
            { filterField: 'minDate', filter: firstDate },
            { filterField: 'maxDate', filter: secondDate },
          ],
        }
      ))
    },
  }
  return obj[separateBy]()
}

export const fetchDashboardsData = async ({ commit, rootGetters }, { dashboardId }) => {
  commit(types.DASHBOARDSDATA_FETCHING_DATA)
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const { trendingDataDateRangeFilter: { lastPeriod, thisPeriod } } = rootGetters['dashboardsFilters/appliedTrendingDataFilters']
  const lastPeriodMinDate = lastPeriod.minDate
  const lastPeriodMaxDate = lastPeriod.maxDate
  const thisPeriodMinDate = thisPeriod.minDate
  const thisPeriodMaxDate = thisPeriod.maxDate
  let trendingDataFilters = {
    lastPeriodMinDate,
    lastPeriodMaxDate,
    thisPeriodMinDate,
    thisPeriodMaxDate,
  }
  trendingDataFilters = removeNullUndefined(trendingDataFilters)

  try {
    const data = await getData({
      ...dashboardFilters,
      ...trendingDataFilters,
      dashboardId,
    })
    return commit(types.DASHBOARDSDATA_RECEIVE_DATA, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_DATA_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const fetchDashboardsReviewHistory = async ({ commit, rootGetters }, { dashboardId }) => {
  commit(types.DASHBOARDSDATA_FETCHING_REVIEWS_HISTORY)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  try {
    const data = await getReviewsHistory({ ...dashboardFilters, dashboardId })
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY_ERROR, e.message)
  }
}

export const fetchDashboardsReviewsNetRatingHistory = async ({ commit, rootGetters }, {
  dashboardId,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_REVIEWS_NET_RATING_HISTORY)
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  try {
    const data = await getReviewsNetRatingHistory({ ...dashboardFilters, dashboardId })
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY_ERROR, e.message)
  }
}

export const fetchDashboardsReviewsSentimentHistory = async ({ commit, rootGetters }, {
  dashboardId,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_REVIEWS_SENTIMENT_HISTORY)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  try {
    const data = await getReviewsSentimentHistory({ ...dashboardFilters, dashboardId })
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR, e.message)
  }
}

export const fetchDashboardsReviewsRatingHistory = async ({ commit, rootGetters }, {
  dashboardId,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_REVIEWS_RATING_HISTORY)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  try {
    const data = await getReviewsRatingHistory({ ...dashboardFilters, dashboardId })
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY_ERROR, e.message)
  }
}

export const fetchDashboardsReviews = async ({ commit, rootGetters }, {
  dashboardId, pagination, sorting,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_REVIEWS)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  try {
    const data = await getReviews({
      ...dashboardFilters, dashboardId, pagination, sorting,
    })
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_REVIEWS_ERROR, e.message)
  }
}

export const fetchDashboardsTrends = async ({ commit, rootGetters }, {
  dashboardId, minDate, maxDate,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_TRENDS)
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  try {
    const data = await getTrends({
      ...dashboardFilters,
      dashboardId,
      minDate,
      maxDate,
    })
    return commit(types.DASHBOARDSDATA_RECEIVE_TRENDS, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_TRENDS_ERROR, e.message)
  }
}

export const fetchDashboardsUrlsHistory = async ({ commit, rootGetters }, {
  dashboardId, minDate, maxDate, sort,
  sortDir,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_URLS_HISTORY)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const dates = cleanFilters({ minDate, maxDate })
  try {
    const data = await getUrlsHistory({
      ...dashboardFilters,
      ...dates,
      dashboardId,
      sort,
      sortDir,
    })
    return commit(types.DASHBOARDSDATA_RECEIVE_URLS_HISTORY, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_URLS_HISTORY_ERROR, e.message)
  }
}

export const fetchDashboard = async ({ commit }, { dashboardId }) => {
  commit(types.DASHBOARDSDATA_FETCHING_DASHBOARD)

  try {
    const data = await getDashboard({ dashboardId })
    return commit(types.DASHBOARDSDATA_RECEIVE_DASHBOARD, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_DASHBOARD_ERROR, e.message)
  }
}

export const fetchTrendingData = async ({ commit, rootGetters }, {
  dashboardId,
  period,
}) => {
  commit(types.DASHBOARDSDATA_FETCHING_TRENDING_DATA)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const { trendingDataDateRangeFilter } = rootGetters['dashboardsFilters/appliedTrendingDataFilters']
  const { minDate, maxDate } = trendingDataDateRangeFilter[period]

  const lastPeriodFilters = {
    minDate,
    maxDate,
  }
  try {
    const [
      { entities },
      { netRating },
      { numReviews },
      sentiment,
    ] = await Promise.all([
      getEntities({
        dashboardId, ...dashboardFilters, ...lastPeriodFilters,
      }),
      getNetRating({
        dashboardId, ...dashboardFilters, ...lastPeriodFilters,
      }),
      getNumReviews({
        dashboardId, ...dashboardFilters, ...lastPeriodFilters,
      }),
      getNetSentiment({
        dashboardId, ...dashboardFilters, ...lastPeriodFilters,
      }),
    ])

    const trendingPeriodData = {
      [period]: {
        entities,
        netRating,
        numReviews,
        sentiment,
      },
    }

    return commit(types.DASHBOARDSDATA_RECEIVE_TRENDING_DATA, { trendingPeriodData })
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_DASHBOARD_ERROR, e.message)
  }
}

export const fetchDashboardsEntities = async (
  {
    commit,
    getters,
    rootGetters,
  },
  filters = {},
) => {
  commit(types.DASHBOARDSDATA_FETCHING_ENTITIES)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const { dashboardId } = getters

  try {
    const data = await getEntities({
      dashboardId, ...dashboardFilters, ...filters,
    })

    return commit(types.DASHBOARDSDATA_RECEIVE_ENTITIES, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_ENTITIES_ERROR, e.message)
  }
}

export const fetchDashboardsEntitiesWithGroupData = async (
  {
    commit,
    getters,
    rootGetters,
  },
) => {
  commit(types.DASHBOARDSDATA_FETCHING_ENTITIES_WITH_GROUP_DATA)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const { dashboardId } = getters

  try {
    const data = await getEntities({
      dashboardId,
      includeGroupData: true,
      ...dashboardFilters,
    })

    return commit(types.DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA_ERROR, e.message)
  }
}

export const resetState = ({ commit }) => commit(types.DASHBOARDSDATA_RESET)

export async function fetchDashboardEntityGroups(
  {
    commit,
    getters,
    rootGetters,
  },
  {
    separateBy,
    urls,
    timeBuckets,
  },
) {
  commit(types.DASHBOARDSDATA_FETCHING_GROUPED_ENTITIES)

  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const { dashboardId } = getters
  const filters = getFilters({ separateBy, urls, timeBuckets })

  try {
    const promises = filters.map(({ filterFields }) => getEntities({
      dashboardId,
      ...dashboardFilters,
      ...filterFields.reduce((a, { filterField, filter }) => ({ ...a, [filterField]: filter }), {}),
    }))

    const data = await Promise.all(promises)
    return commit(types.DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES, { groups: data, filters })
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES_ERROR, e.message)
  }
}

export const fetchProductsSentiment = async (
  {
    commit,
    getters,
    rootGetters,
  },
  {
    page,
    pageSize,
  },
) => {
  commit(types.DASHBOARDSDATA_FETCHING_PRODUCTS_SENTIMENT)

  const { dashboardId } = getters
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const filters = { ...dashboardFilters, page, pageSize }

  try {
    const { productsSentiment, totalProducts } = await getProductsSentiment({
      dashboardId,
      ...filters,
    })

    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT, {
      productsSentiment, totalProducts,
    })
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT_ERROR, e.message)
  }
}

export const fetchDashboardProductsHistory = async (
  {
    commit,
    getters,
    rootGetters,
  },
  {
    productIds = [],
  },
) => {
  commit(types.DASHBOARDSDATA_FETCHING_PRODUCTS_HISTORY)

  const { dashboardId } = getters
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const filters = { ...dashboardFilters, productIds }

  try {
    const data = await getProductsHistory({ dashboardId, filters })

    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY, data)
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY_ERROR, e.message)
  }
}

export const fetchDashboardProductsEntities = async ({
  commit,
  getters,
  rootGetters,
},
  {
    page,
    pageSize,
  }) => {
  commit(types.DASHBOARDSDATA_FETCHING_PRODUCTS_ENTITIES)

  const { dashboardId } = getters
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const filters = { ...dashboardFilters }

  try {
    const { productsEntities, totalProducts } = await getProductsEntities({
      dashboardId,
      page,
      pageSize,
      ...filters,
    })

    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES, {
      productsEntities,
      totalProducts,
    })
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES_ERROR, e.message)
  }
}

export const saveDashboardComponentSettings = async (
  {
    commit,
    getters,
  }, {
    componentId,
    sort,
    sortDir,
    productIds,
    search,
  }) => {
  const { dashboardId } = getters
  const componentSettings = await saveComponentSettings({
    dashboardId,
    componentId,
    sort,
    sortDir,
    productIds,
    search,
  })

  return commit(types.DASHBOARDSDATA_SET_DASHBOARD_COMPONENT_SETTINGS, {
    componentId,
    settings: componentSettings,
  })
}

export const setProductsAsSpd = async ({ commit }, selectedProducts) => {
  commit(types.DASHBOARDS_SET_PRODUCTS_AS_SPD, selectedProducts)
}

export const fetchProductsOfTable = async (
  {
    commit,
    getters,
    rootGetters,
  },
  {
    page,
    pageSize,
    componentId,
  },
) => {
  commit(types.DASHBOARDSDATA_FETCHING_PRODUCTS_TABLE)

  const { dashboardId } = getters
  const dashboardFilters = rootGetters['dashboardsFilters/appliedFilters']
  const filters = { ...dashboardFilters, page, pageSize }

  try {
    const { products, totalRecords } = await getProducts({
      dashboardId,
      ...filters,
      componentId,
    })

    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE, {
      products,
      totalRecords,
    })
  } catch (e) {
    return commit(types.DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE_ERROR, e.message)
  }
}
