import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingRecentData: false,
  fetchRecentDataError: '',
  recentData: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
