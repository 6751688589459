import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingCreateSearch: false,
  fetchCreateSearchError: '',

  fetchingSearch: false,
  fetchSearchError: '',
  search: {},

  fetchingUpdateSearch: false,
  fetchUpdateSearchError: '',

  addSearchUrlsResult: {},
  fetchingAddSearchUrls: false,
  fetchAddSearchUrlsError: '',

  newResults: [],
  totalNewResults: 0,
  fetchingNewResults: false,
  fetchNewResultsError: '',

  addedResults: [],
  totalAddedResults: 0,
  fetchingAddedResults: false,
  fetchAddedResultsError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
