export default {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false, maximumFractionDigits: 2,
    },
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
    decimal: {
      style: 'decimal', maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: true, minimumFractionDigits: 1,
    },
  },
}
