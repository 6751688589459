import { SENTIMENT_RANGES } from '@/utils/constants'

const PERMITTED_SENTIMENT_TYPES = Object.keys(SENTIMENT_RANGES)

function getSentimentRangeKey({ minSentiment, maxSentiment }) {
  for (let i = 0; i < PERMITTED_SENTIMENT_TYPES.length; i += 1) {
    const rangeKey = PERMITTED_SENTIMENT_TYPES[i]
    const [rangeMin, rangeMax] = SENTIMENT_RANGES[rangeKey]
    if (rangeMin === minSentiment && rangeMax === maxSentiment) {
      return rangeKey
    }
  }
  return null
}

export default getSentimentRangeKey
