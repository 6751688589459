import * as types from '../../types'

const countValuesOfFilters = (filters) => {
  if (typeof filters !== 'object' || filters === null) {
    return 0
  }

  const valuesArray = Object.values(filters)
  let count = 0

  valuesArray.forEach((value) => {
    if (Array.isArray(value) || typeof value === 'object') {
      count += countValuesOfFilters(value)
    } else {
      count += 1
    }
  })

  return count
}

export default {
  [types.DASHBOARDSDATA_FETCHING_DATA](state) {
    Object.assign(state, { fetchingDashboardsData: true, fetchDashboardsDataError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_DATA](state, data) {
    const {
      name,
      pinned,
      urls,
      hostnames,
      ratingsBreakdown,
      sentimentBreakdown,
      netSentiment,
      topPositiveReview,
      topNegativeReview,
      reviewsHistory,
      netRatingHistory,
      sites,
      trends,
      trendingData,
      locations,
      topUrls,
      urlBreakdown,
    } = data
    const stateUpdate = {
      name,
      pinned,
      urls,
      hostnames,
      ratingsBreakdown,
      sentimentBreakdown,
      netSentiment,
      topPositiveReview,
      topNegativeReview,
      reviewsHistory,
      netRatingHistory,
      sites,
      trends,
      trendingData,
      locations,
      topUrls,
      urlBreakdown,
      allowComponentsToFetch: true,
    }

    Object.assign(state, { fetchingDashboardsData: false, ...stateUpdate })
  },
  [types.DASHBOARDSDATA_RECEIVE_DATA_ERROR](state, fetchDashboardsDataError) {
    Object.assign(state, {
      allowComponentsToFetch: true,
      fetchingDashboardsData: false,
      fetchDashboardsDataError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_REVIEWS_HISTORY](state) {
    Object.assign(state, { fetchingDashboardsReviewsHistory: true, fetchDashboardsReviewsHistoryError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY](state, data) {
    const { reviewsHistory, type } = data
    Object.assign(state, {
      fetchingDashboardsReviewsHistory: false,
      reviewsHistory: {
        history: reviewsHistory,
        type,
      },
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_HISTORY_ERROR](state, fetchDashboardsReviewsHistoryError) {
    Object.assign(state, {
      fetchingDashboardsReviewsHistory: false,
      fetchDashboardsReviewsHistoryError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_REVIEWS_NET_RATING_HISTORY](state) {
    Object.assign(state, { fetchingDashboardsReviewsNetRatingHistory: true, fetchDashboardsReviewsNetRatingHistoryError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY](state, data) {
    const { netRatingHistory, type } = data

    Object.assign(state, {
      fetchingDashboardsReviewsNetRatingHistory: false,
      reviewsNetRatingHistory: {
        history: netRatingHistory,
        type,
      },
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_NET_RATING_HISTORY_ERROR](state, fetchDashboardsReviewsNetRatingHistoryError) { // eslint-disable-line max-len
    Object.assign(state, {
      fetchingDashboardsReviewsNetRatingHistory: false,
      fetchDashboardsReviewsNetRatingHistoryError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_REVIEWS_SENTIMENT_HISTORY](state) {
    Object.assign(state, {
      fetchingDashboardsReviewsSentimentHistory: true,
      fetchDashboardsReviewsSentimentHistoryError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY](state, data) {
    const { sentimentBreakdown, type } = data

    Object.assign(state, {
      fetchingDashboardsReviewsSentimentHistory: false,
      reviewsSentimentHistory: {
        history: sentimentBreakdown,
        type,
      },
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_SENTIMENT_HISTORY_ERROR](state, fetchDashboardsReviewsSentimentHistoryError) { // eslint-disable-line max-len
    Object.assign(state, {
      fetchingDashboardsReviewsSentimentHistory: false,
      fetchDashboardsReviewsSentimentHistoryError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_REVIEWS_RATING_HISTORY](state) {
    Object.assign(state, {
      fetchingDashboardsReviewsRatingHistory: true,
      fetchDashboardsReviewsRatingHistoryError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY](state, data) {
    const { ratingsBreakdown } = data
    Object.assign(state, {
      fetchingDashboardsReviewsRatingHistory: false,
      reviewsRatingHistory: {
        history: ratingsBreakdown,
        data: '',
      },
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_RATING_HISTORY_ERROR](state, fetchDashboardsReviewsRatingHistoryError) { // eslint-disable-line max-len
    Object.assign(state, {
      fetchingDashboardsReviewsRatingHistory: false,
      fetchDashboardsReviewsRatingHistoryError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_REVIEWS](state) {
    Object.assign(state, { fetchingReviews: true, fetchReviewsError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS](state, data) {
    const { reviews, reviewsCount: numReviews } = data
    const stateUpdate = { reviews, numReviews }
    Object.assign(state, { fetchingReviews: false, ...stateUpdate })
  },
  [types.DASHBOARDSDATA_RECEIVE_REVIEWS_ERROR](state, fetchReviewsError) {
    Object.assign(state, {
      fetchingReviews: false,
      fetchReviewsError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_TRENDS](state) {
    Object.assign(state, { fetchingTrends: true, fetchingTrendsError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_TRENDS](state, data) {
    const { trends } = data
    const stateUpdate = { trends }
    Object.assign(state, { fetchingTrends: false, ...stateUpdate })
  },
  [types.DASHBOARDSDATA_RECEIVE_TRENDS_ERROR](state, fetchingTrendsError) {
    Object.assign(state, {
      fetchingTrends: false,
      fetchingTrendsError,
    })
  },
  [types.DASHBOARDSDATA_FETCHING_URLS_HISTORY](state) {
    Object.assign(state, { fetchingUrlsHistory: true, fetchingUrlsHistoryError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_URLS_HISTORY](state, data) {
    const { urlBreakdown } = data
    const stateUpdate = { urlBreakdown }
    Object.assign(state, { fetchingUrlsHistory: false, ...stateUpdate })
  },
  [types.DASHBOARDSDATA_RECEIVE_URLS_HISTORY_ERROR](state, fetchingUrlsHistoryError) {
    Object.assign(state, {
      fetchingUrlsHistory: false,
      fetchingUrlsHistoryError,
    })
  },
  [types.DASHBOARDSDATA_FETCHING_DASHBOARD](state) {
    Object.assign(state, { fetchingDashboard: true, fetchDashboardError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_DASHBOARD](state, { dashboard }) {
    const spdTabs = dashboard.tabs?.find(({ type }) => type === 'single-product')
    const allowComponentsToFetch = !countValuesOfFilters(dashboard.filters)
    Object.assign(state,
      {
        fetchingDashboard: false,
        activeDashboard: dashboard,
        selectedProductsAsSPD: spdTabs?.data || [],
        allowComponentsToFetch,
      })
  },
  [types.DASHBOARDSDATA_RECEIVE_DASHBOARD_ERROR](state, fetchDashboardError) {
    Object.assign(state, { fetchingDashboard: false, fetchDashboardError })
  },
  [types.DASHBOARDSDATA_RESET](state) {
    const { initialState } = state
    Object.assign(state, { ...initialState, initialState })
  },

  [types.DASHBOARDSDATA_FETCHING_TRENDING_DATA](state) {
    Object.assign(state, {
      fetchingDashboardsTrendingData: true,
      fetchDashboardsTrendingDataError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_TRENDING_DATA](state, { trendingPeriodData }) {
    const { trendingData } = state
    const updatedTrendingData = {
      ...trendingData,
      ...trendingPeriodData,
    }

    Object.assign(state, {
      fetchingDashboardsTrendingData: false,
      fetchDashboardsTrendingDataError: '',
      trendingData: updatedTrendingData,
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_TRENDING_DATA_ERROR](state, fetchDashboardsTrendingDataError) {
    Object.assign(state, {
      fetchingDashboardsTrendingData: false,
      fetchDashboardsTrendingDataError,
    })
  },
  [types.DASHBOARDSDATA_FETCHING_ENTITIES](state) {
    Object.assign(state, { fetchingDashboardsEntities: true, fetchDashboardsEntitiesError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_ENTITIES](state, { entities }) {
    Object.assign(state, { fetchingDashboardsEntities: false, entities })
  },
  [types.DASHBOARDSDATA_RECEIVE_ENTITIES_ERROR](state, fetchDashboardsEntitiesError) {
    Object.assign(state, { fetchingDashboardsEntities: false, fetchDashboardsEntitiesError })
  },
  [types.DASHBOARDSDATA_FETCHING_ENTITIES_WITH_GROUP_DATA](state) {
    Object.assign(state, {
      fetchingDashboardsEntitiesWithGroupData: true,
      fetchDashboardsEntitiesWithGroupDataError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA](state, { entities }) {
    Object.assign(state, {
      fetchingDashboardsEntitiesWithGroupData: false,
      entitiesWithGroupData: entities,
    })
  },
  // eslint-disable-next-line max-len
  [types.DASHBOARDSDATA_RECEIVE_ENTITIES_WITH_GROUP_DATA_ERROR](state, fetchDashboardsEntitiesWithGroupDataError) {
    Object.assign(state, {
      fetchingDashboardsEntitiesWithGroupData: false,
      fetchDashboardsEntitiesWithGroupDataError,
    })
  },
  [types.DASHBOARDSDATA_FETCHING_GROUPED_ENTITIES](state) {
    Object.assign(state, { fetchingDashboardsEntityGroups: true, fetchDashboardsGroupedEntitiesError: '' })
  },
  [types.DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES](state, data) {
    Object.assign(state, { fetchingDashboardsEntityGroups: false, entityGroups: data })
  },
  // eslint-disable-next-line max-len
  [types.DASHBOARDSDATA_RECEIVE_GROUPED_ENTITIES_ERROR](state, fetchDashboardsGroupedEntitiesError) {
    Object.assign(state, {
      fetchingDashboardsEntityGroups: false,
      fetchDashboardsGroupedEntitiesError,
    })
  },

  [types.DASHBORDSDATA_FETCHING_PRODUCTS](state) {
    Object.assign(state, { fetchingProducts: true, fetchingProductsError: '' })
  },
  [types.DASHBORDSDATA_FETCHING_PRODUCTS_SUCCESS](state, { products, totalProducts }) {
    Object.assign(state, {
      products, totalProducts, fetchingProductsError: '', fetchingProducts: false,
    })
  },
  [types.DASHBORDSDATA_FETCHING_PRODUCTS_ERROR](state, fetchingProductsError) {
    Object.assign(state, { products: [], fetchingProductsError, fetchingProducts: false })
  },
  [types.DASHBOARDSDATA_FETCHING_PRODUCTS_SENTIMENT](state) {
    Object.assign(state, {
      productsSentiment: [],
      fetchingProductsSentiment: true,
      fetchProductsSentimentError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT](state, { productsSentiment, totalProducts }) {
    Object.assign(state, {
      fetchingProductsSentiment: false,
      totalProductsSentiment: totalProducts,
      productsSentiment,
      fetchProductsSentimentError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_SENTIMENT_ERROR](state, fetchProductsSentimentError) {
    Object.assign(state, {
      productsSentiment: [],
      fetchingProductsSentiment: false,
      fetchProductsSentimentError,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_PRODUCTS_HISTORY](state) {
    Object.assign(state, {
      productsHistory: [],
      fetchingProductsHistory: true,
      fetchProductsHistoryError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY](state, { productBreakdown }) {
    Object.assign(state, {
      productsHistory: productBreakdown,
      fetchingProductsHistory: false,
      fetchProductsHistoryError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_HISTORY_ERROR](state, fetchProductsHistoryError) {
    Object.assign(state, {
      productsHistory: [],
      fetchProductsHistoryError,
      fetchingProductsHistory: false,
    })
  },

  [types.DASHBOARDSDATA_FETCHING_PRODUCTS_ENTITIES](state) {
    Object.assign(state, {
      productsEntities: [],
      fetchingProductsEntities: true,
      fetchProductsEntitiesError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES](state, { productsEntities, totalProducts }) {
    Object.assign(state, {
      fetchingProductsEntities: false,
      totalProductsEntities: totalProducts,
      productsEntities,
      fetchProductsEntitiesError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_ENTITIES_ERROR](state, fetchProductsEntitiesError) {
    Object.assign(state, {
      productsEntities: [],
      fetchProductsEntitiesError,
      fetchingProductsEntities: false,
    })
  },
  [types.DASHBOARDS_SET_PRODUCTS_AS_SPD](state, products) {
    Object.assign(state, {
      selectedProductsAsSPD: products,
    })
  },
  [types.DASHBOARDSDATA_SET_DASHBOARD_COMPONENT_SETTINGS](state, { componentId, settings }) {
    const { activeDashboard } = state
    const { componentSettings = [] } = activeDashboard

    const index = componentSettings.findIndex((component) => component.componentId === componentId)
    if (index === -1) {
      componentSettings.push({
        componentId,
        settings,
      })
    } else {
      componentSettings[index].settings = settings.settings
    }
    Object.assign(state, {
      activeDashboard: { ...activeDashboard, componentSettings },
    })
  },
  [types.DASHBOARDSDATA_FETCHING_PRODUCTS_TABLE](state) {
    Object.assign(state, {
      productsOfTable: [],
      fetchingProductsOfTable: true,
      fetchProductsOfTableError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE](state, { products, totalRecords }) {
    Object.assign(state, {
      fetchingProductsOfTable: false,
      totalProductsOfTable: totalRecords,
      productsOfTable: products,
      fetchProductsOfTableError: '',
    })
  },
  [types.DASHBOARDSDATA_RECEIVE_PRODUCTS_TABLE_ERROR](state, fetchProductsOfTableError) {
    Object.assign(state, {
      productsOfTable: [],
      fetchingProductsOfTable: false,
      fetchProductsOfTableError,
    })
  },

}
