import * as types from '../../types'
import * as appInfoApi from '../../../api/appInfo'

export const fetchSupportedDomains = async ({ commit }, fetchParams = {}) => {
  commit(types.APP_INFO_FETCHING_SUPPORTED_DOMAINS)

  try {
    const data = await appInfoApi.getSupportedDomains(fetchParams)
    commit(types.APP_INFO_RECEIVE_SUPPORTED_DOMAINS, data)

    return data
  } catch (e) {
    return commit(types.APP_INFO_RECEIVE_SUPPORTED_DOMAINS_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const clearSuggestedDomains = async ({ commit }, errorKeys = {}) => commit(types.APP_INFO_CLEAR_SUGGESTED_DOMAINS, errorKeys)
