import * as types from '../../types'

export default {
  [types.APP_INFO_FETCHING_SUPPORTED_DOMAINS](state) {
    Object.assign(state, { fetchingSupportedDomains: true, fetchingSupportedDomainsError: '' })
  },
  [types.APP_INFO_RECEIVE_SUPPORTED_DOMAINS](state, {
    supportedDomains, totalRecords,
  }) {
    Object.assign(state, {
      fetchingSupportedDomainsError: '',
      fetchingSupportedDomains: false,
      supportedDomains,
      totalSupportedDomains: totalRecords,
    })
  },
  [types.APP_INFO_RECEIVE_SUPPORTED_DOMAINS_ERROR](state, fetchingSupportedDomainsError) {
    Object.assign(state, { fetchingSupportedDomains: false, fetchingSupportedDomainsError })
  },
  [types.APP_INFO_CLEAR_SUGGESTED_DOMAINS](state) {
    Object.assign(state, {
      fetchingSupportedDomainsError: '',
      fetchingSupportedDomains: false,
      supportedDomains: [],
      totalSupportedDomains: 0,
    })
  },
}
