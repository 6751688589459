import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingCreateDashboard: false,
  fetchCreateDashboardError: '',
  fetchingDashboards: false,
  fetchDashboardsError: '',
  dashboards: [],
  totalDashboards: 0,
  page: 1,
  pageSize: 50,
  sort: '',
  sortDir: 'asc',
  fetchingSaveDashboard: false,
  saveDashboardError: '',
  fetchingSaveDashboardAs: false,
  saveDashboardAsError: '',
  duplicatedDashboardId: '',
  fetchingDeleteDashboard: false,
  deleteDashboardError: '',
  newlyCreatedDashboardId: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
